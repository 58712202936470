.Technic{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: var(--main-font);
    max-width: 1080px;
}
.TechnicCalendar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
    box-shadow: 0px 0px 5px var(--main-light-grey);
    border-radius: 5px;
    width: 60%;
    max-width: 90vh;
}
.TechnicCalendarDates{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}
.TechnicCalendarMonth{
    width : 100%;
    text-align: center;
    padding: 10px 0px;
    border-bottom: solid 1px var(--main-light-grey);
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.TechnicCalendarMonthArrow{
    
    font-size: 2rem;
    width : 15%;
    height: 100%;
    cursor: pointer;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}
.TechnicCalendarMonthName{
    width: 70%;
    text-align: center;
}
.TechnicCalendarDayLegend{
    background-color: var(--main-light-grey);
    color: var(--main-middle-grey);
    text-align: center;
    width: 14.28%;
}
.TechnicCalendarDay{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
    border: solid 1px var(--main-light-grey);
    font-family: 'Gill Sans', sans-serif;
    color: var(--main-middle-grey);
    width: 14.28%;
    aspect-ratio: 1 / 1;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 600;
    transition: 0.3s;
}
.TechnicCalendarDay:hover{
    background-color: var(--main-light-grey);
    transition: 0.3s;
}
.TechnicCalendarToday{
    background-color: var(--main-light-grey);
    transition: 0.3s;

    border: solid 1px rgb(0, 208, 135);
}
.TechnicCalendarToday:hover{
    background-color: var(--main-grey);
    transition: 0.3s;
}
.TechnicCalendarOut{
    opacity: 0.4;
}
.TechnicCalendarDayShow{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    padding: 3px 0px;
    font-size: 0.6rem;
    font-weight: 400;
    text-shadow: none;
    background-color: var(--main-grey);
    border: solid 1px var(--main-grey);
    color: #FFF;
    text-align: center;
    border-radius: 50px;
    margin-bottom: 5px;
}
.TechnicCalendarDayShowIcon{
    margin-right: 5px;
}
.TechnicCalendarDayShowActive{
    background-color: var(--main-dark-blue);
    border: solid 1px rgb(0, 208, 135);
    color: rgb(0, 208, 135);
}
.TechnicUsers{
    min-height: 85%;
    background-color: #FFF;
    box-shadow: 0px 0px 5px var(--main-light-grey);
    border-radius: 5px;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}   
.TechnicUserStatusParam {
    position: relative;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px 8px;
    background-color: var(--main-light-grey);
    color: var(--main-grey);
    border-radius: 5px;
    margin: 5px 0px;
}
.techUserStatus{
    width: 100%;
    border: solid 1px var(--main-light-grey);
    border-radius: 4px;
    padding: 5px;
    margin: 0px!important;
    color: var(--main-dark-blue);
}
.statusValues{
    position: absolute;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    top: 100%; /* Commence juste en dessous du champ de recherche */
    width: 100%;
    height: 200px;
    padding-top: 15px;
    background: var(--main-light-grey);
    z-index: 1000; /* S'assurer qu'il flotte au-dessus d'autres éléments */
    max-height: 200px; /* Limiter la hauteur et permettre le défilement */
    overflow-y: auto; /* Ajouter une barre de défilement si nécessaire */
    border-top: none;
    margin-top: -2px;
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--main-grey);
    border-radius: 0px 0px 3px 3px;
    transition: 0.3s;
}
.statusValuesInactive{
    position: absolute;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    top: 100%; /* Commence juste en dessous du champ de recherche */
    width: 100%;
    height: 0px;
    background: var(--main-light-grey);
    z-index: 1000;
    margin-top: -5px;
    max-height: 200px;
    overflow-y: hidden;
    border-top: none;
    pointer-events: all;
    display: flex;
    align-items: left;
    justify-content: flex-start;
    flex-direction: column;
    color: var(--main-grey);
    border-radius: 0px 0px 5px 5px;
    transition: 0.3s;
}
.statusValues::-webkit-scrollbar {
    display: none;
}
.techUserStatus{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 5px;
    font-size: 0.8rem;
    color: #FFF;
    cursor: pointer;
    transition: 0.3s;
}
.techUserStatus:hover{
    opacity: 0.8;
    transition: 0.3s;
}
.techUserStatusValue{
    display: flex;
    width: 95%;
    border-radius: 3px;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 5px;
    font-size: 0.7rem;
    border-bottom: solid 1px var(--main-light-grey);
    cursor: pointer;
    transition: 0.3s;
}
.userStatusIcon{
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.techUserStatusValue:hover{
    background-color: var(--main-grey);
    color: #FFF;
    transition: 0.3s;
}
.TechnicUsersDate{
    width: 100%;
    text-align: center;
    padding: 5px;
    margin-bottom: 5px;
    background-color: var(--main-light-grey);
    border-radius: 4px 4px 0px 0px;
    color: var(--main-dark-blue);
}
.TechnicUsersZone{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px;

}
.TechPresCard{
    width: 98%;
    border: solid 1px var(--main-dark-blue);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 10px;
}
.TechPresCardTop{
    border-radius: 5px 5px 0px 0px;
    background-color: var(--main-dark-blue);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
    width: 100%;
    padding: 5px 10px;
}
.TechPresCardName{
    font-size: 0.8rem;
    font-weight: 400;
}
.TechPresCardStatus{
    border-radius: 4px;
    border: solid 1px var(--main-yellow);
    font-size: 0.6rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;
}
.TechPresCardStatus .userStatusIcon{
    border-right: solid 1px var(--main-yellow);
    background-color: var(--main-yellow);
    color: var(--main-dark-blue);
    width: 16px;
    height: calc(0.6rem + 10px);
    border-radius: 2px 0px 0px 2px;
    margin-right: 5px;
}
.TechPressCardContent{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    padding: 5px;
}
.TechPresCardContentPhone{
    width: 45%;
    padding: 2px;
    border: solid 1px var(--main-grey);
    color: var(--main-grey);
    text-align: center;
    font-weight: 300;
    letter-spacing: 1px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 0.8rem;
}
.TechPresCardContentPhone:hover{
    background-color: var(--blue);
    border: solid 1px var(--blue);
    color: #FFF;
    transition: 0.3s;
}
.TechPresCardContentMessage{
    width: 45%;
    padding: 2px;
    border: solid 1px var(--main-grey);
    color: var(--main-grey);
    text-align: center;
    font-weight: 300;
    letter-spacing: 1px;
    border-radius: 50px;
    font-size: 0.8rem;
    cursor: pointer;
}
.TechPresCardContentMessage:hover{
    background-color: var(--main-green);
    border: solid 1px var(--main-green);
    color: #FFF;
    transition: 0.3s;
}
.TechPresCardTaskZone{
    width : 100%;
    font-size: 0.75rem;
    font-style: italic;
}
.TechPresCardTask{
    border-radius: 4px;
    background-color: var(--main-dark-blue);
    color: #FFF;
    margin: 10px 5px;
    padding: 5px 10px;
}
.TechPresCardTaskReal{
    border: solid 1px rgb(0, 208, 135);
}
.TechPresCardTaskTextTitle{
    font-style: normal;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.TechPresCardTaskClientButton{
    width: 100%;
    text-align: center;
    border: solid 1px var(--main-light-grey);
    border-radius: 50px;
    margin-top: 10px;
    cursor: pointer;
    transition: 0.3s;
}
.TechPresCardTaskDevellopeButton{
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-light-grey);
    cursor: pointer;
}
.TechPresCardTaskClientButton:hover{
    background-color: var(--main-light-grey);
    transition: 0.3s;
}
.TechPresCardTaskClientZone{
    width: 100%;
    height: auto;
    padding: 5px 10px;
    margin: 10px 0px;
    border-left: solid 1px #FFF;
    transition: 0.3s;
}
.TechPresCardTaskClientZoneUnactive{
    height: 0;
    overflow: hidden;
    padding: 0px 10px;
    transition: 0.3s;
}
.TechPresCardTaskClientZonePhone{
    border-radius: 50px;
    background-color: #FFF;
    color: var(--main-dark-blue);
    padding: 5px 10px;
    font-style: normal;
}
.TechPresCardTaskValidZone{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    font-style: normal;
    padding: 5px;
    border-radius: 4px;
    border: solid 1px var(--main-dark-blue);
}
.TechPresCardTaskActionZone{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}
.CommentZone{
    width: 100%;
    margin: 10px 0px;
}
.TechPresCardTaskValid{
    text-align: center;
    width: 35%;
    padding: 5px 10px;
    border: solid 1px var(--main-green);
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}
.TechPresCardTaskValidIcon{
    margin-left: 5px;
}
.TechPresCardTaskValidUnactive{
    text-align: center;
    width: 30%;
    padding: 5px 10px;
    border: solid 1px var(--main-dark-blue);
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}
.TechPresCardTaskValid:hover{
    background-color: rgb(0, 208, 135);
    transition: 0.3s;
}
.TechPresCardTaskModif{
    text-align: center;
    width: 30%;
    padding: 5px 10px;
    border: solid 1px #FFF;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
}
.TechPresCardTaskModifClose{
    text-align: center;
    width: 100%;
    padding: 5px 10px;
    border: solid 1px var(--main-dark-blue);
    background-color: var(--main-dark-blue);
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
}
.TechPresCardTaskModifCase{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: fit-content;
    transition: 0.3s;
    pointer-events: all;
}
.TechPresCardTaskModifCaseUnactive{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 0;
    transition: 0.3s;
    overflow: hidden;
    pointer-events: none;
}
.TechPresCardTaskModifForm{
    width : 95%;
}
.TechPresCardTaskModif:hover{
    background-color: #FFF;
    color: var(--main-dark-blue);
    transition: 0.3s;
}
.TechnicCreateTask{
    display: flex;
    width: 96%;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: 10px 5px;
    text-align: center;
    font-size: 0.8rem;
    background-color: var(--main-light-grey);
    color: var(--main-grey);
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
}
.TechnicCreateTaskButton{
    width : 100%;
    border: solid 1px var(--main-dark-blue);
    color: var(--main-dark-blue);
    background-color: #FFF;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s;
}
.TechnicCreateTaskButton:hover{
    background-color: #FFF;
    transition: 0.2s;
}
.TechnicCreateTaskForm{
    width : 100%;
    margin-top: 10px;
    color: var(--main-dark-blue);
    text-align: left;
    transition: 0.3s;
}
.TechnicCreateTaskFormInactive{
    height: 0;
    overflow: hidden;
    transition: 0.3s;
}
.TechnicCreateTaskForm input, textarea, select{
    width: 100%!important;
    border-radius: 5px!important;
    border: solid 1px var(--main-middle-grey)!important;
    color: var(--main-dark-blue)!important;
    background-color: #FFF!important;
    margin-top: 5px!important;
}
.TechnicCreateTaskForm label{
    margin-top: 10px;
}
.TechPresCardTaskModifForm input, textarea, select{
    width: 100%!important;
    border-radius: 3px!important;
    border: solid 1px var(--main-middle-grey)!important;
    color: var(--main-dark-blue)!important;
    background-color: #FFF!important;
    margin-top: 5px!important;
}
.TechPresCardTaskModifForm label{
    margin-top: 5px;
    display: block;
}
.TechnicCreateTaskValid{
    width: 100%;
    padding: 5px;
    color: #FFF;
    background-color: var(--main-dark-blue);
    text-align: center;
    border-radius: 4px;
    margin: 5px 0px;
    cursor: pointer;
}
.TechnicCreateTaskValid:hover{
    color: #FFF;
    background-color: var(--main-blue);
    transition: 0.3s;
}
.TechnicCreateTaskForm ul{
    padding: 0;
}
.TechnicCreateTaskForm li{
    width : 100%;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    border-bottom: solid 1px var(--main-light-grey);
    display: block;
    transition: 0.2s;
}
.TechnicCreateTaskForm li:hover{
    background-color: var(--main-middle-grey);
    color: #FFF;
    transition: 0.2s;
}

@media screen and (max-width : 768px) {
    .Technic{
        flex-direction: column;
        padding: 10vh 0px;
        height: auto;
    }
    .TechnicCalendar{
        width: 96%;
        max-width: 96vw;
    }
    .TechnicUsers{
        width: 90%;
        max-width: 90vw;
    }
    .TechnicCreateTaskForm{
        width: 100%;
    }
    .TechnicCreateTaskFormInactive{
        width: 100%;
    }
    .TechnicCreateTaskForm input, textarea, select{
        width: 100%!important;
    }
    .TechnicCreateTaskForm label{
        margin-top: 5px;
    }
    .TechnicCreateTaskValid{
        width: 100%;
    }
    .TechnicCreateTaskForm ul{
        padding: 0;
    }
    .TechnicCreateTaskForm li{
        width : 100%;
        padding: 3px;
        cursor: pointer;
        border-bottom: solid 1px var(--main-light-grey);
        display: block;
        transition: 0.2s;
    }
    .TechnicCreateTaskForm li:hover{
        background-color: var(--main-dark-blue);
        transition: 0.2s;
    }
    .TechnicCalendarDayLegend{
        font-size: 0.6rem;
    }
}

@media screen and (max-width : 480px) {
    .Technic{
        flex-direction: column;
        padding: 10vh 0px;
        height: auto;
    }
    .TechnicCalendar{
        width: 96%;
        max-width: 96vw;
    }
    .TechnicUsers{
        width: 100%;
        max-width: 100vw;
    }
    .TechnicCreateTaskForm{
        width: 100%;
    }
    .TechnicCreateTaskFormInactive{
        width: 100%;
    }
    .TechnicCreateTaskForm input, textarea, select{
        width: 100%!important;
    }
    .TechnicCreateTaskForm label{
        margin-top: 5px;
    }
    .TechnicCreateTaskValid{
        width: 100%;
    }
    .TechnicCreateTaskForm ul{
        padding: 0;
    }
    .TechnicCreateTaskForm li{
        width : 100%;
        padding: 3px;
        cursor: pointer;
        border-bottom: solid 1px var(--main-light-grey);
        display: block;
        transition: 0.2s;
    }
    .TechnicCreateTaskForm li:hover{
        background-color: var(--main-dark-blue);
        transition: 0.2s;
    }
    .TechnicCalendarDayLegend{
        font-size: 0.6rem;
    }
}