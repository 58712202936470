.Stock{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    font-family: var(--main-font);
    max-width: 1080px;
}
.StockSearch{
    width: 100%;
    background-color: var(--main-light-grey);
    color: var(--main-dark-blue);
    font-weight: 400;
    padding: 5px 10px;
}
.StockSearch input{
    width: 100%!important;
    background-color: transparent!important;
    transition: 0.3s;
}
.StockSearch input:hover{
    background-color: #FFF!important;
    transition: 0.3s;
}
.StockSearch input:focus{
    background-color: #FFF!important;
    transition: 0.3s;
}
.articleSearchZone{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: var(--main-grey);
    border: solid 1px var(--main-grey);
    top: 7vh;
    width: 36%;
    height: 0;
    opacity: 0;
    pointer-events: none;
}
.articleSearchZoneActive{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #FFF;
    box-shadow: 0px 0px 5px 0px var(--main-light-grey);
    color: var(--main-dark-blue);
    width: 36%;
    height: 25vh;
    top: 7vh;
    border-radius: 4px;
    pointer-events: all;
    opacity: 1;
    transition: 0.3s;
    z-index: 100;
}
.articleSearchLine{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 5px;
    cursor: pointer;
    border-bottom: solid 1px var(--main-light-grey);
    font-size: 0.8rem;
}
.articleSearchLine:hover{
    background-color: var(--main-light-grey);
    transition: 0.3s;
}
.articleSearchLineName{
    color: var(--main-dark-blue);
}
.articleSearchLinePrice{
    color: var(--main-light-grey);
}
.articleSearchLineImage{
    width: 3vh;
    height: 3vh;
    border: solid 1px var(--main-grey);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.StockBloc{
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: space-around;
    align-items: top;
}
.StockDesc{
    width: 45%;
    position: relative;
    max-height: 95%;
    background-color: #FFF;
    box-shadow: 0px 0px 5px 0px var(--main-light-grey);
    border-radius: 5px;
    display: left;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
}
.StockDescTypeTongue{
    display: inline;
    position: absolute;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 35%;
    margin-left: 5%;
    box-shadow: -1px 1px 2px 1px var(--main-light-grey);
    border-radius: 0px 0px 5px 5px;
    background-color: var(--blue);
    color: #FFF;
    text-align: center;
    padding: 5px 10px;
    font-size: 0.8rem;
}
.StockDescTypeTongue:hover{
    transform: scale(1.1);
    transition: 0.3s;
}
.StockMainDescBox{
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 4vh;
}
.StockMainDesc{
    width: 65%;
    aspect-ratio: 2.16 / 1;
    color: #222;
    font-size: 0.8rem;
    color: #222;
}
.StockMainDescName{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    border: solid 1px var(--main-light-grey);
    width: 100%;
    padding: 5px 5px;
    font-size: 0.8rem;
    border-radius: 8px;
}
.StockMainDescName input{
    background-color: transparent;
    border: none;
    margin: 0!important;
    color: var(--main-dark-blue)!important;
    width: 100%!important;
}
.StockMainDescCat{
    background-color: transparent;
    color: var(--main-dark-blue);
    border: solid 1px var(--main-grey);
    border-radius: 3px;
    width: 50%;
    font-family: var(--main-font);
    font-weight: 500;
    font-size: 0.8rem;
}
.StockMainDescText{
    width: 100%;
    border: solid 1px var(--main-grey);
    color: var(--main-dark-blue)!important;
    background-color: transparent;
    border-radius: 4px;
    font-size: 0.8rem;
    padding: 5px 10px;
    margin-top: 5px;
}
.StockMainDescImage{
    width: 30%;
    aspect-ratio: 1 / 1;
    border-radius: 5px;
    background-color: var(--main-grey);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.StockMainDescImageModif{
    width: 80%;
    margin-bottom: 10px;
    padding: 4px;
    border-radius: 4px;
    text-align: center;
    color: #FFF;
    background-color: var(--main-dark-blue);
    border: solid 1px var(--main-darkest-blue);
    font-size: 0.8rem;
    opacity: 0;
    transition: 0.3s;
}
.StockMainDescImage:hover .StockMainDescImageModif{
    opacity: 1;
    transition: 0.3s;
}
.StockPrices{
    width : 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    border-bottom: solid 1px var(--main-light-grey);
    padding: 5px;
}
.StockPriceCase{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 45%;
    border: solid 2px var(--main-grey);
    border-radius: 4px;
    font-size: 0.6rem;
    margin: 5px 0px;
    text-transform: uppercase;
}
.StockPriceName{
    width: 60%;
    padding: 5px;
    background-color: transparent;
}
.StockPrice{
    width: 39%;
    background-color: var(--main-grey);
    color: #FFF;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 0px 5px;
}
.StockPrice input{
    color: #FFF!important;
    border: none;
    background-color: transparent!important;
    max-width: 80%!important;
    padding: 5px!important;
}
StockPrice span{
    color: #FFF;
    padding: 5px;
}
.StockTvaSelect {
    border: none!important;
    background-color: transparent!important;

    text-align: left;
    color: #FFF!important;
    width: 80%!important;
    margin: 0!important;
    padding: 5px!important;
}
.StockTvaSelect option {
    color: var(--main-dark-blue);
}
.StockTechnic{
    width: 100%;
    display: flex;
    align-items:flex-start;
    justify-content: space-between;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: auto;
}
.StockTechnic::-webkit-scrollbar{
    display: none;
}

.StockTecnicDescBox{
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-right: solid 1px var(--main-grey);
    color: var(--main-dark-blue);
    font-size: 0.7rem;
    padding: 10px;
}
.StockTecnicDescBoxTitle{
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: var(--main-middle-grey);
}
.StockTechnicParameters{
    width: 100%;
}
.StockTechnicParameter{
    width: 100%;
    border: solid 2px var(--main-grey);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
}
.StockTechnicParameterLeft{
    padding: 5px;
    font-size: 0.7rem;
    text-align: left;
    color: var(--main-dark-blue);

}
.StockTechnicParameterRight{
    padding: 5px;
    font-size: 0.7rem;
    text-align: right;
    background-color: var(--main-grey);
    color: #FFF;
}
.StockTechnicImageTextBloc{
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.StockTechnicImageTextBlocInverted{
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.StockTechnicBlocImage{
    width: 30%;
    aspect-ratio: 1 / 1;
    background-color: var(--main-grey);
}
.StockTechnicBlocText{
    width: 65%;
    font-size: 0.6rem;
    aspect-ratio: 2.16 / 1;
    color: var(--main-dark-blue);
}
.StockTechnicLargeImage{
    width: 100%;
    min-height: 15vh;
    background-color: var(--main-grey);
    margin-top: 10px;
}

.StockTecnicDocs{
    width: 35%;
    height: 100%;
    font-size: 0.8rem;
    padding-top: 10px;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.StockTechnicDoc{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 98%;
    margin-top: 5px;
}
.StockTechnicDocUnactive{
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0.8;
    width: 98%;
    margin-top: 5px;
}
.StockTechnicDocName{
    background-color: #444;
    color: #FFF;
    font-size: 0.6rem;
    padding: 5px;
    width: 100%;
    border-radius: 3px; 
}
.StockTechnicDocView{
    height: calc(0.8rem + 10px);
    aspect-ratio: 1 / 1;
    background-color: var(--main-grey);
    border-radius: 3px;
    margin: 0px 2px;
    cursor: pointer;
}
.StockTechnicDocSend{
    height: calc(0.8rem + 10px);
    aspect-ratio: 1 / 1;
    background-color: var(--main-grey);
    border-radius: 3px; 
    margin: 0px 2px;
    cursor: pointer;
}
.StockTechnicDocUpload{
    height: calc(0.8rem + 10px);
    aspect-ratio: 1 / 1;
    background-color: var(--main-grey);
    border-radius: 3px; 
    margin: 0px 2px;
    cursor: pointer;
}

.StockSaveBar{
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 0.8rem;
}
.StockSaveButtonUnactive{
    pointer-events: none;
    opacity: 0;

}
.StockSaveButton{
    text-align: center;
    color: #FFF;
    background-color: var(--main-dark-blue);
    width: 100%;
    padding: 5px 10px;
    border-radius: 0px 0px 5px 5px;
    cursor: pointer;
    pointer-events: all;
}
.StockSaveButton:hover{
    background-color: var(--main-dark-blue);
}

.StockList{
    width: 49%;
    box-shadow: 0px 0px 5px 0px var(--main-light-grey);
    background-color: #FFF;
    border-radius: 5px;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: first baseline;
    font-family: var(--main-font);
    overflow-x: auto;
    position: relative;
}
.StockListActionBar{
    width : 100%;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: var(--main-dark-blue);
    padding: 10px;
    font-size: 0.8rem;
}
.StockListCreateButton{
    border : solid 1px #FFF;
    color : #FFF;
    cursor : pointer;
    border-radius: 5px;
    text-align: center;
    padding: 5px 10px;
    transition: 0.3s;
}
.StockListCreateButton:hover{
    background-color: #FFF;
    color: var(--main-dark-blue);
    transition: 0.3s;
}
.StockListRegisterButton{
    border : solid 1px #FFF;
    color : #FFF;
    cursor : pointer;
    border-radius: 5px;
    text-align: center;
    padding: 5px 10px;
    transition: 0.3s;
}
.StockListRegisterButton:hover{
    background-color: #444;
    transition: 0.3s;
}
.StockListCategories{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow: auto;
    padding: 10px;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.StockListCategories::-webkit-scrollbar{
    display: none;
}
.StockListCategory{
    width: 100%;
    font-size: 0.8rem;
    margin: 10px;
}
.StockListCategoryTitle{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--main-dark-blue);
    color: var(--blue);
    margin: 0px auto;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}
.StockListCategoryTitle span{
    display: flex;
    align-items: center;
    justify-content: center;
}
.StockListCategoryTitleGridStyle{
    width: 95%;
    background-color: var(--main-dark-blue);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    color: var(--blue);
    padding: 10px;
    cursor: pointer;
    transition: 0.3s;
    text-transform: capitalize;
    margin: 0px auto;
}
.StockListCategoryTitleGridStyle span{
    display: flex;
    align-items: center;
    justify-content: center;
}
.StockListCategoryTitle:hover{
    background-color: var(--main-dark-blue);
    transition: 0.3s;
}
.StockListCategoryIcone{
    width: 20px;
    height: 20px;
    border: solid 1px #FFF;
}
.StockListCategoryArticleList{
    width : 100%;
    padding: 0;
    margin: 0;
    color: #DDD;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    
}
.StockListCategoryArticleListGridStyle{
    width : 100%;
    padding: 0;
    margin: 0;
    color: #DDD;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;  
    flex-wrap: wrap;
}
.StockListCategoryArticle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width : 95%;
    margin: 0px auto;
    padding: 5px;
    background-color: var(--main-light-grey);
    color: var(--main-dark-blue);
    border-bottom: solid 1px var(--main-grey);
    cursor: pointer;
    transition: 0.2s;
}
.StockListCategoryArticle .StockListCategoryArticleNumber{
    max-width: 30%;
}
.StockListCategoryArticleGridStyle{
    width: 24%;
    border-radius: 4px;
    background-color: #FFF;
    box-shadow: 0px 0px 5px 0px var(--main-light-grey);
    color: var(--main-dark-blue);
    margin: 10px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: 0.2s;
}
.StockListCategoryArticleImage{
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: #FFF;
    border-radius: 3px;
    margin-bottom: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.StockListCategoryArticleName{
    font-size: 0.8rem;
    color: var(--main-dark-blue);
    margin-bottom: 5px;
}
.StockListCategoryArticleQte{
    font-size: 0.8rem;
    color: #FFF;
    background-color: var(--main-dark-blue);
    padding: 5px;
    border-radius: 3px;
    width: 100%;
    text-align: center;
}
.StockListCategoryArticleActive{
    width : 100%;
    padding: 5px;
    background-color: #666;
    color: rgb(69, 211, 161);
    border-bottom: solid 1px #222;
    cursor: pointer;
    transition: 0.2s;
}
.StockListCategoryArticle:hover{
    background-color: #555;
    transition: 0.3s;
}
.StockListCategoryArticleActive:hover{
    background-color: #555;
    transition: 0.3s;
}
.articleSearchLineQuantity{
    color: rgb(0, 208, 135)
}
.articleSearchLineQuantityAlert{
    color : rgb(215, 79, 71)
}

.StockListCategoryArticleNumber{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--main-light-grey);
    color: var(--main-dark-blue);
    cursor: pointer;
    transition: 0.2s;
    border-radius: 3px;
}
.StockListCategoryArticleNumberMore{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 3px 3px 0px;
    overflow: hidden;
    transition: 0.2s;
}
.StockListCategoryArticleNumberMore:hover{
    background-color: var(--main-grey);
    color: #FFF;
    transition: 0.3s;
}
.StockListCategoryArticleNumberLess{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 3px 0px 0px 3px;
    justify-content: center;
    transition: 0.2s;
}
.StockListCategoryArticleNumberLess:hover{
    background-color: var(--main-grey);
    color: #FFF;
    transition: 0.3s;
}
.StockListCategoryArticleNumber input{
    width: 100%;
    border: none;
    text-align: center;
    color: var(--main-dark-blue);
    background-color: transparent;
    -moz-appearance: textfield;
    appearance: textfield;
    padding: 5px 0px;
}


.StockListSwitchBand{
    width: 100%;
    display: flex;
    position: sticky;
    bottom: 0;
    align-items: center;
    justify-content: space-around;
    background-color: var(--main-middle-grey);
    color: #FFF;
}
.StockListSwitchButtonStock{
    color: #FFF;
    width: 45%;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 0px 0px 5px 0px;
    transition: 0.3s;
}
.StockListSwitchButtonStock:hover{
    background-color: var(--main-grey);
    transition: 0.3s;
}
.StockListSwitchButtonStockActive{
    background-color: #FFF;
    color: var(--main-dark-blue);
    transition: 0.3s;
}
.StockListSwitchButtonStockActive:hover{
    background-color: #FFF;
    color: var(--main-grey);
    transition: 0.3s;
}
.StockListSwitchButtonShop{
    color: #FFF;
    width: 45%;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 0px 0px 5px 5px;
    transition: 0.3s;
}
.StockListSwitchButtonShop:hover{
    color: var(--main-light-grey);
    transition: 0.3s;
}
.StockListSwitchButtonShopActive{
    background-color: #FFF;
    color: var(--main-grey);
    transition: 0.3s;
}
.StockListSwitchButtonShopActive:hover{
    background-color: #FFF;
    color: var(--main-dark-grey);
    transition: 0.3s;
}
.AffichageSwitch{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.AffichageSwitchButtonLeft{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    border-radius: 3px 0px 0px 3px;
    border: solid 1px #FFF;
    padding: 5px 0px;
    cursor: pointer;
    transition: 0.3s;
}
.AffichageSwitchButtonLeft:hover{
    color: var(--main-light-grey);
    transition: 0.3s;
}
.AffichageSwitchButtonLeftActive{
    background-color: #FFF;
    color: var(--main-grey);
}
.AffichageSwitchButtonLeftActive:hover{
    color: #FFF;
    background-color: var(--main-light-grey);
    transition: 0.3s;
}
.AffichageSwitchButtonRight{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    border-radius: 0px 3px 3px 0px;
    border: solid 1px #FFF;
    border-left: none;
    padding: 5px 0px;
    transition: 0.3s;
}
.AffichageSwitchButtonRight:hover{
    background-color: var(--main-grey);
    transition: 0.3s;
}
.AffichageSwitchButtonRightActive{
    background-color: #FFF;
    color: var(--main-grey);
}
.AffichageSwitchButtonRightActive:hover{
    background-color: var(--main-light-grey);
    transition: 0.3s;
}
.StockFournisseurBox{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    padding: 10px;
    padding-top: 10%;
}
.Fournisseur{
    width: 21%;
    margin: 2%;
    padding: 10px;
    text-align: center;
}
.FournisseurLogo{
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: var(--main-grey);
    border-radius: 5px;
}


.StockFournisseurDesc{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 0.8);
    color: #FFF;
    width: 100%;
    height: 25%;
    bottom: 0;
    padding: 10px;
    overflow-y: auto;

    transition: 0.3s;
}
.StockFournisseurDescInactive{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    background-color: rgba(0, 0, 0, 0.8);
    overflow-y: hidden;
    color: #FFF;
    width: 100%;
    height: 0;
    bottom: 0;
    transition: 0.3s;
}
.FournisseurDescTitle{
    width: 60%;
    font-size: 1rem;
    text-align: left;
    margin-left: 10px;
}
.FournisseurActiveButton{
    width: 20%;
    font-size: 0.8rem;
    text-align: center;
    cursor: pointer;
    padding: 5px;
    border-radius: 3px;
    background-color: var(--main-dark-blue);
    transition: 0.3s;
}
.FournisseurCloseButton{
    width: 10%;
    font-size: 0.8rem;
    text-align: center;
    cursor: pointer;
}
.FournisseurDescText{
    width: 100%;
    font-size: 0.8rem;
    text-align: left;
    margin-top: 10px;
}
.FournisseurSwitchBar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.FournisseurSwitchButton{
    width: 45%;
    font-size: 0.8rem;
    text-align: center;
    cursor: pointer;
    padding: 5px;
    border-radius: 3px;
    background-color: var(--main-dark-blue);
    color: #FFF;
    transition: 0.3s;
}
.FournisseurSwitchButtonRed{
    width: 45%;
    font-size: 0.8rem;
    text-align: center;
    cursor: pointer;
    padding: 5px;
    border-radius: 3px;
    background-color: var(--main-red);
    color: #FFF;
    transition: 0.3s;
}
.FournisseurSwitchButton:hover{
    background-color: var(--main-blue);
    transition: 0.3s;
}
.StockListCategoryBack{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: var(--main-dark-grey);
    color: #FFF;
    cursor: pointer;
    transition: 0.3s;

}
.StockListArticlesMore{
    width: 95%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: var(--main-grey);
    color: #FFF;
    cursor: pointer;
    transition: 0.3s;

}

@media screen and (max-width : 768px) {
    .Stock{
        width: 100%;
        height: unset;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        font-family: var(--main-font);
        padding: 10vh 0px;
    }
    .StockSearch{
        width: 96%;
        border-radius: 4px;
        background-color: #FFF;
        color: var(--main-dark-blue);
        font-weight: 400;
        padding: 5px 10px;
    }
    .StockSearch input{
        border: none;
        width: 100%;
        background-color: transparent;
        color: #FFF;
    }
    .articleSearchZone{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        background-color: var(--main-grey);
        border: solid 1px var(--main-grey);
        top: 7vh;
        width: 90%;
        height: 0;
        opacity: 0;
        pointer-events: none;
    }
    .articleSearchZoneActive{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        background-color: #FFF;
        box-shadow: 0px 0px 5px 0px var(--main-light-grey);
        color: var(--main-dark-blue);
        width: 90%;
        height: 25vh;
        top: 7vh;
        border-radius: 4px;
        pointer-events: all;
        opacity: 1;
        transition: 0.3s;
        z-index: 100;
    }
    .articleSearchLine{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 5px;
        cursor: pointer;
        border-bottom: solid 1px var(--main-light-grey);
        font-size: 0.8rem;
    }
    .articleSearchLine:hover{
        background-color: var(--main-light-grey);
        transition: 0.3s;
    }
    .articleSearchLineName{
        color: var(--main-dark-blue);
    }
    .articleSearchLinePrice{
        color: var(--main-light-grey);
    }
    .articleSearchLineImage{
        width: 3vh;
        height: 3vh;
        border: solid 1px var(--main-grey);
    }
    .StockBloc{
        width: 100%;
        height: unset;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
    .StockDesc{
        width: 96%;
        position: relative;
        max-height: 95%;
        background-color: #FFF;
        box-shadow: 0px 0px 5px 0px var(--main-light-grey);
        border-radius: 5px;
        display: left;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow: auto;
    }
    .StockDescTypeTongue{
        display: inline;
        position: absolute;
        text-transform: uppercase;
        letter-spacing: 1px;
        width: 90%;
        margin-left: 5%;
        box-shadow: -1px 1px 2px 1px var(--main-light-grey);
        border-radius: 0px 0px 5px 5px;
        background-color: var(--blue);
        color: #FFF;
        text-align: center;
        padding: 5px 10px;
        font-size: 0.8rem;
    }
    .StockMainDescBox{
        width: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 4vh;
    }
    .StockMainDesc{
        width: 100%;
        aspect-ratio: 2.16 / 1;
        color: #222;
        font-size: 0.8rem;
        color: #222;
    }
    .StockMainDescName{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        border: solid 1px var(--main-light-grey);
        width: 100%;
        padding: 5px 10px;
        font-size: 0.8rem;
        border-radius: 4px;
        margin-bottom: 5px;
    }
    .StockMainDescName input{
        background-color: transparent;
        border: none;
        color: var(--main-dark-blue);
        width: 100%;
    }
    .StockMainDescCat{
        background-color: transparent;
        color: var(--main-dark-blue);
        border: solid 1px var(--main-grey);
        border-radius: 3px;
        width: 50%;
        font-family: var(--main-font);
        font-weight: 500;
        font-size: 0.8rem;
    }
    .StockMainDescText{
        width: 100%;
        border: solid 1px var(--main-grey);
        color: var(--main-dark-blue);
        background-color: transparent;
        border-radius: 4px;
        font-size: 0.8rem;
        padding: 5px 10px;
        margin-top: 5px;
    }
    .StockMainDescImage{
        width: 30%;
        aspect-ratio: 1 / 1;
        border-radius: 5px;
        background-color: var(--main-grey);
        display: flex;
        align-items: flex-end;
        justify-content: center;
        cursor: pointer;
    }
    .StockMainDescImageModif{
        width: 80%;
        margin-bottom: 10px;
        padding: 4px;
        border-radius: 4px;
        text-align: center;
        color: #FFF;
        background-color: var(--main-dark-blue);
        border: solid 1px var(--main-darkest-blue);
        font-size: 0.8rem;
        opacity: 0;
        transition: 0.3s;
    }
    .StockMainDescImage:hover .StockMainDescImageModif{
        opacity: 1;
        transition: 0.3s;
    }
    .StockPrices{
        width : 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        border-bottom: solid 1px var(--main-light-grey);
        padding: 5px;
    }
    .StockPriceCase{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        width: 45%;
        border: solid 2px var(--main-grey);
        border-radius: 4px;
        font-size: 0.6rem;
        margin: 5px 0px;
        text-transform: uppercase;
    }
    .StockPriceName{
        width: 60%;
        padding: 5px;
        background-color: transparent;
    }
    .StockPrice{
        width: 39%;
        background-color: var(--main-grey);
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .StockPrice input{
        color: #FFF!important;
        border: none;
        background-color: transparent;
        max-width: 80%!important;
        padding: 5px!important;
    }
    .StockTvaSelect {
        border: none!important;
        background-color: transparent!important;
    
        text-align: left;
        color: #FFF!important;
        width: 80%!important;
        margin: 0!important;
        padding: 5px!important;
    }
    .StockTvaSelect option {
        color: var(--main-dark-blue);
    }
    .StockTechnic{
        width: 100%;
        display: flex;
        align-items:flex-start;
        justify-content: space-between;
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-x: auto;
    }
    .StockTechnic::-webkit-scrollbar{
        display: none;
    }
}

@media screen and (max-width : 480px) {
    .Stock{
        width: 100%;
        height: unset;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        font-family: var(--main-font);
        padding: 10vh 0px;
    }
    .StockSearch{
        width: 96%;
        border-radius: 4px;
        background-color: #FFF;
        color: var(--main-dark-blue);
        font-weight: 400;
        padding: 5px 10px;
    }
    .StockSearch input{
        border: none;
        width: 100%;
        background-color: transparent;
        color: #FFF;
    }
    .articleSearchZone{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        background-color: var(--main-grey);
        border: solid 1px var(--main-grey);
        top: 7vh;
        width: 90%;
        height: 0;
        opacity: 0;
        pointer-events: none;
    }
    .articleSearchZoneActive{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        background-color: #FFF;
        box-shadow: 0px 0px 5px 0px var(--main-light-grey);
        color: var(--main-dark-blue);
        width: 90%;
        height: 25vh;
        top: 7vh;
        border-radius: 4px;
        pointer-events: all;
        opacity: 1;
        transition: 0.3s;
        z-index: 100;
    }
    .articleSearchLine{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 5px;
        cursor: pointer;
        border-bottom: solid 1px var(--main-light-grey);
        font-size: 0.8rem;
    }
    .articleSearchLine:hover{
        background-color: var(--main-light-grey);
        transition: 0.3s;
    }
    .articleSearchLineName{
        color: var(--main-dark-blue);
    }
    .articleSearchLinePrice{
        color: var(--main-light-grey);
    }
    .articleSearchLineImage{
        width: 3vh;
        height: 3vh;
        border: solid 1px var(--main-grey);
    }
    .StockBloc{
        width: 100%;
        height: unset;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
    .StockDesc{
        width: 96%;
        position: relative;
        max-height: 95%;
        background-color: #FFF;
        box-shadow: 0px 0px 5px 0px var(--main-light-grey);
        border-radius: 5px;
        display: left;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow: auto;
    }
    .StockDescTypeTongue{
        display: inline;
        position: absolute;
        text-transform: uppercase;
        letter-spacing: 1px;
        width: 90%;
        margin-left: 5%;
        box-shadow: -1px 1px 2px 1px var(--main-light-grey);
        border-radius: 0px 0px 5px 5px;
        background-color: var(--blue);
        color: #FFF;
        text-align: center;
        padding: 5px 10px;
        font-size: 0.8rem;
    }
    .StockMainDescBox{
        width: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 4vh;
    }
    .StockMainDesc{
        width: 100%;
        aspect-ratio: 2.16 / 1;
        color: #222;
        font-size: 0.8rem;
        color: #222;
    }
    .StockMainDescName{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        border: solid 1px var(--main-light-grey);
        width: 100%;
        padding: 5px 10px;
        font-size: 0.8rem;
        border-radius: 4px;
        margin-bottom: 5px;
    }
    .StockMainDescName input{
        background-color: transparent;
        border: none;
        color: var(--main-dark-blue);
        width: 100%;
    }
    .StockMainDescCat{
        background-color: transparent;
        color: var(--main-dark-blue);
        border: solid 1px var(--main-grey);
        border-radius: 3px;
        width: 50%;
        font-family: var(--main-font);
        font-weight: 500;
        font-size: 0.8rem;
    }
    .StockMainDescText{
        width: 100%;
        border: solid 1px var(--main-grey);
        color: var(--main-dark-blue);
        background-color: transparent;
        border-radius: 4px;
        font-size: 0.8rem;
        padding: 5px 10px;
        margin-top: 5px;
    }
    .StockMainDescImage{
        width: 100%;
        aspect-ratio: 1 / 1;
        border-radius: 5px;
        background-color: var(--main-grey);
        display: flex;
        align-items: flex-end;
        justify-content: center;
        cursor: pointer;
        margin-top: 2%;
    }
    .StockMainDescImageModif{
        width: 80%;
        margin-bottom: 10px;
        padding: 4px;
        border-radius: 4px;
        text-align: center;
        color: #FFF;
        background-color: var(--main-dark-blue);
        border: solid 1px var(--main-darkest-blue);
        font-size: 0.8rem;
        opacity: 0;
        transition: 0.3s;
    }
    .StockMainDescImage:hover .StockMainDescImageModif{
        opacity: 1;
        transition: 0.3s;
    }
    .StockPrices{
        width : 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        border-bottom: solid 1px var(--main-light-grey);
        padding: 5px;
    }
    .StockPriceCase{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        width: 96%;
        border: solid 2px var(--main-grey);
        border-radius: 4px;
        font-size: 0.7rem;
        margin: 5px 0px;
        text-transform: uppercase;
    }
    .StockPriceName{
        width: 60%;
        padding: 5px;
        background-color: transparent;
    }
    .StockPrice{
        width: 39%;
        background-color: var(--main-grey);
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .StockPrice input{
        color: #FFF!important;
        border: none;
        background-color: transparent;
        max-width: 80%!important;
        padding: 5px!important;
    }
    .StockTvaSelect {
        border: none!important;
        background-color: transparent;
        text-align: left;
        color: #FFF!important;
        width: 80%!important;
        margin: 0!important;
        padding: 5px!important;
    }
    .StockTvaSelect option {
        color: var(--main-dark-blue);
    }
    .StockTechnic{
        width: 100%;
        display: flex;
        align-items:flex-start;
        justify-content: space-between;
        flex-direction: column;
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-x: auto;
    }
    .StockTechnic::-webkit-scrollbar{
        display: none;
    }
    .StockSearch{
        width: 96%;
        border-radius: 4px;
        background-color: #FFF;
        color: var(--main-dark-blue);
        font-weight: 400;
        padding: 5px 10px;
    }
    .StockTecnicDescBox{
        width: 96%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        border-right: none;
        color: var(--main-dark-blue);
        font-size: 0.7rem;
        padding: 10px;
    }
    .StockTecnicDocs{
        width: 96%;
        height: 100%;
        margin-bottom: 2%;
        font-size: 0.8rem;
        padding-top: 10px;
        color: #999;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
    }
    .StockList{
        width: 96%;
        box-shadow: 0px 0px 5px 0px var(--main-light-grey);
        background-color: #FFF;
        border-radius: 5px;
        height: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: first baseline;
        font-family: var(--main-font);
        overflow-x: auto;
        position: relative;
        margin-top: 2%;
    }
    .StockTechnicDocName{
        background-color: #444;
        color: #FFF;
        font-size: 0.75rem;
        padding: 5px;
        width: 100%;
        border-radius: 3px; 
    }
    .StockListCategoryTitleGridStyle{
        width: 45%;
        background-color: #333;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        color: rgb(0, 208, 135);
        padding: 10px;
        margin: 10px;
        cursor: pointer;
        transition: 0.3s;
    }
    .StockListCategories{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        overflow: auto;
        padding: 10px;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
    .AffichageSwitch{
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .StockListCategories{
        height: 60vh;
    }
}

