.UnactiveBloc{
    display: none;
}
.Facturation{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 1080px;
}
.FacturationActionZone{
    width: 55%;
    height: 95%;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
.FacturationActionBloc{
    width: 100%;
}
.FactureClientSearchZone{
    width: 94%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px 10px;
    background-color: #FFF;
    margin-top: -5px;
    border: solid 1px var(--main-middle-grey);
    border-top: none;
}
.clientSearchLine{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;

}


.FacturationVisionZone{
    position: relative;
    height: 95%;
    aspect-ratio: 1 / 1.414;
    background-color: #FFF;
    box-shadow: 0px 0px 5px var(--main-light-grey);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.FacturationVisionEmpty{
    width: 100%;
    height: 80%;
    text-align: center;
    font-size: 1rem;
    color: #444;
    margin-top: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.FacturationVisionEmptyButton{
    width: 30%;
    text-align: center;
    font-size: 0.8rem;
    color: #FFF;
    background-color: var(--main-dark-blue);
    border: solid 1px var(--main-dark-blue);
    margin-top: 2vh;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}
.FacturationVisionEmptyButton:hover{
    background-color: var(--main-dark-blue);
    transition: 0.3s;
}
.FacturationParamBand{
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: right;
}
.ParameterButton{
    padding: 5px 15px;
    border: solid 1px var(--main-dark-blue);
    color: var(--main-dark-blue);
    text-align: center;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 50px;
    cursor: pointer;
    transition: 0.3s;
}
.ParameterButton:hover{
    color: #FFF;
    background-color: var(--main-dark-blue);
    transition: 0.3s;
}
.NewFactureButton{
    padding: 5px 15px;
    border: solid 1px var(--main-grey);
    color: #FFF;
    background-color: var(--main-grey);
    text-align: center;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 50px;
    cursor: pointer;
    transition: 0.3s;
    margin-right: 10px;
}
.NewFactureButton:hover{
    color: #FFF;
    background-color: var(--main-dark-blue);
    transition: 0.3s;
}
.FacturationActionBar{
    width: 98%;
    margin-bottom: 1vh;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.FactureDevisChoice{
    background-color: var(--main-light-grey);
    border-radius: 6px;
    padding: 8px ;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 0.8rem;
    letter-spacing: 1px;
}
.FactureDevisChoiceBlured{
    opacity: 0.6;
}
.FactureModifBackButton{
    background-color: var(--main-dark-blue);
    color: #FFF;
    border-radius: 6px;
    padding: 8px 5px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 0.8rem;
    letter-spacing: 1px;
    cursor: pointer;
}
.FactureChoice{
    background-color: transparent;
    color: var(--main-grey);
    border-radius: 4px;
    width: 5vw;
    padding: 5px 0px;
    text-align: center;
    cursor: pointer;
}
.DevisChoice{
    background-color: transparent;
    color: var(--main-grey);
    border-radius: 4px;
    width: 5vw;
    padding: 5px 0px;
    text-align: center;
    cursor: pointer;
}
.FdActiveChoice{
    color: rgb(0, 208, 135);
    background-color: #FFF;
    box-shadow: 0px 0px 5px var(--main-middle-grey);
    transition: 0.3s;
}
.FactureDateChoice{
    background-color: var(--main-light-grey);
    border-radius: 6px;
    display: flex;
    padding: 0px  5px;
    align-items: center;
    justify-content: space-around;
    width: 60%;
    font-size: 0.8rem;
    letter-spacing: 1px;
    color: var(--main-grey);
}
.FactureDateInput{
    margin: 0px 5px;
    padding: 5px 5px;
    transition: 0.25s;
    cursor: pointer;
    border-radius: 5px;
    background-color: var(--main-light-grey);
    color: var(--main-grey)!important;
    border: none;
    font-size: 0.7rem;
    font-family: var(--main-font);
    width: 40%!important;
}
.FactureDateInput:hover{
    background-color: var(--main-grey);
    color: var(--main-dark-blue)!important;
    font-weight: 500;
    transition: 0.25s;
}
.FactureSearch{
    background-color: var(--main-light-grey);
    border-radius: 6px;
    padding: 10px 8px;
    cursor: pointer;
    font-size: 0.8rem;
    letter-spacing: 1px;
    color: var(--main-grey);
    margin-left: 1vw;
    min-width: 12vw;
    width: 100%!important;
    border-style: none;
}
.FactureModifForm{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width: 98%;
    margin-left: 1%;
    height: 80vh;
    padding: 10px;
    border: solid 1px var(--main-light-grey);
    border-radius: 5px;
    font-family: var(--main-font);
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    };
    font-size: 0.8rem;
}
.FactureModifClientSearch{
    border: none;
    margin-top: 10px;
    border-radius: 3px;
    padding: 3px;
    width: 100%;
}
.FactureModifClientInfos{
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    border: solid 1px var(--main-light-grey);
    border-radius: 4px;
    margin: 10px 0px;
}
.FactureModifClientName{
    border: none;
    margin-top: 5px;
    border-radius: 3px;
    padding: 3px;
    width: 45%;
}
.FactureModifClientSurname{
    border: none;
    margin-top: 5px;
    border-radius: 3px;
    padding: 3px;
    width: 45%;
}
.FactureModifClientAdresse{
    border: none;
    margin-top: 10px;
    border-radius: 3px;
    padding: 3px;
    width: 45%;
}
.FactureModifClientAdresseBis{
    border: none;
    margin-top: 10px;
    border-radius: 3px;
    padding: 3px;
    width: 45%;
}
.FactureModifClientZip{
    border: none;
    margin-top: 10px;
    border-radius: 3px;
    padding: 3px;
    width: 45%;
}
.FactureModifClientCity{
    border: none;
    margin-top: 10px;
    border-radius: 3px;
    padding: 3px;
    width: 45%;
}
.FactureModifClientPhone{
    border: none;
    margin-top: 10px;
    border-radius: 3px;
    padding: 3px;
    width: 45%;
}
.FactureModifClientMail{
    border: none;
    margin-top: 10px;
    border-radius: 3px;
    padding: 3px;
    width: 45%;
}
.FactureModifArticleNameZone {
    display: inline-block;
    position: relative;
    width: 40%;
    overflow: visible;
    font-size: 0;
}
.FactureModifArticleNameZone input{
    width: 100%!important;
}
.FactureModifName{
    width: 100%;
    border: none;
    padding: 3px;
    border-radius: 3px;  
}
.FactureModifArticlesList{
    width: 100%;
    height: 50vh;
    overflow: auto;
    border: solid 1px var(--main-light-grey);
    border-radius: 4px;
    /* on masque la barre de défilement */
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
}
.AvoirFormArticles{
    width: 100%;
    max-height: 40vh;
    overflow: auto;
    border: solid 1px var(--main-light-grey);
    background-color: var(--main-light-grey);
    border-radius: 4px;
}
.AvoirFormArticleLegend{
    display: flex;
    position: sticky;
    top: 0;
    width: 100%;
    padding: 5px;
    align-items: center;
    justify-content: space-around;
    border-bottom: solid 1px var(--main-light-grey);
    background-color: var(--main-grey);
    color: #FFF;
}
.AvoirFormArticle{
    width: 100%;
    padding: 10px 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: solid 1px var(--main-grey);
    flex-wrap: wrap;
}
.AvoirFormArticleActive{
    border-left: solid 5px var(--blue);
}
.AvoirFormArticleCheckbox{
    width: 5%;
    text-align: center;
}
.AvoirFormArticleName{
    width: 40%;
    text-align: left;
}
.AvoirFormArticleExemplaire{
    width: 10%;
    text-align: center;
}
.AvoirFormArticlePriceHt{
    width: 10%;
    text-align: center;
}
.AvoirFormArticlePriceTtc{
    width: 10%;
    text-align: center;
}
.AvoirFormArticlePriceTva{
    width: 10%!important;
    text-align: center;
}
.AvoirFormArticleDelete{
    width: 10%;
    text-align: center;
}
.AvoirFormArticleComment{
    width: 100%;
    text-align: left;
    background-color: var(--main-middle-grey);
    border: solid 1px var(--main-grey);
    border-radius: 3px;
    padding: 5px;
    color: #FFF;
    font-size: 0.6rem;
    margin-top: 10px;
    font-style: italic;
}
.FactureModifArticleLine{
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px var(--main-light-grey);
    margin: 5px 0px;
    justify-content: space-around;
}
.FactureModifArticleLine input, select{
    width: 10%;
    border: none;
    margin: 0px!important;
    padding: 3px;
    border-radius: 3px;
}
.FactureModifArticleAddLine{
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    border: solid 1px var(--main-light-grey);
    border-radius: 4px;
    margin: 5px 0px;
    justify-content: space-around;
    background-color: var(--main-light-grey);
    color: var(--main-grey);
    font-size: 0.8rem;
    cursor: pointer;
    transition: 0.3s;
}
.FactureModifArticleAddLine:hover{
    background-color: var(--main-grey);
    color: #FFF;
    transition: 0.3s;
}

.FactureModifArticleLineCreator{
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border: solid 1px var(--main-light-grey);
    border-radius: 4px;
    margin: 5px 0px;
    justify-content: space-around;
    background-color: var(--main-light-grey);
    color: var(--main-grey);
    font-size: 0.8rem;
    cursor: pointer;
    transition: 0.3s;
}
.FactureModifArticleLineCreatorActive{
    height: inherit;
    max-height: fit-content;
    pointer-events: all;
    transition: 0.3s;
}
.FactureModifArticleLineCreator label{
    width: 10%;
    text-align: center;
    padding: 5px;
    border-right: solid 1px var(--main-light-grey);

}
.FactureModifArticleNameList li {
    list-style: none;
    padding: 5px;
    width: 100%;
    text-align: center;
    border-bottom: solid 1px var(--main-light-grey);
    overflow: hidden; 
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
} 
.FactureModifArticleNameList li:hover{
    background-color: var(--main-light-grey);
    transition: 0.3s;
}
.FactureModifArticleNameList {
    display: none;
    position: absolute;
    width: 100%;
    max-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: #FFF;
    color: var(--main-dark-blue);
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 0px 0px 4px 4px;
    box-shadow: 1px 1px 3px var(--main-grey);
    min-height: 20px; /* Ajoutez une hauteur minimale à l'élément ul */
    overflow: auto; /* Permettez à l'élément ul d'afficher une barre de défilement si son contenu dépasse sa hauteur */
    z-index: 1000; /* Garantissez que l'élément ul est affiché au-dessus des autres éléments de la page */
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
}  
.FactureModifArticleNameList li {
    list-style: none;
    padding: 5px;
    width: 100%;
    text-align: center;
    border-bottom: solid 1px var(--main-light-grey);
    min-height: 20px; /* Ajoutez une hauteur minimale à l'élément li */
    line-height: 20px; /* Définissez une hauteur de ligne fixe pour l'élément li */
    overflow: hidden; /* Empêchez tout débordement de contenu de l'élément li */
}  
.FactureModifArticleNameList li:hover{
    background-color: var(--main-light-grey);
    transition: 0.3s;
}
.FactureModifArticleName {
    border: none !important;
    width: 100% !important;
    max-height: 20px; /* Ajoutez une hauteur minimale à l'élément input */
    box-sizing: border-box; /* Incluez le rembourrage et la bordure dans la hauteur et la largeur de l'élément input */
    overflow: hidden; /* Empêchez tout débordement de contenu de l'élément input */
    font-size: 1rem;
    vertical-align: top;
}
.AvoirModifArticleComent{
    border: none!important;
    border-radius: 0px 0px 3px 3px!important;
    padding: 3px!important;
    width: 98%!important;
    margin-bottom: 10px;
}
.FactureAvoirModifArticleName{
    border: none !important;
    width: 100% !important;
    max-height: 20px; /* Ajoutez une hauteur minimale à l'élément input */
    box-sizing: border-box; /* Incluez le rembourrage et la bordure dans la hauteur et la largeur de l'élément input */
    overflow: hidden; /* Empêchez tout débordement de contenu de l'élément input */
    font-size: 1rem;
    vertical-align: top;
}
.FactureAvoirModifArticleExemplaire{
    border: none!important;
    border-radius: 3px!important;
    padding: 3px!important;
    width: 10%!important;
}
.FactureAvoirModifArticlePriceHt{
    border: none!important;
    border-radius: 3px!important;
    padding: 3px!important;
    width: 9%!important;
}
.FactureAvoirModifArticlePriceTtc{
    border: none!important;
    border-radius: 3px!important;
    padding: 3px!important;
    width: 9%!important;
}
.FactureAvoirModifArticlePriceTva{
    border: none!important;
    border-radius: 3px!important;
    padding: 3px!important;
    width: 9%!important;
}
.FactureAvoirModifArticleComent{
    border: none!important;
    border-radius: 3px!important;
    padding: 3px!important;
    width: 20%!important;
}
.FactureAvoirModifArticleAdd{
    border: none!important;
    border-radius: 3px!important;
    padding: 3px!important;
    width: 100%!important;
    background-color: var(--main-grey);
    color: #FFF;
    text-align: center;
}
.FactureAvoirModifArticleAdd:hover{
    background-color: var(--main-dark-grey);
    transition: 0.3s;
}
.FactureModifArticleExemplaire{
    border: none!important;
    border-radius: 3px!important;
    padding: 3px!important;
    width: 10%!important;
}
.FactureModifArticlePriceHt{
    border: none!important;
    border-radius: 3px!important;
    padding: 3px!important;
    width: 9%!important;
}
.FactureModifArticlePriceTtc{
    border: none!important;
    border-radius: 3px!important;
    padding: 3px!important;
    width: 9%!important;
}
.FactureModifArticlePriceTva{
    border: none!important;
    border-radius: 3px!important;
    padding: 3px!important;
    width: 9%!important;
}
.FactureModifArticleDelete{
    border: none;
    width: 7%;
    border-radius: 3px;
    padding: 3px;
    background-color: var(--main-grey);
    color: #FFF;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.7rem;
    letter-spacing: 2px;
    cursor: pointer;
    border: solid 1px var(--main-grey);
    transition: 0.3s;
}
.FactureModifArticlesListLegend{
    width: 100%;
    padding: 5px;
    position: sticky;
    top: 0;
    background-color: var(--main-light-grey);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.FactureModifArticleOption{
    border: none;
    border-radius: 3px;
    padding: 3px;
    width: 10%;
    background-color: var(--main-grey);
    color: #FFF;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.7rem;
    letter-spacing: 2px;
    cursor: pointer;
    border: solid 1px var(--main-grey);
    transition: 0.3s;
}
.FactureModifArticleOption:hover{
    background-color: var(--main-dark-grey);
    transition: 0.3s;
}
.FactureModifArticleOptionActive{
    border: none;
    border-radius: 3px;
    padding: 3px;
    width: 10%;
    background-color: rgb(0, 208, 135);
    color: #FFF;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.7rem;
    letter-spacing: 2px;
    cursor: pointer;
    border: solid 1px rgb(0, 208, 135);
    transition: 0.3s;
}
.FactureModifArticleOptionActive:hover{
    background-color: rgb(3, 154, 101);
    transition: 0.3s;
}

.FactureModifArticleNameLegend{
    border: none;
    padding: 3px;
    width: 60%;
}
.FactureModifArticleExemplaireLegend{
    border: none;
    padding: 3px;
    width: 10%;
}
.FactureModifArticlePriceHtLegend{
    border: none;
    padding: 3px;
    width: 9%;
}
.FactureModifArticlePriceTtcLegend{
    border: none;
    padding: 3px;
    width: 9%;
}
.FactureModifArticleOptionLegend{
    border: none;
    padding: 3px;
    width: 10%;
}
.FactureModifArticleDeleteLegend{
    border: none;
    padding: 3px;
    width: 7%;
}


.FactureModifArticleMore{
    width: 98%;
    position: sticky;
    bottom: 0;
    margin: 5px 1%;
    text-align: center;
    text-transform: uppercase;
    border-radius: 3px;
    padding: 5px;
    font-size: 0.7rem;
    letter-spacing: 2px;
    background-color: var(--main-dark-blue);
    color: #FFF;
    border: solid 1px var(--main-dark-blue);
    cursor: pointer;
    transition: 0.3s;
}
.FactureModifArticleMore:hover{
    background-color: var(--main-darkest-blue);
    transition: 0.3s;
}
.FactureSave{
    width: calc(100% - 10px);
    text-align: center;
    text-transform: uppercase;
    border-radius: 3px;
    padding: 5px;
    margin: 5px;
    font-size: 0.7rem;
    letter-spacing: 2px;
    background-color: var(--main-dark-blue);
    color: rgb(0, 208, 135);
    border: solid 1px rgb(0, 208, 135);
    cursor: pointer;
    transition: 0.3s;
}
.FactureSave:hover{
    transition: 0.3s;
    background-color: rgb(0, 208, 135);
    color: #FFF;
}

.FacturationList{
    width: 98%;
    padding: 0;
    height: 85%;
    overflow: auto;
    top: 0;
    background-color: #FFF;
    border-radius: 5px;
    /* On efface les barres de défilement */
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
}
.FacturationList li{
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border-bottom: solid 1px var(--main-middle-grey);
}
.FacturationListItem{
    border: solid 1px var(--main-light-grey);
    border-radius: 4px;
    margin: 5px;
    cursor: pointer;
    transition: 0.3s;
}
.FacturationListItem:hover{
    background-color: var(--main-light-grey);
    transition: 0.3s;
}
.FacturationListEmpty{
    width: 40%;
    text-align: center;
    background-color: var(--main-red);
    border: solid 2px var(--main-red);
    color: #FFF;
    font-family: var(--main-font);
    border-radius: 5px;
    margin: 2vh 30%;
    padding: 10px 0px;
    font-size: 0.8rem; 
}
.FacturationListLegend{
    position: sticky;
    top: 0;
    background-color: var(--main-dark-grey);
    color: #FFF;
}
.FacturationListLegendNum{
    width: 20%;
}
.FacturationListLegendClient{
    width : 40%;
}
.FacturationListLegendDate{
    width: 20%;
}
.FacturationListNum{
    width: 20%;
}
.FacturationListClient{
    width: 40%;
}
.FacturationListDate{
    width: 20%;
}
.FactureVisionHeader{
    width: 95%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.DevisModifButton{
    background-color: var(--blue);
    color: #FFF;
    width: 8vw;
    border-radius: 6px;
    padding: 8px 5px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 0.8rem;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 0.3s;
}
.DevisModifButton:hover{
    background-color: var(--main-blue);
    transition: 0.3s;
}
.DevisModifEmptySpace{
    width: 8vw;
}
.FactureModifButton{
    background-color: var(--main-dark-blue);
    color: #FFF;
    width: 8vw;
    border-radius: 6px;
    padding: 8px 5px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 0.8rem;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 0.3s;
}
.FactureVisionLogoZone{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 60%;
}
.FactureVisionLogo{
    width: 35%;
    aspect-ratio: 1.414 / 1;
    object-fit: contain;
    object-position: left;
}
.FactureVisionEnterpriseInfo{
    width: 50%;
    margin-left: 10px;
    color: var(--main-dark-blue);
    font-family: var(--main-font);
    font-size: 0.6rem;
}
.FactureVisionNumberZone{
    width: 30%;
    text-align: right;
    font-family: var(--main-font);
    font-size: 0.6rem;
}
.FactureVisionClientInfoZone{
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.FactureVisionComment{
    width: 50%;
    text-align: left;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 100%;
    font-size: 0.6rem;
    color: var(--main-dark-blue);
    padding: 5px;
}
.FactureVisionClientInfo{
    border: solid 1px var(--main-dark-blue);
    color : var(--main-dark-blue);
    width: 50%;
    padding: 5px;
    font-family: var(--main-font);
    border-radius: 5px;
    font-size: 0.6rem;
}
.FacturationVisionArticleListZone{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: solid 1px var(--main-dark-blue);
    width: 98%;
    margin-top: 10px;
    border-radius: 8px ;
    height: 46%
}
.FacturationVisionArticleList{
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 0.6rem;
    color: var(--main-dark-blue);
}
.FacturationVisionArticleListLegend{
    border-bottom: solid 1px var(--main-dark-blue);
    color: #FFF;
    background-color: var(--main-dark-blue);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px 5px 0px 0px;
}
.FacturationVisionArticleListLegendDesignation{
    width: 55%;
    color: #FFF;
    text-align: center;
    padding: 5px 5px;
}
.FacturationVisionArticleListLegendQte{
    width: 15%;
    color: #FFF;
    text-align: center;
    padding: 5px 5px;
}
.FacturationVisionArticleListLegendHt{
    width: 15%;
    color: #FFF;
    text-align: center;
    padding: 5px 5px;
}
.FacturationVisionArticleListLegendTtc{
    width: 15%;
    text-align: center;
    color: #FFF;
    padding: 5px 5px;
}
.FacturationVisionArticleListItem{
    border-bottom: solid 1px var(--main-dark-blue);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.FacturationVisionArticleListItemDesignation{
    width: 55%;
    border-right: solid 1px var(--main-dark-blue);
    text-align: left;
    padding: 5px 5px;
}
.FacturationVisionArticleListItemQte{
    width: 15%;
    border-right: solid 1px var(--main-dark-blue);
    text-align: center;
    padding: 5px 5px;
}
.FacturationVisionArticleListItemHt{
    width: 15%;
    border-right: solid 1px var(--main-dark-blue);
    text-align: center;
    padding: 5px 5px;
}
.FacturationVisionArticleListItemTtc{
    width: 15%;
    text-align: center;
    padding: 5px 5px;
}
.FacturationVisionReglementList{
    width: 98%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: solid 1px var(--main-dark-blue);
    background-color: var(--main-light-grey);
    margin-top: 10px;
    border-radius: 8px ;
}
.FacturationVisionReglementListInactive{
    display: none;
}
.FacturationVisionReglementLine{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}
.FacturationVisionReglementType{
    width: 30%;
    text-align: left;
    display: flex;
    align-items: center;
    font-size: 0.6rem;
}
.FacturationVisionReglementType svg{
    margin-right: 5px!important;
}
.FacturationVisionReglementDate{
    width: 30%;
    text-align: center;
    font-size: 0.6rem;
}
.FacturationVisionReglementAmount{
    width: 30%;
    text-align: right;
    font-size: 0.6rem;
}
.FacturationVisionReglementComment{
    width: 98%;
    text-align: left;
    color: var(--main-dark-blue);
    font-size: 0.6rem;
    margin-top: 10px;
}
.FacturationVisionCheckingZone{
    width: 98%;
    margin-top: 10px;
    color: var(--main-dark-blue);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.6rem;
}

.FacturationVisionPriceZone{
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.FacturationVisionPriceLegend{
    width: 50%;
    text-align: left;
}
.FacturationVisionPrice{
    width: 50%;
    text-align: left;
}
.FactureVisionPaymentMethod{
    width: 98%;
    text-align: center;
    font-size: 0.5rem;
    color: var(--main-dark-blue);
    margin-top: 10px;
}
.FactureVisionEnterpriseDetails{
    margin-top: 10px;
    width: 98%;
    text-align: center;
    font-size: 0.5rem;
    color: var(--main-dark-blue);
}
.ParameterMask{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    z-index: 100;
    display: none;
}
.ParameterMaskActive{
    display: flex;
}
.ParameterBloc{
    width: 60%;
    height: 80%;
    background-color: #FFF;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
}
.ParameterTitle{
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    color: #FFF;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    padding: 10px 5%;
    background-color: var(--main-dark-blue);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ParameterClose{
    width: 100%;
    text-align: right;
    font-size: 0.8rem;
    color: #FFF;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;

}
.ParameterVente{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;
    border-bottom: solid 1px var(--main-light-grey);
    font-family: "Poppins", sans-serif;
}
.ParameterVente label{
    width: 100%;
    text-align: left;
    font-size: 0.8rem;
    color: var(--main-middle-grey);
}
.ParameterVente textarea{
    width: 100%;
    height: 250px;
    padding: 10px;
    border: solid 1px var(--main-middle-grey)!important;
    border-radius: 5px;
    background-color: var(--main-light-grey);
    color: var(--main-middle-blue)!important;
    margin-bottom: 10px;
}
.ParameterPersonalisation{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;
    border-bottom: solid 1px var(--main-light-grey);
    font-family: "Poppins", sans-serif;
}
.ParameterPersonalisationTitle{
    width: 100%;
    text-align: left;
    font-size: 1rem;
    color: var(--main-dark-blue);
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 600;

}
.ParameterPersonalisation label{
    width: 55%;
    text-align: left;
    font-size: 0.8rem;
    color: var(--main-middle-grey);
}
.ParameterPersonalisation input, select{
    width: 40% !important;
    text-align: left;
    font-size: 0.8rem;
    color: var(--main-middle-grey);
}
.ParameterSave{
    width: 40%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 5px;
    border-radius: 5px;
    background-color: var(--main-dark-blue);
    color: #FFF;
    cursor: pointer;
    margin: 10px;
    transition: 0.3s;
}
.ParameterSave:hover{
    background-color: var(--main-darkest-blue);
    transition: 0.3s;
}

.AvoirMask{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    z-index: 100;
    display: none;
}
.AvoirMaskActive{
    display: flex;

}
.AvoirBloc{
    width: 40%;
    min-height: 60%;
    max-height: 80%;
    background-color: #FFF;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    overflow: auto;
    font-family: var(--main-font);
    font-size: 0.8rem;
    color: var(--main-dark-blue);
}
.AvoirTitleZone{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.AvoirTitle{
    font-size: 1.2rem;
    color: var(--main-dark-blue);
}
.AvoirClose{
    font-size: 0.8rem;
    color: var(--main-dark-blue);
    cursor: pointer;
}
.AvoirForm{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
}
.AvoirFormClient{
    width: 100%;
    padding: 5px;
    border: solid 1px var(--main-light-grey);
    border-radius: 5px;
    margin-bottom: 10px;
}
.AvoirFormClientTitle{
    width: 100%;
    text-align: left;
    font-size: 0.8rem;
    color: var(--main-dark-blue);
    margin-bottom: 5px;
}
.AvoirFormClientName{
    width: 100%;
    padding: 5px;
    border: solid 1px var(--main-light-grey);
    border-radius: 5px;
    margin-bottom: 10px;
}
.AvoirFormClientAdresse{
    width: 100%;
    padding: 5px;
    border: solid 1px var(--main-light-grey);
    border-radius: 5px;
    margin-bottom: 10px;
}
.AvoirFormClientZip{
    width: 100%;
    padding: 5px;
    border: solid 1px var(--main-light-grey);
    border-radius: 5px;
    margin-bottom: 10px;
}
.AvoirFormClientTel{
    width: 100%;
    padding: 5px;
    border: solid 1px var(--main-light-grey);
    border-radius: 5px;
    margin-bottom: 10px;
}
.AvoirFormClientMail{
    width: 100%;
    padding: 5px;
    border: solid 1px var(--main-light-grey);
    border-radius: 5px;
    margin-bottom: 10px;
}
.FactureModifArticleExemplaire{
    border: none;
    border-radius: 3px;
    padding: 3px;
    width: 10%;
}
.FactureModifArticlePriceHt{
    border: none;
    border-radius: 3px;
    padding: 3px;
    width: 9%;
}
.FactureModifArticlePriceTtc{
    border: none;
    border-radius: 3px;
    padding: 3px;
    width: 9%;
}
.FactureModifTotal{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border: solid 1px var(--main-light-grey);
    border-radius: 4px;
    margin: 10px 0px;
}
.FactureModifTotalAvoir{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border: solid 1px var(--main-light-grey);
    border-radius: 4px;
    margin: 10px 0px;
}
.FactureModifValidationZone{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.FactureModifValidation{
    padding: 5px 10px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
    background-color: var(--main-grey);
    color: #FFF;
    margin: 10px;
}
.FactureModifValidation:hover{
    background-color: var(--main-dark-grey);
    transition: 0.3s;
}
.FactureModifAnnulation{
    padding: 5px 10px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
    background-color: #FFF;
    color: var(--main-grey);
    margin: 10px;
}
.FactureModifAnnulation:hover{
    background-color: var(--main-red);
    color: #FFF;
    transition: 0.3s;
}


.FactureVisionFloatBar{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5px 10px;
    font-size: 0.7rem;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0px 0px 4px 4px;  
    opacity: 0;
    cursor: pointer;
    pointer-events: none;
    transition: 0.3s;
}
#facturationVisionZone:hover .FactureVisionFloatBar{
    opacity: 1;
    pointer-events: all;
    transition: 0.3s;
}
.avoirButton{
    padding: 3px 10px;
    margin: 5px 10px;
    background-color: #FFF;
    color: var(--main-grey);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
}
.avoirButton:hover{
    color: var(--main-dark-grey);
    background-color: var(--var-light-grey);
    transition: 0.3s;
}
.sendByMailButton{
    padding: 3px 10px;
    margin: 5px 10px;
    border-radius: 4px;
    background-color: #FFF;
    color: var(--main-grey);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    transition: 0.3s;
    margin-right: 10px;
}
.sendByMailButton:hover{
    color: var(--main-dark-grey);
    background-color: var(--main-light-grey);
    transition: 0.3s;
}
.printButton{
    padding: 3px 10px;
    margin: 5px 10px;
    border-radius: 4px;
    background-color: #FFF;
    color: var(--main-grey);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    transition: 0.3s;
    margin-right: 10px;
}
.printButton:hover{
    color: var(--main-dark-grey);
    background-color: var(--main-light-grey);
    transition: 0.3s;
}
.PdfMask{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    z-index: 100;
    display: none;
}
.PdfMaskActive{
    display: flex;
}

.mailSendMaskUnactive{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    z-index: 100;
    display: flex;
    pointer-events: none;
    opacity: 0;
    transition: 0.3s;
}
.mailSendMask{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    z-index: 100;
    display: flex;
    pointer-events: all;
    opacity: 1;
    transition: 0.3s;
}
.mailSendBox{
    width: 40%;
    height: 60%;
    background-color: #FFF;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
}
.mailSendBox input{
    width: 90%;
    padding: 5px;
    border: solid 1px var(--main-light-grey);
    border-radius: 5px;
    margin: 10px;
}
.mailSendBoxTitle{
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    color: var(--main-dark-blue);
    padding: 10px;
    border-bottom: solid 1px var(--main-light-grey);
}
.mailSendBoxText{
    width: 90%;
    text-align: left;
    font-size: 0.8rem;
    color: var(--main-dark-blue);
    padding: 10px;
}
.mailSendBoxButton{
    width: 90%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 5px;
    border-radius: 5px;
    background-color: var(--main-dark-blue);
    color: #FFF;
    cursor: pointer;
    margin: 10px;
    transition: 0.3s;
}

.reglementMask{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    opacity: 1;
    pointer-events: all;
    transition: 0.3s;
}
.reglementMaskUnactive{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
}
.reglementBloc{
    width: 40%;
    height: 60%;
    background-color: #FFF;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
}
.reglementTitle{
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    color: var(--main-dark-blue);
    padding: 10px;
    border-bottom: solid 1px var(--main-light-grey);
}
.reglementLine{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border-bottom: solid 1px var(--main-light-grey);
}
.reglementType{
    width: 30%;
    text-align: left;
    display: flex;
    align-items: center;
    color: var(--main-dark-blue);
    font-size: 0.6rem;
}
.reglementType svg{
    margin-right: 5px;
}
.reglementAmount{
    width: 30%;
    text-align: right;
    color: var(--main-dark-blue);
    font-size: 0.6rem;
}
.reglementDate{
    width: 30%;
    text-align: center;
    color: var(--main-dark-blue);
    font-size: 0.6rem;
}
.reglementMoreButton{
    width: 90%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 5px;
    border-radius: 5px;
    background-color: var(--main-dark-blue);
    color: #FFF;
    cursor: pointer;
    margin: 10px;
    transition: 0.3s;
}
.reglementMoreButton:hover{
    background-color: var(--main-darkest-blue);
    transition: 0.3s;
}
.reglementCloseButton{
    width: 90%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 5px;
    border-radius: 5px;
    background-color: var(--main-red);
    color: #FFF;
    cursor: pointer;
    margin: 10px;
    transition: 0.3s;
}
.reglementSaveButton{
    width: 90%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 5px;
    border-radius: 5px;
    background-color: var(--main-dark-blue);
    color: #FFF;
    cursor: pointer;
    margin: 10px;
    transition: 0.3s;
}
.FactureModifBassinZone{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 5px;
    border: solid 1px var(--main-light-grey);
    background-color: var(--main-light-grey);
    border-radius: 4px;
    margin: 10px 0px;
}
.FactureModifBassinZone input{
    background-color: #FFF!important;
}
.FactureModifBassinTitle{
    width: 100%;
    text-align: left;
    background-color: var(--main-dark-blue);
    color: #FFF;
    padding: 5px 15px;
    border-radius: 5px;
    font-size: 1.2rem;
}
.FactureModifBassinName{
    width: 30%;
    padding: 5px;
    color: var(--main-dark-blue);
    font-size: 0.8rem;
}
.FactureModifBassinNameInput{
    width: 60%;
    padding: 5px;
    border: solid 1px var(--main-light-grey);
    border-radius: 4px;
    color: var(--main-dark-blue);
    font-size: 0.8rem;
}

@media screen and (max-width: 768px) {
    .Facturation{
        overflow: auto;
        height: unset;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 15vh 0vh;
    }
    .FacturationActionZone{
        width: 100%;
        height: unset;
        flex-direction: column;
        align-items: center;
    }
    .FacturationParamBand{
        width: 96%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
        border-radius: 4px;
        margin: 10px 0px;
    }
    .FacturationList{
        height: 50%;
    }
    .FacturationListEmpty{
        width: 80%;
        margin: 2vh 10%;
    }
    .FacturationList li{
        flex-direction: column;
        align-items: flex-start;
    }
    .FacturationListNum{
        width: 100%;
    }
    .FacturationListClient{
        width: 100%;
    }
    .FacturationListDate{
        width: 100%;
    }
    .FacturationListLegendNum{
        width: 100%;
    }
    .FacturationListLegendClient{
        width: 100%;
    }
    .FacturationListLegendDate{
        width: 100%;
    }
    .FactureVisionHeader{
        flex-direction: column;
    }
    .FactureVisionLogoZone{
        width: 100%;
        justify-content: center;
    }
    .FactureVisionLogo{
        width: 50%;
    }
    .FactureVisionEnterpriseInfo{
        width: 100%;
        margin-left: 0px;
    }
    .FactureVisionNumberZone{
        width: 100%;
        text-align: center;
    }
    .FactureVisionComment{
        width: 100%;
        text-align: center;
    }
    .FactureVisionArticleListZone{
        width: 100%;
    }
    .FacturationVisionArticleList{
        font-size: 0.5rem;
        height: unset;
    }
    .FacturationVisionArticleListLegend{
        font-size: 0.5rem;
    }
    .FacturationVisionArticleListLegendDesignation{
        width: 50%;
    }
    .FacturationVisionArticleListLegendQte{
        width: 15%;
    }
    .FacturationVisionArticleListLegendHt{
        width: 15%;
    }
    .FacturationVisionArticleListLegendTtc{
        width: 15%;
    }
    .FacturationVisionArticleListItem{
        flex-direction: column;
    }
    .FacturationVisionArticleListItemDesignation{
        width: 100%;
    }
    .FacturationVisionArticleListItemQte{
        width: 100%;
    }
    .FacturationVisionArticleListItemHt{
        width: 100%;
    }
    .FacturationVisionArticleListItemTtc{
        width: 100%;
    }
    .FacturationVisionReglementList{
        width: 100%;
    }
    .FacturationVisionReglementLine{
        flex-direction: column;
    }
    .FacturationVisionReglementType{
        width: 100%;
        text-align: center;
    }
    .FacturationVisionReglementDate{
        width: 100%;
        text-align: center;
    }
    .FacturationVisionReglementAmount{
        width: 100%;
        text-align: center;
    }
    .FacturationVisionReglementComment{
        width: 100%;
        text-align: center;
    }
    .FacturationVisionCheckingZone{
        width: 50%;
        flex-direction: column;
    }
    .FacturationVisionPriceZone{
        width: 50%;
        flex-direction: column;
    }
    .FacturationVisionPriceLegend{
        width: 100%;
        text-align: center;
    }
    .FacturationVisionPrice{
        width: 100%;
        text-align: center;
    }
    .FactureVisionFloatBar{
        font-size: 0.5rem;
    }
    .FactureModifBackButton{
        width: 96%;
        text-align: center;
        padding: 5px;
        margin: 5px;
        border-radius: 4px;
        cursor: pointer;
        transition: 0.3s;
    }
    .FacturationVisionArticleListZone{
        height: unset;
    }
    .FactureVisionEnterpriseDetails{
        font-size: 0.3rem;
    }
}

@media screen and (max-width: 480px) {
    .Facturation{
        overflow: auto;
        height: unset;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 15vh 0px;
    }
    .FacturationActionZone{
        width: 100%;
        height: unset;
        flex-direction: column;
        align-items: center;
    }
    .FacturationVisionEmptyButton{
        width: 80%;
        text-align: center;
        padding: 5px;
        margin: 5px;
        border-radius: 4px;
        cursor: pointer;
        transition: 0.3s;
    }
    .FacturationList{
        height: 40%;
    }
    .FacturationListEmpty{
        width: 90%;
        margin: 2vh 5%;
    }
    .FacturationList li{
        flex-direction: column;
        align-items: flex-start;
    }
    .FacturationListNum{
        width: 100%;
    }
    .FacturationListClient{
        width: 100%;
    }
    .FacturationListDate{
        width: 100%;
    }
    .FacturationListLegendNum{
        width: 100%;
    }
    .FacturationListLegendClient{
        width: 100%;
    }
    .FacturationListLegendDate{
        width: 100%;
    }
    .FactureVisionHeader{
        flex-direction: row;
    }
    .FactureVisionLogoZone{
        width: 70%;
        justify-content: center;
    }
    .FactureVisionLogo{
        width: 40%;
    }
    .FactureVisionEnterpriseInfo{
        width: 58%;
        margin-left: 3px;
    }
    .FactureVisionNumberZone{
        width: 30%;
        text-align: right;
    }
    .FactureVisionComment{
        width: 100%;
        text-align: center;
    }
    .FactureVisionArticleListZone{
        width: 100%;
    }
    .FacturationVisionArticleList{
        font-size: 0.5rem;
        height: unset;
    }
    .FacturationVisionArticleListLegend{
        font-size: 0.5rem;
    }
    .FacturationVisionArticleListLegendDesignation{
        width: 50%;
    }
    .FacturationVisionArticleListLegendQte{
        width: 15%;
    }
    .FacturationVisionArticleListLegendHt{
        width: 15%;
    }
    .FacturationVisionArticleListLegendTtc{
        width: 15%;
    }
    .FacturationVisionArticleListItem{
        flex-direction: row;
        align-items: stretch;
    }
    .FacturationVisionArticleListItemDesignation{
        width: 50%;
    }
    .FacturationVisionArticleListItemQte{
        width: 15%;
    }
    .FacturationVisionArticleListItemHt{
        width: 15%;
    }
    .FacturationVisionArticleListItemTtc{
        width: 15%;
    }
    .FacturationVisionReglementList{
        width: 100%;
    }
    .FacturationVisionReglementLine{
        flex-direction: column;
    }
    .FacturationVisionReglementType{
        width: 100%;
        text-align: center;
    }
    .FacturationVisionReglementDate{
        width: 100%;
        text-align: center;
    }
    .FacturationVisionReglementAmount{
        width: 100%;
        text-align: center;
    }
    .FacturationVisionReglementComment{
        width: 100%;
        text-align: center;
    }
    .FacturationVisionCheckingZone{
        width: 96%;
        flex-direction: row;
    }
    .FacturationVisionPriceZone{
        width: 50%;
        flex-direction: row;
    }
    .FacturationVisionPriceLegend{
        width: 100%;
        text-align: center;
    }
    .FacturationVisionPrice{
        width: 100%;
        text-align: center;
    }
    .FactureVisionFloatBar{
        font-size: 0.5rem;
    }
    .FacturationActionBloc{
        width: 100%;
        height: unset;
        min-height: 40vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .FacturationActionBar{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .FactureDevisChoice{
        width: 96%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-bottom: 2%;
        margin-top: 2%;
    }
    .FactureChoice {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .DevisChoice {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .FactureDateChoice{
        width: 96%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 2% 0px;
    }
    .FactureDateChoice input{
        width: 100%;
        margin-bottom: 2%;
    }
    .FactureSearch{
        width: 96%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 2%;
    }
    .FacturationVisionZone{
        width: 96%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .FactureVisionClientInfo{
        width: 100%;
        padding: 5px;
        border: solid 1px var(--main-dark-blue);
        border-radius: 5px;
        margin-bottom: 10px;
        line-height: 0.5rem;
    }
    .FacturationVisionArticleListZone{
        height: unset;
    }
    .FactureVisionEnterpriseDetails{
        font-size: 0.3rem;
    }
}

