:root::-webkit-scrollbar{
  display: none;
}

:root {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
*{
  box-sizing: border-box;

  /* Ensemble des couleur du site */
  --main-font : "Gill Sans", sans-serif;
  --main-darkest-blue : rgb(2, 12, 22);
  --main-dark-blue : rgb(3, 20, 37);
  --main-blue : #051D35;
  --blue : #498fd4;
  --main-middle-blue : #316299;
  --main-medium-blue : #AFDDEC;
  --main-light-blue : #e7f3fe;
  --main-dark-grey :rgb(62, 71, 80);
  --main-darkest-grey : #151515;
  --main-grey : #606d7b;
  --main-middle-grey : #b0bac3;
  --main-light-grey : #e6e9ed;
  --main-red : #d14242;
  --main-light-red : #f8d7da;
  --main-dark-red : #a82b2b;
  --main-light-green : #e8f7f1;
  --main-dark-green : #2e7d32;
  --main-light-orange : #f9e8e3;
  --main-dark-orange : #a84300;
  --main-light-yellow : #fff9e7;
  --main-dark-yellow : #fbc02d;
  --main-light-purple : #f3e5f5;
  --main-dark-purple : #6a1b9a;
  --panel-background : #f7f8fa;
  --main-orange : #ff8746;
  --main-yellow : #ffc72d;
  --main-green : #63bf8f;
  --main-gradient : linear-gradient(0deg, var(--blue) 0%, var(--main-blue) 100%);

}
.bold_text{
  font-weight: 600;
}
input, textarea, select, button {
  -ms-overflow-style: none!important;
  overflow: -moz-scrollbars-none!important;
}
input::-webkit-scrollbar, textarea::-webkit-scrollbar, select::-webkit-scrollbar, button::-webkit-scrollbar {
  display: none!important;
}
.App {
  margin: none;
  padding: none;
  background: linear-gradient(var(--main-dark-blue), var(--blue));
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.Alpha_banner{
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 30%;
  bottom: 0;
  right: 20px;
  background: var(--main-blue);
  color: white;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-size: 12px;
  text-align: left;
}
.Alpha_banner_active{
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: 30%;
  bottom: 0;
  right: 20px;
  background: var(--main-blue);
  color: white;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-size: 12px;
  text-align: left;
}
.Alpha_banner p{
  width: 60%;
}
.Alpha_banner_active p{
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Alpha_banner_button{
  background: var(--main-blue);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.Alpha_banner_button:hover{
  background: var(--main-middle-blue);
  transition: 0.3s;
}
.Alpha_banner_close{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 3px;
  margin-left: 10px;
  cursor: pointer;
  background-color: var(--main-dark-red);
  transition: 0.3s;
}
.Alpha_banner_close:hover{
  background-color: var(--main-red);
  transition: 0.3s;
}
.Alpha_banner_more{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: var(--main-blue);
  color: white;
  border-radius: 0px 0px 5px 5px;
  font-size: 12px;
  text-align: center;
  border: solid 1px var(--main-middle-blue);
  border-radius: 5px;
}
.Alpha_banner_more p{
  width: 100%;
  flex-direction: column;

}

@media screen and (max-width: 768px){
  .App{
    width: 100vw;
    height: 100vh;
  }
}

@media screen and (max-width: 480px){
  .App{
    width: 100vw;
    height: 100vh;
  }
}

