.NotificationPanel{
    position: fixed;
    height: 100vh;
    width: 20vw;
    left: 0;
    top: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #FFF;
    box-shadow: 0px 0px 5px rgba(100, 100, 100, 0.5);
    font-family: var(--main-font);
    transition: 0.3s;
    z-index: 2;
}
.NotificationPanelInactive{
    position: fixed;
    height: 100vh;
    width: 20vw;
    left: -20vw;
    top: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #FFF;
    box-shadow: 0px 0px 5px rgba(100, 100, 100, 0.5);
    font-family: var(--main-font);
    transition: 0.3s;
}
.NotifPanelName{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    padding-left: 5%;
    color: #FFF;
    background-color: #777;
    letter-spacing: 1px;
}
.NotificationCloser{
    height: 100%;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor : pointer;
    padding: 5px;
    font-size: 1.8rem;
    background-color: #222;
    color: #FFF;
}
.NotificationZone{
    width: 100%;
    max-height: 85vh;
    overflow-x: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.Notification{
    width: 96%;
    border-radius: 5px;
    border: solid 1px #DDD;
    background-color: #EEE;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 5px;
    cursor: pointer;
    transition : 0.2s;
}
.Notification:hover{
    background-color: #DDD;
    transition : 0.2s;
}
.NotificationContent{
    font-size: 0.8rem;
    color: #444;
    padding: 10px;
}
.NotificationText{
    font-size: 0.75rem;
    color: #888;
}
.NotifActionPart{
    padding: 10px;
}
.NotifImage{
    width: 3vw;
    aspect-ratio: 1 / 1;
    background-color: #444;
    border-radius: 4px;
}
.NotifActionButton{
    width: 5vw;
    font-size: 0.8rem;
    background-color: #CCC;
    color: #444;
    text-align: center;
    border-radius: 3px;
    padding: 3px 0px;
    cursor: pointer;
    transition: 0.3s;
}
.NotifActionButton:hover{
    background-color: #999;
    transition: 0.3s;
}
@media only screen and (max-width: 768px) {
    .NotificationPanel,
    .NotificationPanelInactive {
      width: 30vw;
    }
    .NotificationPanelInactive {
      left: -30vw;
    }
  
    .NotifImage {
      width: 5vw;
    }
  
    .NotifActionButton {
      width: 8vw;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .NotificationPanel,
    .NotificationPanelInactive {
      width: 75vw;
    }
    .NotificationPanelInactive {
      left: -50vw;
    }
    .NotifImage {
      width: 8vw;
    }
    .NotifActionButton {
      width: 13vw;
    }
    
    .NotificationPanelInactive{
        left: -75vw;
    }
  }
