* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--main-font);
}

.appear {
  display: block;
}

.disappear {
  display: none;
}

.inputAlert {
  border: solid 1px red;
}

.OnePage {
    width: 100%;
    height: 100vh;
    background-color: #FFF;
    font-family: var(--main-font);
    overflow-x: auto;
}  

.OnePage_menu {
  width: 100%;
  height: 10vh;
  font-size: 0.8rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  color: var(--main-dark-blue);
  align-items: center;
  justify-content: space-between;
  padding: 0px 15%;
  background-color: var(--main-dark-blue);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  z-index: 1000;
}
.logo_bloc{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 35%;
}
.logo {
  height: 5vh;
  width: 5vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../public/logo192.png');
}
.company_name{
  font-size: 1.2rem;
  color: #FFF;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-left: 10px;
}

.menu_line {
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
}

.menu_line_button {
    cursor: pointer;
    height: 10vh;
    color: #FFF;
    border-bottom: solid 2px transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    padding: 0px 10px;
}

.menu_line_button:hover {
  border-bottom: solid 5px #FFF;
  transition: 0.3s;
}

.menu_burger{
    display: none;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    font-size: 1.5rem;
    cursor: pointer;
    height: 3vh;
    width: 3vh;
    position: relative; 
}

.burger_line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #FFF;
  border-radius: 5px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Ajoutez ces lignes pour positionner correctement les lignes du burger */
.burger_line:nth-child(1) {
  transform: translateY(-50%) translateY(-10px);
} 
.burger_line:nth-child(2) {
  transform: translateY(-50%);
} 
.burger_line:nth-child(3) {
  transform: translateY(-50%) translateY(10px);
}

  /* Mettez à jour ces lignes pour créer la croix avec animation */
.menu_burger_active .burger_line:nth-child(1) {
    transform: rotate(45deg) translateY(-50%) translateX(-1px);
    animation: burgerToCross 0.3s ease forwards;
}
  
.menu_burger_active .burger_line:nth-child(2) {
    transform: scaleX(0);
}
  
.menu_burger_active .burger_line:nth-child(3) {
    transform: rotate(-45deg) translateY(-50%) translateY(-0px);
    animation: burgerToCrossReverse 0.3s ease forwards;
}
.connect {
  background-color: #FFF;
  color: var(--main-dark-blue);
  border-radius: 50px;
  padding: 5px 15px;
  cursor: pointer;
  transition: 0.2s;
}
.connect:hover {
  opacity: 0.8;
  transform: scale(1.025);
  transition: 0.2s;
}

.slogan_zone {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--main-gradient);
  padding: 5% 15%;
}

.slogan {
  width: 70%;
  padding-right: 5%;
  text-align: left;
  font-size: 3rem;
  color: #FFF;
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
}
.slogan_img {
    width: 30%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    aspect-ratio: 1 / 1;
}
.suscribe {
  width: 15vw;
  text-align: center;
  padding: 10px 0px;
  color: #FFF;
  background-color: var(--main-dark-blue);
  border-radius: 5px;
  margin-top: 5vh;
  font-size: 1rem;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.3s;
}

.suscribe:hover {
  background-color: var(--main-blue);
  transition: 0.3s;
}

.suscribe_box_mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 1;
  z-index: 1000;
  pointer-events: all;
  transition: 0.3s;
}

.suscribe_box_mask_unactive {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}

.suscribe_box {
  max-height: 80%;
  min-height: 60%;
  width: 45%;
  overflow-x: auto;
  background-color: #FFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  opacity: 1;
  pointer-events: all;
  /* faire disparaitre la barre de défilement */
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar{
    display: none;
  };
  transition: 0.3s;
}

.suscribe_box input, select{
  width: 100%!important;
  padding: 10px!important;
  border-radius: 5px!important;
  border: solid 1px var(--main-middle-grey)!important;
  background-color: var(--main-light-grey)!important;
  color: var(--main-dark-blue)!important;
  margin: 5px 0px!important;
}
.suscribe_box label{
  width: 100%!important;
  font-size: 0.8rem!important;
  color: var(--main-dark-blue)!important;
  text-align: left!important;
  padding: 5px 10px!important;
  margin-top: 1%!important;
}

.my-custom-button-class span{
  /* Style bouton de paiement Stripe */
  justify-content: center!important;
  background: none!important;
  background-color: var(--blue)!important;
  display: flex!important;
  align-items: center!important;
  color: #FFF!important;
  border-radius: 5px!important;
  padding: 15px 20px!important;
  cursor: pointer!important;
  font-size: 1rem!important;
  text-transform: uppercase!important;
  transition: 0.3s!important;
}
.my-custom-button-class span:hover{
  background-color: var(--main-blue)!important;
  transition: 0.3s!important;
}

.suscribe_box_unactive {
  max-height: 80%;
  width: 45%;
  overflow-x: auto;
  background-color: #FFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}

.suscribe_box_title {
  width: 100%;
  text-align: center;
  background-color: var(--main-dark-blue);
  color: #FFF;
  padding: 15px 0px;
  font-size: 1.8rem;
  letter-spacing: 1px;
  font-weight: 600;
}

.suscribe_form {
  margin: 10px 0px;
  width: 100%;
  padding: 5px;
}

.suscribe_form label,
select,
input {
  color: var(--main-dark-blue) !important;
  font-size: 0.8rem !important;
  width: 100%!important;
}
.suscribe_form select, input{
  padding: 10px!important;
  border-radius: 5px!important;
  border: solid 1px var(--main-middle-grey)!important;
  background-color: var(--main-light-grey)!important;
  color: var(--main-dark-blue)!important;
  margin: 5px 0px!important;

}

.suscribe_form fieldset {
  border-radius: 4px;
  border: solid 1px #DDD;
  color: var(--main-dark-blue);
  font-style: italic;
  margin-top: 2vh;
}

.suscribe_form_action {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 15px 0px;
  background-color: var(--main-dark-blue);
}

.suscribe_form_back {
  width: 25%;
  padding: 5px;
  text-align: center;
  color: #FFF;
  border-radius: 4px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: 0.3s;
}

.suscribe_form_back:hover{
  background-color: #FFF;
  color: var(--main-dark-blue);
  transition: 0.3s;
}

.suscribe_form_valid {
  width: 25%;
  padding: 5px;
  text-align: center;
  background-color: var(--main-dark-blue);
  border: solid 1px #FFF;
  color: #FFF;
  border-radius: 4px;
  font-size: 0.8rem;
  cursor: pointer;
}

.suscribe_form_valid:hover{
  background-color: #FFF;
  color: var(--main-dark-blue);
  transition: 0.3s;
}

.suscribe_form_valid_active {
  width: 25%;
  padding: 5px;
  text-align: center;
  background-color: var(--main-light-blue);
  color: #FFF;
  border-radius: 4px;
  font-size: 0.8rem;
  cursor: pointer;
}

.enterprise_more_bloc {
  width: 100%;
  background-color: #EEE;
  border-radius: 4px;
  padding: 5px;
}

.enterprise_more_name {
  width: 100%;
  text-align: left;
  font-size: 1.2rem;
  color: var(--main-dark-blue);
  font-weight: 600;
  margin-top: 5px;
}

.enterprise_more_reduce {
  width: 100%;
  text-align: left;
  font-size: 0.8rem;
  color: var(--main-dark-blue);
  font-style: italic;
  margin-top: 5px;
  cursor: pointer;
}

.enterprise_more_delete {
  width: 100%;
  text-align: left;
  font-size: 0.8rem;
  color: var(--main-dark-blue);
  font-style: italic;
  margin-top: 5px;
  cursor: pointer;
}

.enterprise_more_form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
}

.enterprise_more_form_close {
  height: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.more_enterprise_button {
  border-radius: 4px;
  width: 100%;
  padding: 10px 0px;
  background-color: var(--main-middle-blue);
  color: #FFF;
  font-size: 0.8rem;
  font-style: normal;
  margin-top: 5px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
}

.more_enterprise_button:hover {
  background-color: var(--main-blue);
  transition: 0.3s;
}

.more_enterprise_button_unactive {
  border-radius: 4px;
  width: 100%;
  padding: 5px 0px;
  background-color: var(--main-dark-blue);
  color: #FFF;
  font-size: 0.8rem;
  font-style: normal;
  margin-top: 5px;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
  pointer-events: none;
}

.suscribe_alert_box {
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
  color: #fff;
  background-color: #e77b7b;
  border: solid 1px #b13232;
  border-radius: 4px;
  margin: 10px 1%;
}

.suscribe_alert_box_unactive {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
  color: #fff;
  background-color: #e77b7b;
  border: solid 1px #b13232;
  border-radius: 4px;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}

.connection_box_mask_unactive {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}
.connection_box_mask {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 1;
  pointer-events: all;
  transition: 0.3s;
}
.connection_box_unactive {
  width: 30%;
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}

.connection_box {
  width: 30%;
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 1;
  pointer-events: all;
  transition: 0.3s;
}

.connection_box_title {
  width: 100%;
  text-align: center;
  color: #FFF;
  background-color: var(--main-dark-blue);
  padding: 15px 0px;
  border-radius: 4px 4px 0px 0px;
  font-size: 1.8rem;
  letter-spacing: 1px;
  font-weight: 600;
}

.connection_box select, input{
  width: 96%!important;
  font-size: 0.8rem;
  padding: 10px;
  border-radius: 5px;
  border: solid 1px var(--main-middle-grey);
  background-color: var(--main-light-grey);
  color: var(--main-dark-blue);
  margin: 10px 2%;
}
.connection_box label{
  width: 100%;
  font-size: 0.8rem;
  color: var(--main-dark-blue);
  text-align: left;
  padding: 5px 10px;
  margin-top: 1%;
}

.rememberBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 0px;
}

.rememberBox label {
  width: 80%;
  text-align: left;
}

.rememberBox input {
  width: 10%!important;
  text-align: left;
}

.forgotPass {
  width: 100%;
  padding: 5px 0px;
  text-align: left;
  font-size: 0.8rem;
  color: var(--main-dark-blue);
  font-style: italic;
  padding-left: 10px;
  cursor: pointer;
}

.whatisit {
  width: 70%;
  height: 35vw;
  background-color: var(--main-medium-blue);
  border-radius: 5px;
  margin: 8vh 15%;
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.whatisit_img {
  height: 35vw;
  aspect-ratio: 1 / 1;
  background-image: url(../../public/images/kid-jump.jpg);
  background-size: cover;
  background-position: center;
}
.whatisit_text {
    width: 45%;
    font-size: 1.5rem;
    text-align: left;
    padding: 30px;
    color: var(--main-dark-blue);
}
.whatisit_text_14{
  font-size: 2.5rem;
  font-weight: 800;
  font-size: "Poppins", sans-serif;
}
.whatisit_text_free{
  font-size: 1.5rem;
  font-weight: 400;
  font-size: "Poppins", sans-serif;
  color: var(--main-dark-blue);
}
.whatisit_text_description{
  font-size: 1rem;
  font-weight: 400;
  font-size: "Poppins", sans-serif;
  color: var(--main-dark-grey);
  border-left: solid 2px var(--main-dark-blue);
  padding: 5px 15px;
  text-align: justify;
  margin: 15% 0%;
}
.whatisit_text_action_button{
  width: 100%;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  transition: 0.3s;
}
.whatisit_text_action_button:hover{
  transform: scale(1.02);
  transition: 0.3s;
}
.whatisit_text_action_button svg{
  margin-left: 10px;
}

.whatisit_long_text {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin: 1vh 15%;
  font-size: 1rem;
  padding: 10px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: var(--main-dark-blue);
}

.pole_explication {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5vh;
  padding: 0px 15%;
  font-family: "Poppins", sans-serif;
}

.pole_explication_img {
  width: 100vw;
  height: 50vh;
  background-color: #555;
  background-size: cover;
  background-position: center;
}
.pole_case {
    width: 30%;
    min-height: 45vh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #FFF;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.pole_nbr {
  width: 100%;
  text-align: left;
  font-size: 5rem;
  padding: 10px;
  color: var(--main-light-grey);
  font-weight: 600;
}

.pole_text {
  color: var(--main-dark-blue);
  width: 100%;
  padding: 0px 10px;
  text-align: left;
  font-size: 1.2rem;
  font-weight: 600;
}

.pole_text p {
  font-size: 1rem;
  font-weight: 400;
  color: var(--main-dark-grey);
}

.pole_more {
  width: 100%;
  padding: 10px;
  text-align: right;
  color: var(--main-dark-blue);
  font-size: 1rem;
  font-style: italic;
  cursor: pointer;
  font-weight: 400;
}

.pole_more:hover {
  color: var(--main-blue);
}

.detail {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin: 5vh 15%;
  font-size: 1rem;
  background-color: var(--main-light-grey);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  -webkit-transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.detail_text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin: 3vh 15%;
  font-size: 1rem;
}

.documentationLink {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2vh;
  padding: 10px 15px;
  background-color: var(--main-dark-blue);
  color: #FFF;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.documentationLink svg {
  margin-left: 5px;
}

.documentationLink:hover {
  background-color: var(--main-blue);
  transition: 0.3s;
}

.contact {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5vh 15%;
  font-size: 1rem;
}

.contactText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 45%;
  text-align: center;
  color: var(--main-dark-blue);
  font-size: 1.8rem;
  letter-spacing: 1px;
}

.contactImage {
  width: 50%;
  aspect-ratio: 1 / 1;
  background-color: #555;
  background-image: url('../../public/images/contact.jpg');
  background-size: cover;
}

.contact_button {
  width: 20vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  color: #FFF;
  background-color: var(--main-dark-blue);
  border-radius: 5px;
  margin-top: 5vh;
  font-size: 1rem;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.3s;
}

.contact_button:hover {
  background-color: var(--main-blue);
}

.contact_button svg {
  margin-left: 5px;
  transition: 0.3s;
}

.contact_button:hover svg {
  margin-left: 10px;
  transition: 0.3s;
}

.forgotPass_box_mask{
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 1;
    pointer-events: all;
    z-index: 1000;
    transition: 0.3s;
}
.forgotPass_box_mask_unactive{
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 0;
    pointer-events: none;
    z-index: 1000;
    transition: 0.3s;
}
.forgotNewPass_box{
    width: 70vw;
    height: 70vh;
    background-color: #FFF;
    font-family: var(--main-font);
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow-x: auto;
    padding: 0 15%;
    transition: 0.3s;
    border-radius: 5px;
}
.forgotNewPass_box_unactive {
    width: 100%;
    height: 100vh;
    background-color: #FFF;
    font-family: var(--main-font);
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow-x: auto;
    padding: 0 15%;
    padding-bottom: 10vh;
    transition: 0.3s;
    opacity: 0;
    pointer-events: none;
    border-radius: 5px;
}
.forgotNewPass_box_title{
    width: 100%;
    text-align: center;
    color: var(--main-dark-blue);
    font-size: 1.8rem;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 2vh;
}
.instantContactMask{
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 1;
    pointer-events: all;
    z-index: 1000;
    transition: 0.3s;
}
.instantContact{
    width: 100%;
    background-color: #FFF;
    font-family: var(--main-font);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-x: auto;
    padding: 0 15%;
    transition: 0.3s;
    border-radius: 5px;
}
.instantContact_title{
    width: 100%;
    text-align: center;
    color: var(--main-dark-blue);
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 0vh;
}
.instantContact_text{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
    color: var(--main-dark-blue);
    font-size: 1.2rem;
    margin-bottom: 2vh;
}
.instantContact_text label{
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    color: var(--main-dark-blue);
    margin: 1vh 0px;
    width: 100%;
}
.instantContact_text input{
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px var(--main-middle-grey);
    margin-bottom: 2vh;
}
.contact_button_cancel{
    width: 20vw;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    color: var(--main-middle-grey);
    border-radius: 5px;
    margin-top: 1vh;
    font-size: 1rem;
    cursor: pointer;
    text-transform: uppercase;
    transition: 0.3s;
}
.chat_box{
    /* Fenetre de chat flottante en bas à droite */
    position: fixed;
    bottom: 0;
    right: 5%;
    width: 30vw;
    height: 50vh;
    background-color: #FFF;
    font-family: var(--main-font);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    overflow-x: auto;
    transition: 0.3s;
    border-radius: 5px;
    z-index: 1000;
}
.chat_box_unactive{
    /* Fenetre de chat flottante en bas à droite */
    position: fixed;
    bottom: 0;
    right: 5%;
    width: 30vw;
    height: 50vh;
    background-color: #FFF;
    font-family: var(--main-font);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    overflow-x: auto;
    transition: 0.3s;
    border-radius: 5px;
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
}
.chat_title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    text-align: center;
    color: #FFF;
    background-color: var(--main-dark-blue);
    font-size: 1.2rem;
    letter-spacing: 1px;
    font-weight: 400;
    padding: 10px;
}
.chat_text{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
    color: var(--main-dark-blue);
    font-size: 1.2rem;
    overflow-y: auto;
    padding: 0px;
    /* Cacher la barre de défilement */
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar{
        display: none;
    }
}
.chat_text_zone{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
    color: var(--main-dark-blue);
    font-size: 1.2rem;
    margin-bottom: 5px;
}
.chat_text_zone_text_bot{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1rem;
    font-weight: 400;
    color: var(--main-dark-blue);
    margin-bottom: 1vh;
    width: 100%;
    margin: 5px 3%;
}
.chat_text_zone_text_user{
    display : flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1rem;
    font-weight: 400;
    color: var(--main-dark-blue);
    margin-bottom: 1vh;
    width: 100%;
    margin: 5px 3%;
}
.chat_text_zone_text_message_bot{
    width: 60%;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px var(--main-middle-grey);
    background-color: var(--main-light-grey);
    margin-bottom: 2vh;
    text-align: left;
}
.chat_text_zone_text_message_user{
    width: 60%;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px var(--main-blue);
    background-color: var(--main-light-blue);
    margin-bottom: 1vh;
    text-align: right;
}
.chat_input{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    position: sticky;
    border-top: solid 1px var(--main-middle-grey);
    background-color: #FFF;
    bottom: 0;
    color: var(--main-dark-blue);
    font-size: 1.2rem;
}
.chat_input input{
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px var(--main-middle-grey);
}
.chat_multiple_choice{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    bottom: 0;
    background-color: #FFF;
    font-family: var(--main-font);
    border-top: solid 1px var(--main-middle-grey);
    flex-wrap: wrap;
    text-align: center;
    color: var(--main-dark-blue);
    font-size: 1.2rem;
    margin-bottom: 5px;
}
.chat_multiple_choice_button{
    width: 95%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0px;
    color: #FFF;
    background-color: var(--main-dark-blue);
    border-radius: 5px;
    margin: 5px 0px;
    font-size: 0.8rem;
    cursor: pointer;
    text-transform: uppercase;
    transition: 0.3s;
}
.chat_multiple_choice_button:hover{
  background-color: var(--main-blue);
  transition: 0.3s;
}
.tarifs{
  width: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  margin: 5vh 15%;
  font-size: 1rem;
}
.tarif_case{
  width: 30%;
  min-height: 45vh;
  margin-bottom: 4vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: #FFF;
  border: solid 1px var(--main-light-grey);
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}
.tarif_icone{
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0px;
  background-color: var(--main-light-blue);
  color: var(--blue);
  border-radius: 5px;
  font-size: 1.5rem;
}
.tarif_title{
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  color: var(--main-dark-blue);
  font-weight: 500;
  margin-top: 5px;
}
.tarif_prix{
  width: 100%;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 2.2rem;
  font-family: 'Poppins', sans-serif;
  color: var(--main-dark-grey);
  font-weight: 600;
  margin-top: 5px;
}
.tarif_text_ht{
  font-size: 0.7rem;
  font-weight: 400;
}
.tarif_text{
  width: 100%;
  text-align: center;
  font-size: 1rem;
  color: var(--main-grey);
  font-style: italic;
  margin-top: 5px;
}
.tarif_text_space{
  width: 100%;
  text-align: center;
  height: 1.5rem;
  color: var(--main-dark-blue);
  font-style: italic;
  margin-top: 5px;
}
.tarif_description{
  width: 100%;
  height: 18vh;
  text-align: center;
  font-size: 1rem;
  color: var(--main-dark-blue);
  font-weight: 600;
  margin: 5px;
  background-color: var(--main-light-grey);
  padding: 10px;
  border-radius: 5px;
}
.tarif_description_title{
  width: 100%;
  text-align: center;
  font-size: 1rem;
  color: var(--main-dark-blue);
  font-weight: 600;
  margin-top: 5px;
}
.tarif_description_text{
  width: 100%;
  text-align: left;
  font-size: 1rem;
  color: var(--main-dark-blue);
  font-style: italic;
  font-weight: 400;
  margin-top: 5px;
}

.suscribe_formule_choice {
  width: 98%;
  margin-left: 1%;
  border-radius: 5px;
  border: solid 1px var(--main-light-grey);
  margin-top: 5vh;
  display: flex;
  align-items: top;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
.suscribe_formule_choice_unactive{
  display: none;
}
.suscribe_formule_choice_title {
  width: 100%;
  text-align: left;
  background-color: var(--main-light-grey);
  color: var(--main-middle-grey);
  font-size: 1.5rem;
  padding: 10px 15px;
  font-weight: 600;
  margin-bottom: 1.2vh;
}
.suscribe_formule_choice_try_period{
  width: 96%;
  display: flex;
  align-items: top;
  justify-content: center;
  flex-direction: column;
  background-color: var(--blue);
  padding: 10px;
  border-radius: 5px;
  color: #FFF;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 10px 0px;
}
.suscribe_formule_choice_try_period_title{
  width: 45%;
  text-align: left;
  font-size: 1.5rem;
  color: #FFF;
  font-weight: 600;
  margin-top: 5px;
}
.suscribe_formule_choice_try_period_text{
  width: 45%;
  text-align: left;
  font-size: 0.8rem;
  color: #FFF;
  font-weight: 400;
  font-style: italic;
  margin-top: 5px;
}
.suscribe_formule_choice_case {
  width: 30%;
  min-height: 50vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  background-color: #FFF;
  padding: 10px;
  border-radius: 8px;
  border: solid 1px var(--main-light-grey);
  cursor: pointer;
  font-family: var(--main-font);
  transition: 0.3s;
}
.suscribe_formule_choice_case_active{
  width: 30%;
  min-height: 50vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  background-color: #FFF;
  padding: 10px;
  border-radius: 8px;
  border: solid 2px var(--main-green);
  cursor: pointer;
  font-family: var(--main-font);
  transition: 0.3s;
}
.suscribe_formule_choice_case:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  transform: scale(1.01);
  transition: 0.3s;
}
.suscribe_formule_choice_case_active:hover{
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  transform: scale(1.01);
  transition: 0.3s;
}
.suscribe_formule_choice_case_image {
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: #555;
  border-radius: 5px;
}
.suscribe_formule_choice_case_title {
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  color: var(--main-dark-blue);
  font-weight: 600;
  margin-top: 5px;
}
.suscribe_formule_choice_case_text {
  width: 100%;
  text-align: center;
  font-size: 1rem;
  color: var(--main-dark-blue);
  font-style: italic;
  margin-top: 5px;
}
.suscribe_formule_choice_case_text_price_box{
  width: 100%;
  display: flex;
  align-items: top;
  justify-content: space-between;
  flex-direction: row;
}
.suscribe_formule_choice_case_text_price {
  width: 68%;
  text-align: right;
  font-size: 1.5rem;
  color: var(--main-grey);
  font-weight: 600;
  margin-top: 5px;
}
.suscribe_formule_choice_case_text_price_ht{
  width: 30%;
  text-align: left;
  font-size: 0.8rem;
  color: var(--main-grey);
  font-style: italic;
  margin-top: 5px;
}
.suscribe_formule_choice_case_text_engagement{
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  border-radius: 5px;
  background-color: var(--main-grey);
  color: #FFF;
  margin-top: 5px;
}
.suscribe_formule_choice_case_text_description {
  width: 100%;
  text-align: center;
  font-size: 1rem;
  color: var(--main-grey);
  font-style: italic;
  margin-top: 5px;
}
.suscribe_form_choice_case_number{
  width: 100%;
  text-align: center;
  font-size: 1rem;
  color: var(--main-dark-blue);
  font-weight: 600;
  margin-top: 5px;
}
.suscribe_formule_choice_case_text_button{
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  color: #FFF;
  background-color: var(--main-dark-blue);
  border-radius: 5px;
  margin-top: 5vh;
  font-size: 1rem;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.3s;
}
.suscribe_formule_choice_case_text_button_actif{
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  color: #FFF;
  background-color: var(--main-green);
  border-radius: 5px;
  margin-top: 5vh;
  font-size: 1rem;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.3s;
}
.suscribe_formule_choice_total_case{
  width: 96%;
  margin: 3% 1%;
  border-radius: 5px;
  background-color: var(--main-light-grey);
  color: var(--main-dark-grey);
}
.suscribe_formule_choice_total_title{
  width: 100%;
  text-align: left;
  font-size: 1.2rem;
  color: #FFF;
  background-color: var(--main-middle-blue);
  font-weight: 600;
  padding: 10px;
  border-radius: 5px 5px 0px 0px;
}
.suscribe_formule_choice_total_case_line{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px 15px;
  border-top: solid 1px var(--main-middle-grey);
}
.suscribe_formule_choice_total_case_title{
  width: 50%;
  text-align: left;
  font-size: 1rem;
  color: var(--main-dark-grey);
}
.suscribe_formule_choice_total_case_price{
  width: 50%;
  text-align: right;
  font-size: 1rem;
  color: var(--main-dark-grey);
  font-weight: 600;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.suscribe_formule_choice_total_case_price_ttc{
  margin-left: 4px;
  font-size: 0.7rem;
  font-weight: 400;
}

.fournisseur_zone{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  padding: 5vh 15%;
  background-color: var(--main-dark-blue);
  color: #FFF;
}
.fournisseur_title{
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 5vh;
}
.fournisseur_text{
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 5vh;
}
.fournisseur_contact{
  padding: 5px 15px;
  border-radius: 50px;
  background-color: #FFF;
  color: var(--main-dark-blue);
  transition: 0.3s;
}
.fournisseur_contact:hover{
  background-color: var(--main-light-grey);
  color: var(--main-dark-blue);
  transition: 0.3s;
}

@media screen and (max-width: 768px) {
    .OnePage {
      padding: 0 5%;
    }
    .OnePage_menu {
      padding: 0 5%;
      justify-content: space-between;
    }
    .logo {
      height: 8vh;
      width: 8vh;
    }
    .menu_line {
      width: 100%;
      font-size: 0.8rem;
    }
    .slogan_zone {
      flex-direction: column;
      height: auto;
    }
  
    .slogan {
      width: 100%;
      font-size: 2rem;
      text-align: center;
    }
  
    .slogan_img {
      width: 100%;
      margin-top: 20px;
      
    }
  
    .suscribe {
      width: 100%;
      margin-top: 20px;
    }
  
    .suscribe_box {
      width: 90%;
    }
  
    .suscribe_box_title {
      font-size: 1.5rem;
    }
  
    .suscribe_form label,
    .suscribe_form select,
    .suscribe_form input {
      font-size: 0.7rem;
    }
  
    .suscribe_form_action {
      flex-direction: column;
    }
  
    .suscribe_form_back,
    .suscribe_form_valid {
      width: 100%;
      margin-top: 10px;
    }
  
    .whatisit {
      flex-direction: column;
      align-items: center;
      height: unset;
      padding-bottom: 10%;
    }
  
    .whatisit_img {
      width: 115%;
      margin-top: -15%;
      background-image: url(../../public/images/pc-couve.png);
      background-size: cover;
      background-position: center;

    }
  
    .whatisit_text {
      width: 100%;
      text-align: center;
    }
  
    .whatisit_long_text {
      width: 100%;
      margin-top: 20px;
    }
  
    .pole_explication {
      flex-direction: column;
    }
  
    .pole_case {
      width: 100%;
      margin-top: 0;
      height: auto;
    }
  
    .pole_nbr {
      font-size: 3rem;
    }
  
    .pole_text {
      padding: 0 10%;
      font-size: 1rem;
    }
  
    .detail {
      width: 100%;
    }
  
    .detail_text {
      width: 100%;
      text-align: center;
    }
    .contact {
      flex-direction: column;
    }
    .contactText {
      width: 100%;
      margin-top: 20px;
    }
    .contactImage {
      width: 100%;
      margin-top: 20px;
    }
    .contact_button {
      width: 100%;
      margin-top: 20px;
    }
    .pole_explication_img{
      display: none;
    }
    .tarifs{
      flex-direction: column;
    }
    .tarif_case{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .connection_box{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 10px;
    }
}
@media screen and (max-width: 480px) {
    .OnePage {
      padding: 0 5%;
    }
  
    .OnePage_menu {
      padding: 0 5%;
      justify-content: space-between;
    }
  
    .logo {
      height: 8vh;
      width: 8vh;
    }
  
    .menu_line {
      display: none;
    }

    .menu_burger{
        display: flex;
    }
    .slogan_zone {
        margin-top: 15vh;
        flex-direction: column;
        height: auto;
    }
  
    .slogan {
      width: 100%;
      font-size: 2rem;
      text-align: center;
    }
  
    .slogan_img {
      width: 100%;
      margin-top: 20px;
    }
  
    .suscribe {
      width: 100%;
      margin-top: 20px;
    }
  
    .suscribe_box {
      width: 90%;
    }
  
    .suscribe_box_title {
      font-size: 1.5rem;
    }
  
    .suscribe_form label,
    .suscribe_form select,
    .suscribe_form input {
      font-size: 0.7rem;
    }
  
    .suscribe_form_action {
      flex-direction: column;
    }
  
    .suscribe_form_back,
    .suscribe_form_valid {
      width: 100%;
      margin-top: 10px;
    }
  
    .whatisit {
      flex-direction: column;
      align-items: center;
      height: unset;
      padding-bottom: 10%;
    }
  
    .whatisit_img {
      width: 115%;
      margin-top: -15%;
      background-image: url(../../public/images/pc-couve.png);
      background-size: cover;
      background-position: center;

    }
  
    .whatisit_text {
      width: 100%;
      text-align: center;
    }
  
    .whatisit_long_text {
      width: 100%;
      margin-top: 20px;
    }
  
    .pole_explication {
      flex-direction: column;
    }
  
    .pole_case {
      width: 100%;
      margin-top: 10px;
      height: auto;
    }
  
    .pole_nbr {
      font-size: 3rem;
    }
  
    .pole_text {
      padding: 0 10%;
      font-size: 1rem;
    }
  
    .detail {
      width: 100%;
    }
  
    .detail_text {
      width: 100%;
      text-align: center;
    }
  
    .contact {
      flex-direction: column;
    }
  
    .contactText {
      width: 100%;
      margin-top: 20px;
    }
  
    .contactImage {
      width: 100%;
      margin-top: 20px;
    }
  
    .contact_button {
      width: 100%;
      margin-top: 20px;
    }
    .pole_explication_img{
      display: none;
    }
    .tarifs{
      flex-direction: column;
    }
    .tarif_case{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .connection_box{
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 10px;
  }
}


