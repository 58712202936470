.Fournisseur{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 5%;
    align-items: center;
    overflow: auto;
}
.title_bloc{
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    color: #FFF;
    font-family: "Poppins", sans-serif;
}
.logo_block{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    font-size: 1.5em;
    font-weight: 400;
    color: #FFF;
    letter-spacing: 1px;
}
.logo_block .logo{
    margin-right: 10px;
    width: 60px;
    height: 60px;
}

.title_bloc h1{
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 10px;
}
.title_bloc p{
    font-size: 1.2em;
    font-weight: 400;
    text-align: center;
}
.contact_bloc{
    width: 96%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    background-color: #FFF;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: "Poppins", sans-serif;
}
.contact_bloc form{
    width: 99.6%;
    margin: 0.2%;
    padding: 5% 5px;
    border: solid 1px #EEE;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form_close{
    height: 0;
    opacity: 0;
    transition: 0.3s;
    overflow: hidden;
    pointer-events: none;
    padding: 0!important;
    margin: 0!important;
}
.contact_bloc form label{
    width: 90%;
    text-align: left;
}
.contact_bloc form input{
    width: 100%!important;
    margin-bottom: 20px!important;
}
.contact_bloc form textarea{
    width: 100%!important;
    height: 100px;
    margin-bottom: 10px;
    padding: 5px;
    border: solid 1px var(--main-middle-grey)!important;
    background-color: var(--main-light-grey);
    color: var(--main-dark-blue)!important;
    border-radius: 6px;
    font-family: "Poppins", sans-serif;
}
.alert_bloc{
    width: 90%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    background-color: var(--main-light-red);
    color: var(--main-dark-red);
    border: solid 1px var(--main-dark-red);
    padding: 5px;
    border-radius: 8px;
    font-family: "Poppins", sans-serif;
    transition: 0.3s;
}
.alert_bloc_unactive{
    opacity: 0;
    padding: 0;
    transition: 0.3s;
}
.contact_bloc form button{
    width: 50%;
    padding: 10px;
    background-color: var(--main-blue);
    color: #FFF;
    border: none;
    border-radius: 6px;
    font-family: "Poppins", sans-serif;
    font-size: 1.2em;
    cursor: pointer;
    transition: 0.3s;
}
.footer_bloc{
    color: #FFF;
    font-family: "Poppins", sans-serif;
    margin-bottom: 5%;
}
.success_message{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
    background-color: var(--main-light-green);
    color: var(--main-dark-green);
    border: solid 1px var(--main-dark-green);
    padding: 5px;
    border-radius: 8px;
    margin: 20px 0px;
    pointer-events: all;
    transition: 0.3s;
}
.success_message_unactive{
    opacity: 0;
    padding: 0;
    height: 0;
    overflow: hidden;
    pointer-events: none;
    transition: 0.3s;
}