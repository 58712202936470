a{
    color: none;
    text-decoration: none;
    color: #AAA;
    transition: 0.2s;
}
a:active{
    color: #FFF;
}
a:visited{
    color: #AAA;
}
a:hover{
    color: #FFF;
    transition: 0.3s;
}
.menuLineActive{
    background-color: var(--main-light-grey);
    color: var(--main-dark-blue);
    border-radius: 5px;
    transition: 0.3s;
}
.menuLineActive:hover{
    background-color: #FFF;
    color: var(--main-dark-blue)!important;
    transition: 0.3s!important;
}
.menuLineIcon{
    width: 16px;
    height: 16px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 5px;
}
.Appli{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.AppliMenu{
    width: 10vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s;
}
.AppliMenuLight{
    width : 5vw;
    transition: 0.3s;
}
.AppliMenuToggle{
    width: 100%;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    color: #FFF;
    font-size: 1.5rem;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.3s;
}
.AppliMenuToggle:hover{
    color: #FFF;
    transform: scale(1.1);
    opacity: 1;
    transition: 0.3s;
}
.AppliLogo{
    width : 5vw;
    height: 5vw;
    margin: 2.5vw;
    background-image: url('../../public/logo192.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.3s;
}
.AppliLogoLight{
    width: 3vw;
    height: 3vw;
    margin: 1vw;
    transition: 0.3s;
}

.AppliMenuNav{
    font-family: var(--main-font);
    color: var(--main-light-blue);
    font-size: 1rem;
    transition: color 0.3s;
    margin-top: 0vw;
    transition: 0.3s;
}
.AppliMenuNavLight{
    margin-top: 3.5vw;
    transition: 0.3s;
}
.AppliMenuNav ul{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
}
.AppliMenuNav li{
    text-decoration: none;
    width: 80%;
    margin-bottom: 2vh;
    padding: 5px 10px;
    border-radius: 5px;
    transition: 0.3s;
}
.AppliMenuNavLight li{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    aspect-ratio: 1 / 1;
}
.menuLineName{
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 0.8rem;
    justify-content: flex-start;
}
.AppliMenuNav li:hover{
    color: #FFF;
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
    transition: 0.3s;
}
.AppliMenuNavLight li:hover{
    color: var(--main-dark-blue);
    background-color: #FFF;
    cursor: pointer;
    transition: 0.3s;
}
.AppliMenuBottomLight{
    width: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.MenuNotifications{
    width: 8vw;
    border-radius: 5px;
    color: var(--main-dark-blue);
    background-color: var(--main-light-grey);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    text-transform: uppercase;
    font-size: 0.7rem;
    transition: 0.3s;
}
.MenuNotificationsLight{
    width: 4vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: none;
    transition: 0.3s;
}
.NotificationNbrRing{
    background-color: var(--main-red);
    color : #FFF;
    border-radius: 0px 5px 5px 0px;
    font-size: 0.7rem;
    padding: 8px;
    aspect-ratio: 1 / 1;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.NotificationNbrRingInactive{
    background-color: var(--main-grey);
    color : var(--main-dark-blue);
    border-radius: 0px 5px 5px 0px;
    font-size: 0.7rem;
    padding: 8px;
    aspect-ratio: 1 / 1;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.MenuNotifications:hover{
    background-color: var(--main-light-grey);
    background-color: #FFF;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    cursor: pointer;
}
.MenuProfilCase{
    width: 8vw;
    margin-bottom: 1vw;
    margin-top: 1vh;
    padding-top: 5px;
    background-color: var(--main-light-grey);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    border-radius: 5px;
    transition: 0.3s;
}
.MenuProfilCaseLight{
    width: 4vw;
    margin-bottom: 1vw;
    margin-top: 1vh;
    padding-top: 5px;
    background-color: var(--main-light-grey);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    border-radius: 5px;
    transition: 0.3s;
}
.MenuProfilCase:hover{
    background-color: #FFF;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    cursor: pointer;
}
.MenuProfilPicture{
    width: 5vw;
    height: 5vw;
    border-radius: 50px;
    margin: 1vh 0px;
    border: solid 1px #555;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--main-light-grey);
    cursor: pointer;
    transition: 0.3s;
}
.MenuProfilPictureLight{
    width: 3vw;
    height: 3vw;
    border-radius: 50px;
    margin: 0.5vh 0px;
    border: solid 1px #555;
    background-image: url('../../public/Avatar/jeremy.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--main-light-grey);
    cursor: pointer;
    transition: 0.3s;

}
.MenuProfilPicture:hover{
    background-color: var(--blue);
    transition: 0.3s;
}
.MenuProfilName{
    width: 100%;
    text-align: center;
    color: var(--main-dark-blue);
    font-weight: 600;
    margin: 0.5vh 0px;
    font-size: 0.8rem;
    font-weight: 400;
    padding: 0px 5px;
    overflow: hidden;
    letter-spacing: 100;
    display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}
.MenuProfilAccess{
    text-align: center;
    text-transform: uppercase;
    font-size: 0.7rem;
    letter-spacing: 1px;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 0px 0px 5px 5px;
    background-color: var(--main-grey);
    color: #FFF;
    cursor: pointer;
    opacity: 1;
    transition: 0.3s;
}
.MenuProfilAccess:hover{
    color: var(--main-dark-blue);
    transition: 0.3s;
}
.deconnexion{
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.7rem;
    letter-spacing: 1px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    color: #FFF;
    border: solid 1px var(--main-blue);
    background-color: var(--main-blue);
    cursor: pointer;
    opacity: 1;
    transition: 0.3s;
}
.deconnexionLight{
    width: 4vw;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.deconnexion:hover{
    color: #FFF;
    background-color: var(--main-red);
    border: solid 1px var(--main-red);
    transition: 0.3s;
}
.AppliScreen{
  display: flex;
  align-items: center;
  justify-content: center;
    width: 98vw;
    height: 100vh;
    background-color: var(--panel-background);
    border-radius: 15px 0px 0px 15px;
    box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.1);
}
.toastColumn{
    width: 35vw;
    height: 100vh;
    position: fixed;
    bottom: 0;
    right: 5px;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
}
.toast{
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    font-family: var(--main-font);
    background-color: #FFF;
    width: 98%;
    border-radius: 5px;
    box-shadow: 0px 5px 3px #CCC;
    color: #444;
    font-size: 0.8rem;
    transition: 0.4s;
    margin-bottom: 10px;
}
.toastBand{
    width: 100%;
    height: 3rem;
    border-radius: 5px 5px 0px 0px;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 2px;
    color: #FFF;
    margin-bottom: 10px;
}
.toastClose{
    width: 100%;
    border-radius:  0px 0px 5px 5px;
    border-top: solid 1px #DDD;
    text-align: center;
    color: #999;
    padding: 5px;
    margin-top: 10px;
    cursor: pointer;
}
.toastClose:hover{
    background-color: #EFEFEF;
    transition: 0.3s;
}
.emergency0{
    background-color: rgb(7, 209, 149);
}
.emergency1{
    background-color: rgb(255, 199, 46);
}
.emergency2{
    background-color: rgb(255, 162, 0);
}
.emergency3{
    background-color: rgb(255, 84, 37);
}
.emergency4{
    background-color: var(--main-middle-grey);
}
.menu_light{
  display: none;
}
.noSubAlertMask{
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  pointer-events: all;
  transition: 0.3s;
}
.noSubAlertMaskUnactived{
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}
.noSubAlertBox{
  width: 50%;
  min-height: 50%;
  background: #FFF;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  transition: 0.3s;
  font-family: "Poppins", sans-serif;
}
.noSubAlertTitle{
  width: 100%;
  padding: 20px;
  background-color: var(--main-dark-red);
  font-size: 1.5rem;
  font-weight: 600;
  color: #FFF;
  text-align: center;
  margin-bottom: 20px;
  border-radius: 8px 8px 0px 0px;
}
.noSubAlertContent{
  width: 100%;
  padding: 20px;
  font-size: 1rem;
  font-weight: 400;
  color: var(--main-dark-grey);
  text-align: center;
  margin-bottom: 20px;
}
.noSubList{
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 10px;
}
.noSubListItem{
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: solid 1px #EEE;
  border-radius: 5px;
  padding: 10px;
}
.noSubListItemActive{
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: solid 2px var(--main-green);
  border-radius: 5px;
  padding: 10px;
}
.noSubListItemIcone{
  width: 98%;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 1% 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-light-blue);
  color: var(--blue);
}
.noSubListItemTitle{
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  color: var(--main-dark-blue);
  font-family: "Poppins", sans-serif;
  margin: 10px 0px;
}
.noSubListItemPrice{
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  color: var(--main-grey);
  margin: 10px 0px;
}
.noSubListItemPrice span{
  font-size: 0.7rem;
  font-weight: 400;
  color: var(--main-dark-grey);
  margin-left: 5px;
}
.noSubListItemContent{
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--main-dark-grey);
  margin: 10px 0px;
}
.noSubListItemEngagement{
  width: 96%;
  background-color: var(--blue);
  color: #FFF;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 5px;
  margin: 10px 0px;
}
.noSubListItemEngagementWhite{
  width: 96%;
  background-color: transparent;
  color: #FFF;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 5px;
  margin: 10px 0px;
}
.noSubListItemButton{
  width: 96%;
  background-color: var(--main-dark-blue);
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 5px;
  padding: 10px;
  margin: 5px 0px;
  cursor: pointer;;
  transition: 0.3s;
}
.noSubListItemButtonActive{
  width: 96%;
  background-color: var(--main-green);
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 5px;
  padding: 10px;
  margin: 5px 0px;
  cursor: pointer;;
  transition: 0.3s;
}
.noSubListItemButton:hover{
  background-color: var(--main-blue);
  transition: 0.3s;
}
.NoSubAlertBox{
  width: 90%;
  background-color: var(--main-light-red);
  color: var(--main-dark-red);
  border: solid 1px var(--main-red);
  padding: 10px;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
}
.noSubActionZone{
  width: 100%;
  padding: 10px 5%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}
.noSubValid{
  padding: 10px 15px;
  background-color: var(--blue);
  color: #FFF;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
.noSubValid:hover{
  background-color: var(--main-dark-blue);
  transition: 0.3s;
}
.noSubClose{
  padding: 10px 15px;
  background-color: var(--main-red);
  color: #FFF;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.noSubClose:hover{
  background-color: var(--main-dark-red);
  transition: 0.3s;
}
.loadingMask{
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  pointer-events: all;
  transition: 0.3s;
}
.loadingMaskUnactived{
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}
.loadingBox{
  width: 50vh;
  height: 50vh;
  background: #FFF;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.3s;
  font-family: "Poppins", sans-serif;
}
.loadingGif{
  width: 150px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 20px 0px;
}
.loadingContent{
  width: 100%;
  padding: 20px;
  font-size: 1rem;
  font-weight: 400;
  color: var(--main-dark-grey);
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
}
.help_bubble{
  position: fixed;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--main-gradient);
  color: #FFF;
  bottom: 20px;
  right: 20px;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.3s;
}
.help_bubble:hover{
  transform: scale(1.1);
  transition: 0.3s;
}

.help_chat{
  position: fixed;
  max-width: 250px;
  max-height: 300px;
  overflow-y: auto;
  background-color: #FFF;
  color: var(--main-dark-blue);
  border: solid 1px var(--main-light-grey);
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  transition: 0.3s;
  border: solid 2px var(--main-green);
}
.help_chat_unactive{
  display: none;
}
.help_chat_title{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 600;
  color: var(--main-dark-blue);
  margin-bottom: 10px;
  padding: 5px 10px;
}
.help_chat_controls{
  width: 96%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 10px auto;
  padding: 0px 10px;
}
.help_chat_back{
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-middle-grey);
  color: #FFF;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
.help_chat_back:hover{
  background-color: var(--main-dark-grey);
  transition: 0.3s;
}
.help_chat_next{
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-middle-grey);
  color: #FFF;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
.help_chat_next:hover{
  background-color: var(--main-dark-grey);
  transition: 0.3s;
}
.help_chat_close{
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-red);
  color: #FFF;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
.help_chat_close:hover{
  background-color: var(--main-dark-red);
  transition: 0.3s;
}

.first_connec_mask{
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  pointer-events: all;
  transition: 0.3s;
}
.first_connec_box{
  width: 50%;
  background-color: #FFF;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.3s;
  font-family: "Poppins", sans-serif;
}
.first_connec_title{
  width: 100%;
  padding: 20px;
  background-color: var(--main-dark-blue);
  font-size: 1.5rem;
  font-weight: 600;
  color: #FFF;
  text-align: center;
  margin-bottom: 20px;
  border-radius: 8px 8px 0px 0px;
}
.first_connec_content{
  width: 100%;
  padding: 20px;
  font-size: 1rem;
  font-weight: 400;
  color: var(--main-dark-grey);
  text-align: center;
  margin-bottom: 20px;
}
.first_connec_content a {
  color: var(--main-dark-blue);
  text-decoration: none;
}
.first_connec_action{
  width: 100%;
  padding: 10px 5%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}
.first_connec_button{
  padding: 10px 15px;
  background-color: var(--blue);
  color: #FFF;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
.first_connec_button:hover{
  background-color: var(--main-dark-blue);
  transition: 0.3s;
}

/* CSS pour les écrans de plus de 768px */
@media only screen and (max-width: 768px) {
    .Appli {
      width: 100vw;
      height: 100vh;
      flex-direction: column-reverse;
    }
    .AppliLogo {
      display: none;
  }
    .AppliMenu {
      width: 100vw;
      height : 5vh;
    }
    .AppliMenuLight {
      width: 5vw;
    }
    .AppliMenuNav {
      margin-top: 0vw;
      width: 100vw;
    }
    .AppliMenuNavLight {
      margin-top: 0.5vh;
      width: 98vw;
    }
    .AppliMenuNav ul {
      flex-direction: row;
      width: 100%;
      height: 5vh;
      margin: 0;
    }
    .AppliMenuNav li {
      width: 80%;
      text-align: left;
      padding: 0px;
      height: 100%;
    }
    .AppliMenuNavLight li {
      text-align: center;
      padding-left: 0;
      width: 75%;
    }
    .MenuNotifications {
      width: 15vw;
      box-shadow: 0px 5px 5px var(--main-darkest-blue);
      padding: 0px;
    }
    .MenuNotificationsLight {
      width: 4vw;
      box-shadow: none;
    }
    .MenuProfilCase {
      width: 8vw;
    }
    .MenuProfilCaseLight {
      width: 4vw;
    }
    .MenuProfilPicture {
      width: 4vw;
      height: 4vw;
    }
    .MenuProfilPictureLight {
      width: 3vw;
      height: 3vw;
    }
    .MenuProfilName {
      font-size: 0.8rem;
    }
    .MenuProfilAccess {
      font-size: 0.7rem;
    }
    .deconnexion {
      font-size: 0.7rem;
    }
    .AppliScreen {
      padding: 0px;
      width: 100vw;
      height: 94vh;
      overflow: auto;
      border-radius: 0px;
    }
    .menuLineName{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .menuLine{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .AppliMenuBottom {
      display: none;
    }
    .menu_light{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      width: 100%;
      height: 0vh;
      background-color: var(--main-dark-blue);
      color: #FFF;
      font-size: 1.5rem;
      cursor: pointer;
      pointer-events: none;
      opacity: 0;
      transition: 0.3s;
      position: fixed;
      top: 0;
      left: 0;
    }
    .menu_light_active{
      background-color: var(--main-light-grey);
      color: var(--main-dark-blue);
      border-radius: 5px;
      opacity: 1;
      pointer-events: all;
      height: 100vh;
      transition: 0.3s;
    }
    .menu_light_line{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
    .menu_light_button{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .menu_light .connect{
      width: 70%;
      text-align: center;
      margin: 10px 0px;
    }
  }
  
  /* CSS pour les écrans de plus de 480px */
  @media only screen and (max-width: 480px) {
    .Appli {
      width: 100vw;
      height: 100vh;
      flex-direction: column-reverse;
    }
    .AppliMenu {
      width: 100vw;
      height : 5vh;
    }
    .AppliMenuLight {
      width: 10vw;
    }
    .AppliLogo {
      display: none;
    }
    .AppliMenuNav {
      margin-top: 0vw;
      width : 100vw;
    }
    .AppliMenuNavLight {
      margin-top: 0.5vh;
      width: 98vw;
    }
    .AppliMenuNav ul {
      flex-direction: row;
      width: 100%;
      height: 5vh;
      margin: 0;
    }
    .AppliMenuNav li {
      width: 80%;
      text-align: left;
      padding: 0px;
    }
    .AppliMenuNavLight li {
      text-align: center;
      padding-left: 0;
      width: 75%;
    }
    .MenuNotifications {
      width: 15vw;
      box-shadow: 0px 5px 5px var(--main-darkest-blue);
      padding: 0px;
    }
    .MenuNotificationsLight {
      width: 15vw;
      box-shadow: none;
      padding-left: 2vw;
    }
    .NotificationNbrRingInactive{
      font-size: 0.9rem;
      color: #FFF;
    }
    .NotificationNbrRing{
      font-size: 0.9rem;
    }
    .MenuProfilCase {
      width: 8vw;
    }
    .MenuProfilCaseLight {
      width: 4vw;
    }
    .MenuProfilPicture {
      width: 4vw;
      height: 4vw;
    }
    .MenuProfilPictureLight {
      width: 3vw;
      height: 3vw;
    }
    .MenuProfilName {
      font-size: 0.8rem;
    }
    .MenuProfilAccess {
      font-size: 0.7rem;
    }
    .deconnexion {
      font-size: 0.7rem;
    }
    .AppliScreen {
      padding: 0px;
      width: 100vw;
      height: 94vh;
      overflow: auto;
      border-radius: 0px;
    }
    .AppliMenuBottom {
      position: fixed;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      top: 0;
      left: 0;
      flex-direction: row;
      background-color: var(--main-dark-blue);
      padding: 0px 10px;
    }
    .AppliMenuToggle{
      display: none;
    }
    .MenuProfilAccess{
      display: none;
    }
    .MenuProfilCase {
      width: 5vh;
      height: 5vh;
      padding: 0px;
      border-radius: 50px;
    }
    .MenuProfilPicture {
      width: 4.5vh;
      height: 4.5vh;
      margin: 0px;
    }
    .deconnexion {
      padding: 0px;
      margin: 0px;
      width: 5vh;
      height: 5vh;
    }
    .menu_light{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      
      width: 100%;
      height: 0vh;
      background-color: var(--main-dark-blue);
      color: #FFF;
      font-size: 1.5rem;
      cursor: pointer;
      pointer-events: none;
      opacity: 0;
      transition: 0.3s;
      position: fixed;
      top: 0;
      left: 0;
    }
    .menu_light_active{
      background-color: var(--main-light-grey);
      color: var(--main-dark-blue);
      border-radius: 5px;
      opacity: 1;
      pointer-events: all;
      height: 100vh;
      transition: 0.3s;
    }
    .menu_light_line{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      height: 70%;
      margin-top: 15%;
      width: 100%;
    }
    .menu_light_button{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 10px 0px;
    }
    .menu_light .connect{
      width: 70%;
      text-align: center;
      margin: 10px 0px;
    }
  }
  