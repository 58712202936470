.Client{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    max-width: 1080px;
}
.ClientSearch{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width : 100%;
    color: var(--main-grey);
    border: solid 1px var(--main-light-grey);
    font-family: var(--main-font);
    border-radius: 8px;
    padding: 5px;
    margin-top: 5vh;
    margin-bottom: 2vh;
}
.ClientSearch input{
    width: 100%!important;
    font-size: 0.8rem;
    font-family: var(--main-font);
    color: var(--main-dark-blue)!important;
    background-color: #FFF!important;
    border: none;
    margin: 0px!important;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
}
.clientSearchZone{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: var(--main-dark-blue);
    border: solid 1px var(--main-dark-blue);
    top: 9vh;
    width: 36%;
    height: 0;
    opacity: 0;
    pointer-events: none;
}
.clientSearchZoneActive{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #FFF;
    border: solid 1px var(--main-light-grey);
    width: 36%;
    height: 25vh;
    top: 9vh;
    border-radius: 0px 0px 5px 5px;
    pointer-events: all;
    opacity: 1;
    transition: 0.3s;
}
.clientSearchLine{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 5px;
    border-bottom: solid 1px var(--main-light-grey);
    color: var(--main-dark-blue);
    font-size: 0.8rem;
}
.clientSearchLineName{
    color: var(--main-dark-blue);
    width: 35%;
}
.clientSearchLineImage{
    width: 3vh;
    height: 3vh;
    border: solid 1px var(--main-grey);
}
.ClientProfile{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    height: 80vh;
}
.ClientInfos{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 24%;
    height: 100%;
    overflow-y: auto;
    /* rendre la scrollbar invisible */
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar{
        display: none;
    }
}
.ClientInfosPerso{
    width: 98%;
    padding: 5px;
    font-size: 0.9rem;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    background-color: #FFF;
    font-family: 'Popins', sans-serif;
    color: var(--main-dark-blue);
}
.ClientInfosPersoLeft{
    width: 100%;
    font-weight: 500;
    padding: 10px;
}
.ClientInfosPersoLeft input{
    width: 95%;
    background-color: transparent;
    border: solid 1px var(--main-light-grey);
    color: var(--main-dark-blue);
    border-radius: 4px;
    padding: 3px 5px;
    margin-bottom: 5px;
}
.ClientInfosPersoRight{
    width: 100%;
    background-color: var(--main-light-grey);
    border: solid 1px var(--main-middle-grey);
    color: var(--main-grey);
    border-radius : 5px;
    padding: 5px;
    text-align: center;
}
.ClientInfoPhone{
    width: 98%;
    padding: 3px;
    font-size: 0.8rem;
    text-align: center;
    letter-spacing: 1px;
    border-radius: 5px;
    background-color: var(--main-dark-blue);
    color: #FFF;
    margin-top: 10px;
}
.ClientInfoMail{
    width: 98%;
    padding: 3px;
    font-size: 0.8rem;
    text-align: center;
    letter-spacing: 1px;
    border-radius: 5px;
    background-color: var(--main-dark-blue);
    color: #FFF;
    margin: 10px 0px;
}
.ClientInfoPhone input{
    background-color: transparent;
    color: #DDD;
    width: 100%;
    border-radius: 3px;
    border: solid 1px var(--main-grey);
    padding: 0px 5px;
}
.ClientInfoMail input{
    background-color: transparent;
    color: #DDD;
    width: 100%;
    border-radius: 3px;
    border: solid 1px var(--main-dark-blue);
    padding: 0px 5px;
}
.ClientInfosBassins{
    border: solid 1px var(--main-light-grey);
    border-radius: 5px;
    width: 100%;
    padding: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    overflow-y: unset;
    /* rendre la scrollbar invisible */
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar{
        display: none;
    }
}
.ClientInfosBassin{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 98%;
    border-radius: 6px;
    border: solid 2px var(--main-dark-blue);
    color: var(--main-dark-blue);
    font-family: "Poppins", sans-serif;
    margin: 5px 0px;
}
.ClientInfosBassinLeft{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}
.ClientBassinName{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
    color: #FFF;
    text-transform: uppercase;
    padding : 5px;
    background-color: var(--main-dark-blue);
    border-radius: 5px 5px 0px 0px;
}
.ClientBassinDescription{
    font-size: 0.8rem;
    width: 96%;
    font-weight: 500;
    margin: 2vh 0px;
}
.ClientBassinCreation{
    font-size: 0.8rem;
    font-weight: 300;
}
.ClientBassinStatus{
    border-radius: 3px;
    background-color: rgb(0, 208, 135);
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    font-weight: 300;
    padding: 0px 10px;
}
.ClientInfosBassinRight{
    padding: 5px;
    width: 100%;
    aspect-ratio: 1 / 1;
    display: flex;
    background-color: var(--main-light-grey);
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.ClientInfosBassinType{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 0.8rem;
    text-align: center;
    font-weight: 500;
    color: var(--main-light-grey);
    text-transform: uppercase;
}
.ClientInfosAlert{
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.ClientAlert{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--main-dark-blue);
    width: 98%;
    color: #FFF;
    font-size: 0.8rem;
    border-radius: 5px;
    border: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    overflow: hidden;
}
.clientAlertValid{
    background-color: var(--main-green);
}
.AlertIcon{
    border-radius: 5px 0px 0px 5px;
    border: solid 2px rgb(148, 60, 60);
    background-color:rgb(254, 107, 107);
    min-width: 30px;
    height: calc(12px + 1rem);
    display: flex;
    align-items: center;
    justify-content: center;
}
.clientAlertValid .AlertText{
    width: 90%;
}
.AlertText{
    padding: 5px 5px;
    max-width: calc(100% - 30px);
    text-align: left;
}
.AlertArchive{
    height: 1rem;
    aspect-ratio: 1 / 1;
    background-color: var(--main-red);
    border-radius: 3px;
    margin-right: -1rem;
    opacity: 0;
    transition: 0.1s;
    color: #FFF;
}
.ClientAlert:hover .AlertArchive{
    margin-right: 5px;
    opacity: 1;
}
.ClientInfosAlertCreator{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.ClientInfosAlertCreatorButton{
    width: 98%;
    padding: 3px 0px;
    background-color: var(--main-dark-blue);
    color: #FFF;
    text-align: center;
    border-radius: 4px;
    border: solid 2px var(--main-dark-blue);
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 0.3s;
}
.ClientInfosAlertCreatorButton:hover{
    background-color: var(--main-dark-blue);
    transition: 0.3s;
}
.ClientInfosAlertCreatorButtonUnactive{
    width: 98%;
    padding: 3px 0px;
    background-color: var(--main-dark-blue);
    color: #FFF;
    text-align: center;
    border-radius: 4px;
    border: solid 2px var(--main-dark-blue);
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    display: none;
    transition: 0.3s;
}
.ClientInfosAlertCreatorForm{
    width: 98%;
    border: solid 1px #CCC;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    color: var(--main-dark-blue);
    font-family: var(--main-font);
    font-size: 0.8rem;
}
.ClientInfosAlertCreatorFormUnactive{
    display: none;
}
.ClientInfosAlertCreatorForm select{
    width: 80%;
    background-color: transparent;
    border: solid 1px #CCC;
    color: var(--main-dark-blue);
    border-radius: 3px;
    padding: 5px;
}
.ClientInfosAlertCreatorForm input{
    width : 98%;
    margin: 10px 0px;
    border-radius: 3px;
    border: solid 1px #CCC;
    background-color: transparent;
}
.ClientInfosAlertCreatorFormValid{
    padding: 5px;
    width: 40%;
    border-radius: 4px;
    color: #FFF;
    background-color: var(--main-dark-blue);
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    cursor : pointer;
    transition: 0.4s;
}
.ClientInfosAlertCreatorFormValid:hover{
    background-color: rgb(0, 208, 135);
    transition: 0.4s;
}
.ClientInfosAlertCreatorFormCancel{
    cursor : pointer;
    padding: 5px;
    letter-spacing: 1px;
    color: var(--main-dark-blue);
    transition: 0.3s;
}
.ClientInfosAlertCreatorFormCancel:hover{
    color: var(--main-dark-blue);
    transition: 0.3s;
}
.clientInfoModifButton{
    width: 98%;
    margin-top: 2%;
    background-color: var(--blue);
    color: #FFF;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    font-size: 0.8rem;
    padding: 5px 0px;
    font-style: italic;
    transition: 0.3s;
}
.clientInfoModifButton:hover{
    background-color: var(--main-blue);
    transition: 0.3s;
}
.ClientShop{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 24%;
    height: 100%;
    border-radius: 6px;
    font-family: var(--main-font);
    background-color: #FFF;
    color: var(--main-dark-blue);
    font-size: 0.8rem;
    font-weight: 500;
    overflow-y: auto;
    /* rendre la scrollbar invisible */
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar{
        display: none;
    }
}
.ClientShopTitle{
    width: 100%;
    padding: 5px;
    font-size: 1rem;
    font-family: var(--main-font);
    color: #FFF;
    background-color: var(--main-dark-grey);
    text-align: center;
    border-radius: 5px 5px 0px 0px;
    position: sticky;
    top: 0;
}

.ClientTechnic{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 24%;
    height: 100%;
    font-family: var(--main-font);
    font-weight: 500;
    color: var(--main-dark-blue);
    font-size: 0.8rem;
    background-color: #FFF;
    border-radius: 6px;
    overflow-y: auto; 
}
.ClientTechnicTitle{
    width: 100%;
    padding: 5px;
    font-size: 1rem;
    font-family: var(--main-font);
    color: #FFF;
    background-color: var(--main-dark-grey);
    text-align: center;
    border-radius: 5px 5px 0px 0px;
    position: sticky;
    top: 0;
}
.ClientShopFactureCard{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 96%;
    background-color: var(--main-dark-blue);
    border: solid 2px var(--main-dark-blue);
    padding: 0px;
    color: #EEE;
    font-weight: 300;
    font-size: 0.8rem;
    border-radius: 4px;
    margin-top: 10px;
}
.ClientShopFactureName{
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between; 
}
.ClientShopFactureStatusBar{
    margin: 8px 0px;
    padding: 0px 5px;
    width: 100%;
    height: 3px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: rgb(0, 208, 135);
}
.ClientShopFactureStatus{
    border-radius: 50px;
    padding: 1px 8px;
    font-size: 0.5rem;
    color: #FFF;
    font-size: 300;
    background-color: rgb(0, 208, 135);
}
.ClientShopFactureRecap{
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 300;
    font-size: 0.6rem;
    width: 100%;
}
.ClientShopFactureRecap div{
    width: 50%;
}
.ClientShopFactureRestant{
    width: 100%;
    text-align: right;
    font-weight: 300;
    font-size: 0.6rem;
}
.ClientShopFactureLink{
    margin-top: 10px;
    margin-bottom: 5px;
    background-color: var(--main-dark-blue);
    color: #EEE;
    border-radius: 50px;
    padding: 2px 0px;
    text-align: center;
    cursor: pointer;
    width: 98%;
}
.ClientShopFactureLink:hover{
    background-color: var(--main-dark-blue);
    transition: 0.3s;
}
.ClientShopTechnicCard{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 98%;
    background-color: var(--main-dark-blue);
    border: solid 2px var(--main-dark-blue);
    padding: 0px;
    color: #EEE;
    font-weight: 300;
    font-size: 0.8rem;
    border-radius: 4px;
    font-family: var(--main-font);
    margin-top: 10px;
}
.ClientShopTechnicCardBasicInfo{
    border-bottom: solid 1px var(--main-light-grey);
    padding: 5px;
    width: 100%;
}
.ClientShopTechnicCardInfo{
    width: 100%;
    padding: 5px;
    font-weight: 400;
}
.ClientShopTechnicCompletion{
    color: var(--main-light-grey);
    font-style: italic;
    font-size: 0.75rem;
}
.ClientStat{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 24%;
    height: 100%;
    border-radius: 6px;
}
.ClientList{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    border-radius: 6px;
}
.ClientListTitle{
    width: 100%;
    padding: 5px;
    font-size: 1rem;
    font-family: var(--main-font);
    color: #FFF;
    background-color: var(--main-dark-grey);
    text-align: center;
    border-radius: 5px 5px 0px 0px;
    position: sticky;
    top: 0;
}
.ClientListContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: #FFF;
    border-radius: 6px;
    overflow-y: auto;
}
.ClientListLine{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
    border-bottom: solid 1px var(--main-light-grey);
    font-size: 0.8rem;
    font-family: var(--main-font);
    color: var(--main-dark-blue);
    cursor: pointer;
}
.ClientListLine:hover{
    background-color: var(--main-light-grey);
}
.ClientListSurname{
    color: var(--main-dark-blue);
    text-transform: uppercase;
}
.ClientListLineName{
    color: var(--main-dark-blue);
}
.ClientListLineImage{
    width: 3vh;
    height: 3vh;
    border: solid 1px var(--main-grey);
}

@media screen and (max-width: 768px) {
    .Client{
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding: 10vh 0px;
    }
    .ClientSearch{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width : 95%;
        background-color: var(--main-light-grey);
        color: var(--main-dark-blue);
        font-family: var(--main-font);
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 2vh;
    }
    .ClientSearch input{
        width: 80%;
        font-size: 0.8rem;
        font-family: var(--main-font);
        color: var(--main-dark-blue)!important;
        border: none;
        background-color: transparent;
    }
    .clientSearchZone{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        background-color: var(--main-dark-blue);
        border: solid 1px var(--main-dark-blue);
        top: 9vh;
        width: 80%;
        height: 0;
        opacity: 0;
        pointer-events: none;
    }
    .clientSearchZoneActive{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        background-color: var(--main-dark-blue);
        border: solid 1px var(--main-dark-blue);
        width: 80%;
        height: 25vh;
        top: 9vh;
        border-radius: 4px;
        pointer-events: all;
        opacity: 1;
        transition: 0.3s;
    }
    .clientSearchLine{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 5px;
        border-bottom: solid 1px var(--main-light-grey);
        font-size: 0.8rem;
    }
    .clientSearchLineName{
        color: #FFF;
    }
    .clientSearchLineImage{
        width: 3vh;
        height: 3vh;
        border: solid 1px var(--main-grey);
    }
    .ClientProfile{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        height: 95%;
    }
    .ClientInfos{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
    .ClientInfosPerso{
        width: 98%;
        padding: 5px;
        font-size: 0.8rem;
        border-radius: 5px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border: solid 1px var(--main-light-grey);
        font-family: 'Popins', sans-serif;
        color: var(--main-dark-blue);
    }
    .ClientInfosPersoLeft{
        width: 49%;
        height: 100%;
        border-right: solid 1px var(--main-light-grey);
        font-weight: 600;
    }
    .ClientInfosPersoLeft input{
        width: 95%;
        background-color: transparent;
        border: solid 1px var(--main-light-grey);
        color: var(--main-dark-blue);
        border-radius: 4px;
        padding: 3px 5px;
        margin-bottom: 5px;
    }
    .ClientInfosPersoRight{
        width: 49%;
        height: 100%;
    }
    .ClientInfoPhone{
        width: 98%;
        padding: 3px;
        font-size: 0.8rem;
        text-align: center;
        letter-spacing: 1px;
        border-radius: 5px;
        background-color: var(--main-dark-blue);
        color: #FFF;
        margin-top: 10px;
    }
    .ClientInfoMail{
        width: 98%;
        padding: 3px;
        font-size: 0.8rem;
        text-align: center;
        letter-spacing: 1px;
        border-radius: 5px;
        background-color: var(--main-dark-blue);
        color: #FFF;
        margin: 10px 0px;
    }
    .ClientInfoPhone input{
        background-color: transparent;
        color: #DDD;
        width: 100%;
        border-radius: 3px;
        border: solid 1px var(--main-grey);
        padding: 0px 5px;
    }
    .ClientInfoMail input{
        background-color: transparent;
        color: #DDD;
        width: 100%;
        border-radius: 3px;
        border: solid 1px var(--main-dark-blue);
        padding: 0px 5px;
    }
    .ClientInfosBassins{
        border: solid 1px var(--main-light-grey);
        border-radius: 5px;
        width: 100%;
        padding: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        max-height: 70%;
    }
    .ClientInfosBassin{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 98%;
        border-radius: 4px;
        background-color: var(--main-dark-blue);
        border: solid 2px var(--main-dark-blue);
        color: #FFF;
        font-family: var(--main-font);
    }
    .ClientInfosBassinLeft{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px;
        justify-content: space-between;
        width: 60%;
        height: 100%;
        border-right: dashed 1px var(--main-light-grey);
    }
    .ClientBassinName{
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        font-size: 0.8rem;
        font-weight: 600;
        color: var(--main-midlle-grey);
        text-transform: uppercase;
    }
    .ClientBassinDescription{
        font-size: 0.8rem;
        font-weight: 500;
        margin: 2vh 0px;
    }
    .ClientBassinCreation{
        font-size: 0.6rem;
        font-weight: 300;
    }
    .ClientBassinStatus{
        border-radius: 50px;
        background-color: rgb(0, 208, 135);
        color: #FFF;
        height: 0.9rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.6rem;
        font-weight: 300;
        padding: 0px 5px;
    }
    .ClientInfosBassinRight{
        padding: 5px;
        height: 100%;
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .ClientInfosBassinType{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 0.8rem;
        text-align: center;
        font-weight: 500;
        color: var(--main-light-grey);
        text-transform: uppercase;
    }
    .ClientInfosAlert{
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .ClientAlert{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--main-dark-blue);
        width: 98%;
        color: #FFF;
        font-size: 0.8rem;
        border-radius: 5px;
        border: 5px;
        margin-bottom: 10px;
        cursor: pointer;
        overflow: hidden;
    }
    .AlertIcon{
        border-radius: 5px 0px 0px 5px;
        border: solid 2px rgb(148, 60, 60);
        background-color:rgb(254, 107, 107);
        min-width: 30px;
        height: calc(12px + 1rem);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .AlertText{
        padding: 5px 5px;
        max-width: calc(100% - 30px);
        text-align: left;
    }
    .AlertArchive{
        height: 1rem;
        aspect-ratio: 1 / 1;
        background-color: var(--main-red);
        border-radius: 3px;
        margin-right: -1rem;
        opacity: 0;
        transition: 0.1s;
        color: #FFF;
    }
    .ClientAlert:hover .AlertArchive{
        margin-right: 5px;
        opacity: 1;
    }
    .ClientInfosAlertCreator{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
    }
    .ClientInfosAlertCreatorButton{
        width: 98%;
        padding: 3px 0px;
        background-color: var(--main-dark-blue);
        color: #FFF;
        text-align: center;
        border-radius: 4px;
        border: solid 2px var(--main-dark-blue);
        font-size: 0.8rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;
        transition: 0.3s;
    }
    .ClientInfosAlertCreatorButton:hover{
        background-color: var(--main-dark-blue);
        transition: 0.3s;
    }
    .ClientInfosAlertCreatorButtonUnactive{
        width: 98%;
        padding: 3px 0px;
        background-color: var(--main-dark-blue);
        color: #FFF;
        text-align: center;
        border-radius: 4px;
        border: solid 2px var(--main-dark-blue);
        font-size: 0.8rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;
        display: none;
        transition: 0.3s;
    }
    .ClientInfosAlertCreatorForm{
        width: 98%;
        border: solid 1px #CCC;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        color: var(--main-dark-blue);
        font-family: var(--main-font);
        font-size: 0.8rem;
    }
    .ClientInfosAlertCreatorFormUnactive{
        display: none;
    }
    .ClientInfosAlertCreatorForm select{
        width: 80%;
        background-color: transparent;
        border: solid 1px #CCC;
        color: var(--main-dark-blue);
        border-radius: 3px;
        padding: 5px;
    }
    .ClientInfosAlertCreatorForm input{
        width : 98%;
        margin: 10px 0px;
        border-radius: 3px;
        border: solid 1px #CCC;
        background-color: transparent;
    }
    .ClientInfosAlertCreatorFormValid{
        padding: 5px;
        width: 40%;
        border-radius: 4px;
        color: #FFF;
        background-color: var(--main-dark-blue);
        text-transform: uppercase;
        letter-spacing: 1px;
        text-align: center;
        cursor : pointer;
        transition: 0.4s;
    }
    .ClientInfosAlertCreatorFormValid:hover{
        background-color: rgb(0, 208, 135);
        transition: 0.4s;
    }
    .ClientInfosAlertCreatorFormCancel{
        cursor : pointer;
        padding: 5px;
        letter-spacing: 1px;
        color: var(--main-dark-blue);
        transition: 0.3s;
    }
    .ClientInfosAlertCreatorFormCancel:hover{
        color: var(--main-dark-blue);
        transition: 0.3s;
    }
    .clientInfoModifButton{
        width: 98%;
        margin-top: 2%;
        border: solid 1px #FFF;
        background-color: #FFF;
        border-radius: 5px;
        color: var(--main-dark-blue);
        cursor: pointer;
        text-align: center;
        font-size: 0.8rem;
        padding: 5px 0px;
        font-style: italic;
        transition: 0.3s;
    }
    .clientInfoModifButton:hover{
        border: solid 1px #DDD;
        background-color: #EEE;
        transition: 0.3s;
    }
    .ClientShop{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        border-radius: 6px;
    }
    .ClientTechnic{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        border-radius: 6px;
    }
    .ClientStat{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        border-radius: 6px;
    }
}

@media screen and (max-width: 480px) {
    .Client{
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding: 10vh 0px;
    }
    .ClientSearch{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width : 95%;
        background-color: var(--main-light-grey);
        color: var(--main-dark-blue);
        font-family: var(--main-font);
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 2vh;
    }
    .ClientSearch input{
        width: 80%;
        font-size: 0.8rem;
        font-family: var(--main-font);
        color: var(--main-dark-blue)!important;
        border: none;
        background-color: transparent;
    }
    .clientSearchZone{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        background-color: var(--main-dark-blue);
        border: solid 1px var(--main-dark-blue);
        top: 9vh;
        width: 90%;
        height: 0;
        opacity: 0;
        pointer-events: none;
    }
    .clientSearchZoneActive{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        background-color: var(--main-dark-blue);
        border: solid 1px var(--main-dark-blue);
        width: 90%;
        height: 25vh;
        top: 9vh;
        border-radius: 4px;
        pointer-events: all;
        opacity: 1;
        transition: 0.3s;
    }
    .clientSearchLine{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 5px;
        border-bottom: solid 1px var(--main-light-grey);
        font-size: 0.8rem;
    }
    .clientSearchLineName{
        color: #FFF;
    }
    .clientSearchLineImage{
        width: 3vh;
        height: 3vh;
        border: solid 1px var(--main-grey);
    }
    .ClientProfile{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        height: 95%;
    }
    .ClientInfos{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 95%;
        height: 100%;
    }
    .ClientInfosPerso{
        width: 98%;
        padding: 5px;
        font-size: 0.8rem;
        border-radius: 5px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border: solid 1px var(--main-light-grey);
        font-family: 'Popins', sans-serif;
        color: var(--main-dark-blue);
    }
    .ClientInfosPersoLeft{
        width: 49%;
        height: 100%;
        border-right: solid 1px var(--main-light-grey);
        font-weight: 600;
    }
    .ClientInfosPersoLeft input{
        width: 95%;
        background-color: transparent;
        border: solid 1px var(--main-light-grey);
        color: var(--main-dark-blue);
        border-radius: 4px;
        padding: 3px 5px;
        margin-bottom: 5px;
    }
    .ClientInfosPersoRight{
        width: 49%;
        height: 100%;
    }
    .ClientInfoPhone{
        width: 98%;
        padding: 3px;
        font-size: 0.8rem;
        text-align: center;
        letter-spacing: 1px;
        border-radius: 5px;
        background-color: var(--main-dark-blue);
        color: #FFF;
        margin-top: 10px;
    }
    .ClientInfoMail{
        width: 98%;
        padding: 3px;
        font-size: 0.8rem;
        text-align: center;
        letter-spacing: 1px;
        border-radius: 5px;
        background-color: var(--main-dark-blue);
        color: #FFF;
        margin: 10px 0px;
    }
    .ClientInfoPhone input{
        background-color: transparent;
        color: #DDD;
        width: 100%;
        border-radius: 3px;
        border: solid 1px var(--main-grey);
        padding: 0px 5px;
    }
    .ClientInfoMail input{
        background-color: transparent;
        color: #DDD;
        width: 100%;
        border-radius: 3px;
        border: solid 1px var(--main-dark-blue);
        padding: 0px 5px;
    }
    .ClientInfosBassins{
        border: solid 1px var(--main-light-grey);
        border-radius: 5px;
        width: 100%;
        padding: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        max-height: 70%;
        
    }
    .ClientInfosBassin{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 98%;
        border-radius: 4px;
        background-color: var(--main-dark-blue);
        border: solid 2px var(--main-dark-blue);
        color: #FFF;
        font-family: var(--main-font);
    }
    .ClientInfosBassinLeft{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px;
        justify-content: space-between;
        width: 60%;
        height: 100%;
        border-right: dashed 1px var(--main-light-grey);
    }
    .ClientBassinDescription{
        font-size: 0.8rem;
        font-weight: 500;
        margin: 2vh 0px;
    }
    .ClientBassinCreation{
        font-size: 0.6rem;
        font-weight: 300;
    }
    .ClientBassinStatus{
        border-radius: 50px;
        background-color: rgb(0, 208, 135);
        color: #FFF;
        height: 0.9rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.3rem;
        font-weight: 300;
        padding: 0px 5px;
    }
    .ClientInfosBassinRight{
        padding: 5px;
        height: 100%;
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .ClientInfosBassinType{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 0.8rem;
        text-align: center;
        font-weight: 500;
        color: var(--main-light-grey);
        text-transform: uppercase;
    }
    .ClientInfosAlert{
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .ClientAlert{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--main-dark-blue);
        width: 98%;
        color: #FFF;
        font-size: 0.8rem;
        border-radius: 5px;
        border: 5px;
        margin-bottom: 10px;
        cursor: pointer;
        overflow: hidden;
    }
    .AlertIcon{
        border-radius: 5px 0px 0px 5px;
        border: solid 2px rgb(148, 60, 60);
        background-color:rgb(254, 107, 107);
        min-width: 30px;
        height: calc(12px + 1rem);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .AlertText{
        padding: 5px 5px;
        max-width: calc(100% - 30px);
        text-align: left;
    }
    .AlertArchive{
        height: 1rem;
        aspect-ratio: 1 / 1;
        background-color: var(--main-red);
        border-radius: 3px;
        margin-right: -1rem;
        opacity: 0;
        transition: 0.1s;
        color: #FFF;
    }
    .ClientAlert:hover .AlertArchive{
        margin-right: 5px;
        opacity: 1;
    }
    .ClientInfosAlertCreator{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
    }
    .ClientInfosAlertCreatorButton{
        width: 98%;
        padding: 3px 0px;
        background-color: var(--main-dark-blue);
        color: #FFF;
        text-align: center;
        border-radius: 4px;
        border: solid 2px var(--main-dark-blue);
        font-size: 0.8rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;
        transition: 0.3s;
    }
    .ClientInfosAlertCreatorButton:hover{
        background-color: var(--main-dark-blue);
        transition: 0.3s;
    }
    .ClientInfosAlertCreatorButtonUnactive{
        width: 98%;
        padding: 3px 0px;
        background-color: var(--main-dark-blue);
        color: #FFF;
        text-align: center;
        border-radius: 4px;
        border: solid 2px var(--main-dark-blue);
        font-size: 0.8rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;
        display: none;
        transition: 0.3s;
    }
    .ClientInfosAlertCreatorForm{
        width: 98%;
        border: solid 1px #CCC;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        color: var(--main-dark-blue);
        font-family: var(--main-font);
        font-size: 0.8rem;
    }
    .ClientInfosAlertCreatorFormUnactive{
        display: none;
    }
    .ClientInfosAlertCreatorForm select{
        width: 80%;
        background-color: transparent;
        border: solid 1px #CCC;
        color: var(--main-dark-blue);
        border-radius: 3px;
        padding: 5px;
    }
    .ClientInfosAlertCreatorForm input{
        width : 98%;
        margin: 10px 0px;
        border-radius: 3px;
        border: solid 1px #CCC;
        background-color: transparent;
    }
    .ClientInfosAlertCreatorFormValid{
        padding: 5px;
        width: 40%;
        border-radius: 4px;
        color: #FFF;
        background-color: var(--main-dark-blue);
        text-transform: uppercase;
        letter-spacing: 1px;
        text-align: center;
        cursor : pointer;
        transition: 0.4s;
    }
    .ClientInfosAlertCreatorFormValid:hover{
        background-color: rgb(0, 208, 135);
        transition: 0.4s;
    }
    .ClientInfosAlertCreatorFormCancel{
        cursor : pointer;
        padding: 5px;
        letter-spacing: 1px;
        color: var(--main-dark-blue);
        transition: 0.3s;
    }
    .ClientInfosAlertCreatorFormCancel:hover{
        color: var(--main-dark-blue);
        transition: 0.3s;
    }
    .clientInfoModifButton{
        width: 98%;
        margin-top: 2%;
        border: solid 1px #FFF;
        background-color: #FFF;
        border-radius: 5px;
        color: var(--main-dark-blue);
        cursor: pointer;
        text-align: center;
        font-size: 0.8rem;
        padding: 5px 0px;
        font-style: italic;
        transition: 0.3s;
    }
    .clientInfoModifButton:hover{
        border: solid 1px #DDD;
        background-color: #EEE;
        transition: 0.3s;
    }
    .ClientShop{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        border-radius: 6px;
    }
    .ClientTechnic{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        border-radius: 6px;
    }
    .ClientStat {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        border-radius: 6px;
    }
}