.Blog{
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: var(--main-light-grey);
    overflow-y: auto;
    /* On masque la barre de défilement */
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
}
.Blog-block{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0px auto;
    width: 100%;
    max-width: 980px;
    background-color: #FFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: 'Poppins', sans-serif;
}
.Blog-header{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-image: url('https://images.unsplash.com/photo-1596984358985-bf4b2faba9e0?q=80&w=3024&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    background-position: center;
    background-size: cover;
}
.Blog-header-mask{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10% 0px;
}
.Blog-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    padding: 20px;
    cursor: pointer;
}
.Blog-logo img{
    width: 50px;
    height: 50px;
}
.Blog-logo span{
    font-size: 1.5rem;
    font-weight: 500;
    margin-left: 10px;
    color: #FFF;
    font-family: 'Poppins', sans-serif;
}
.Blog-title{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #FFF;
    width: 100%;
    padding: 20px;
    border-radius: 5px;
}
.Blog-title h1{
    font-size: 2rem;
    font-weight: 500;
    margin: 10px 0;
}
.Blog-title p{
    font-size: 1rem;
    font-weight: 500;
    margin: 10px 0;
}
.Blog-last-articles{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
}
.Blog-last-articles h2{
    font-size: 1.5rem;
    font-weight: 500;
    width: 100%;
    color: var(--main-grey);
    margin-bottom: 20px;
    text-align: left;
}
.Blog-articles{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
}
.Blog-article{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    background-color: #FFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}
.Blog-article-image{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
}
.Blog-article h3{
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--main-grey);
    margin: 10px 0;
    text-align: left;
}
.Blog-article p{
    font-size: 1rem;
    font-weight: 500;
    color: var(--main-grey);
    margin: 10px 0;
    text-align: left;
}
.Blog-all-articles{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    border-top: solid 1px var(--main-middle-grey);
}
.Blog-big-article{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #FFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin: 10px 0px;
}
.Blog-big-article-image{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 400px;
    background-color: #555;
    overflow: hidden;
    border-radius: 5px 0 0 5px;
}
.Blog-big-article-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 40%;
    padding: 20px;
}
.Blog-big-article h3{
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--main-grey);
    margin: 10px 0;
    text-align: left;
}
.Blog-big-article p{
    font-size: 1rem;
    font-weight: 500;
    color: var(--main-grey);
    margin: 10px 0;
    text-align: left;
}
.Blog-big-article-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    border-top: solid 1px var(--main-middle-grey);
}

.Blog-big-article-date{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.8rem;
    background-color: var(--main-light-grey);
    color: var(--main-grey);
}
.Blog-big-article-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    background-color: var(--main-blue);
    color: #FFF;
    cursor: pointer;
    font-size: 0.8rem;
    transition: 0.3s;
}
.Blog-big-article-button:hover{
    background-color: var(--main-middle-blue);
    transition: 0.3s;
}


.Blog-Pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    border-top: solid 1px var(--main-middle-grey);
}
.Blog-Pagination-previous{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: var(--main-blue);
    aspect-ratio: 1/1;
    height: 30px;
    color: #FFF;
    cursor: pointer;
    transition: 0.3s;
}
.Blog-Pagination-previous:hover{
    background-color: var(--main-middle-blue);
    transition: 0.3s;
}
.Blog-Pagination-page{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    color: var(--main-grey);
    cursor: pointer;
    transition: 0.3s;
}
.Blog-Pagination-page-number{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    aspect-ratio: 1/1;
    height: 30px;
    margin: 0 5px;
    background-color: var(--main-blue);
    color: #FFF;
    cursor: pointer;
    transition: 0.3s;
}
.Blog-Pagination-page-number-active{
    background-color: var(--main-middle-blue);
}
.Blog-Pagination-page-more{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    aspect-ratio: 1/1;
    height: 30px;
    margin: 0 5px;
    background-color: var(--main-light-grey);
    color: var(--main-grey);
    cursor: pointer;
    transition: 0.3s;
}
.Blog-Pagination-page-number:hover{
    background-color: var(--main-middle-blue);
    transition: 0.3s;
}
.Blog-Pagination-next{
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1/1;
    height: 30px;
    border-radius: 5px;
    background-color: var(--main-blue);
    color: #FFF;
    cursor: pointer;
    transition: 0.3s;
}
.Blog-Pagination-next:hover{
    background-color: var(--main-middle-blue);
    transition: 0.3s;
}