.ProfilUnactive{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
}
.Profil{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 1;
    pointer-events: all;
    transition: 0.3s;
    z-index: 10000;
}
.profilZone{
    width : 60%;
    height: 90%;
    background: var(--main-gradient);
    box-shadow: 0px 0px 10px 0px #111;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    font-family: var(--main-font);
    overflow: hidden;
}
.ProfilUnactive .profilZone{
    pointer-events: none;
}
.ProfilName{
    width: 100%;
    text-align: left;
    color: var(--main-middle-grey);
    letter-spacing: 2px;
    text-transform: uppercase;
    display: flex;
    align-items: center; 
    font-size: 1rem;
    font-weight: 500;
    padding: 10px 15px;
    border-radius: 5px 5px 0px 0px;
}
.ProfilName svg{
    margin-right: 10px;
    cursor: pointer;
}
.ProfilBox{
    width: 100%;
    height: 100%;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.ProfilBox input, select{
    color: #444;
    border: solid 1px #444;
    border-radius: 3px;
    font-size: 0.8rem;
    background-color: transparent;
    margin-top: 10px;
}
.ProfilBox label{
    font-size: 0.8rem;
    margin-top: 10px;
}
.ProfilConnecInfo{
    width: 98%;
    align-items: center;
    justify-content: space-between;
    border: solid 1px #FFF;
    background-color: #FFF;
    border-radius: 5px;
    padding: 10px;
}
.ProfilConnecMail{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.ProfilConnecInfo label{
    width: 40%;
}
.ProfilConnecInfo input{
    width: 40%;
}
.ProfilConnecPassChange{
    width : 98%;
    border-radius: 5px;
    background-color: var(--main-grey);
    margin-top: 10px;
    font-size: 0.8rem;
    color : #FFF;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}
.ProfilConnecPassChange label{
    width : 40%;
    margin-top: 10px;
}
.ProfilConnecPassChange input{
    width : 40%;
    margin-top: 10px;
}
.ProfilConnecPassChangeValid{
    width : 25%;
    color : var(--main-dark-blue);
    margin: 10px 0px;
    cursor : pointer;
}
.ProfilConnecPassChangeUnvalid{
    width : 25%;
    color : var(--main-dark-blue);
    margin: 10px 0px;
    opacity: 0.4;
    cursor : pointer;
}
.new_pass_test_bar{
    width: 100%;
    padding: 5px;
    margin: 5px 0px;
    font-size: 0.8rem;
    background-color: var(--main-grey);
    text-align: center;
}
.re_new_pass_matching_bar{
    width: 100%;
    padding: 5px;
    margin: 5px 0px;
    font-size: 0.8rem;
    background-color: var(--main-grey);
    text-align: center;
}
.new_pass_test_bar_inactive{
    display: none;
}
.re_new_pass_matching_bar_inactive{
    display: none;
}
.valid_line{
    width: 100%;
    margin: 5px 0px;
    height: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.valid_line_dot{
    display: block;
    background-color: #FFF;
    width: 19%;
    height: 100%;
}
.valid_line_dot_1{
    background-color: var(--main-red);
}
.valid_line_dot_2{
    background-color: var(--main-orange);
}
.valid_line_dot_3{
    background-color: var(--main-yellow);
}
.valid_line_dot_4{
    background-color: var(--main-yellow);
}
.valid_line_dot_5{
    background-color: var(--main-green);
}
.ProfilBasicInfo{
    display: flex;
    width: 98%;
    align-items: center;
    justify-content: space-between;
    border: solid 1px #FFF;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
    background-color: #FFF;

}
.ProfilBasicInfoText{
    width : 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.ProfilBasicInfoImage{
    width: 29%;
    max-width: 20vh;
    aspect-ratio: 1 / 1;
    background-color: var(--main-light-grey);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: var(--main-middle-grey) 0px 0px 5px inset;
    background-size: cover!important;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: 0.3s;
}
.ProfilBasicInfoImage:hover .ProfilBasicInfoImageChange{
    opacity : 1;
    pointer-events: all;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s;
}
.ProfilBasicInfoImageChange{
    width: 90%;
    background-color: #DDD;
    color: var(--main-grey);
    text-align: center;
    opacity : 0;
    pointer-events: none;
    transition: 0.3s;
    border-radius: 3px;
    padding: 5px 0px;
    margin: 10px;
    font-size: 0.7rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.3s;
}
.ProfilBasicInfoImageChange:hover{
    background-color: #FFF;
    color: var(--main-grey);
    transition: 0.3s;
}
.ProfilBasicInfoText label{
    width: 48%;

}
.profilZone input, select{
    width: 48%;
    background-color: var(--main-light-grey);
    border: solid 1px var(--main-light-grey);
    border-radius: 3px;
    font-size: 0.8rem;
    margin-top: 10px;
    color: var(--main-dark-blue);
    padding: 5px;
}
.changePicZone{
    width: 100%;
    height: 200px;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: var(--main-dark-blue);
    color: #FFF;
    pointer-events: all;
    overflow: auto;
    transition: 0.3s;
}
.changePicZoneInactive{
    width: 100%;
    height: 0px;
    overflow: hidden;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--main-dark-blue);
    pointer-events: none;
    transition: 0.3s;
}
.changePicZonePics{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 10px;
    pointer-events: none;
}
.Profil .changePicZonePics{
    pointer-events: all;
}
.changePicZonePic{
    width: 100px;
    height: 120px;
    aspect-ratio: 1 / 1;
    margin: 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
    cursor: pointer;
    
}
.avatar{
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 5px;
    background-color: #FFF;
    border: solid 1px var(--main-light-grey);
    border-radius: 5px;
    transition: 0.3s;
}
.changePicZonePic:hover .avatar{
    transform: scale(1.1);
    transition: 0.3s;
}

.ProfilContactInfo{
    border: solid 1px #FFF;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
    width: 98%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
}
.ProfilContactInfo input, select, label{
    width: 48%;
}
.profilInfoRole{
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px #FFF;
    border-radius: 5px;
    margin-top: 10px;
    background-color: #FFF;
    font-size: 0.8rem;
}
.ProfilSaveSection{
    display:  flex;
    width :100%;
    align-items: center;
    justify-content: space-around;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 0px 0px 5px 5px;
    font-size: 0.8rem;
}
.ProfilSaveButton{
    border: solid 1px #FFF;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 4px;
    margin: 5px 0px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 2px;
    transition: 0.3s;
}
.ProfilSaveButton:hover{
    transition: 0.3s;
    background-color: #FFF;
    color: var(--main-grey);
}
.ProfilCloseButton{
    color: #FFF;
    padding: 5px 10px;
    margin: 5px 0px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    transition: 0.3s;
}
.ProfilCloseButton:hover{
    opacity: 0.6;
    transition: 0.2s;
}

@media screen and (max-width: 768px){
    .profilZone{
        width: 90%;
    }
    .ProfilBasicInfo{
        flex-direction: column;
    }
    .ProfilBasicInfoImage{
        width: 40%;
    }
    .ProfilBasicInfoText{
        width: 90%;
    }
    .ProfilBasicInfoText label{
        width: 48%;
    }
    .ProfilBasicInfoText input{
        width: 48%;
    }
    .ProfilContactInfo input, select, label{
        width: 48%;
    }
    .ProfilSaveSection{
        flex-direction: column;
    }
    .ProfilSaveButton{
        width: 90%;
    }
    .ProfilCloseButton{
        width: 90%;
    }
}

@media screen and (max-width: 480px){

    .profilZone{
        width: 95%;
        overflow: auto;
    }
    .ProfilBox{
        height: unset;
    }
    .ProfilBasicInfo{
        flex-direction: column;
    }
    .ProfilBasicInfoImage{
        width: 90%;
    }
    .ProfilBasicInfoImageChange{
        opacity: 1;
        pointer-events: all;
        background-color: var(--blue);
        color: #FFF;
    }
    .ProfilBasicInfoText{
        width: 90%;
    }
    .ProfilBasicInfoText label{
        width: 100%;
    }
    .ProfilBasicInfoText input{
        width: 100%;
    }

    .ProfilConnecMail input{
        width: 70%;
    }
    .ProfilConnecMail label{
        width: 30%;
    }
    .ProfilContactInfo input, select, label{
        width: 100%;
    }
    .ProfilSaveSection{
        flex-direction: column;
    }
    .ProfilSaveButton{
        width: 90%;
    }
    .ProfilCloseButton{
        width: 90%;
    }
}