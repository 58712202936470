.CartIcone{
    position: fixed;
    right: calc(20% - 3px);
    top: 5vh;
    min-width: 3vw;
    height: 3vw;
    border-radius: 4px 0px 0px 4px;
    background-color: var(--main-dark-blue);
    color: #FFF;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}
.CartIcone:hover{
    background-color: var(--main-blue);
    transition: 0.3s;
}
.CartIcone_length{
    position: absolute;
    bottom: 5px;
    left: 5px;
    width: 1.2vw;
    height: 1.2vw;
    border-radius: 50%;
    background-color: var(--blue);
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
    font-weight: 600;
    transition: 0.3s;
}
.CartIcone_length__inactive{
    right: -1.5vw;
    transition: 0.3s;
}
.CartIcone__inactive{
    right: 0;
    transition: 0.3s;
}
.CartSlide{
    position: fixed;
    top: 0;
    right: 0;
    width: 20%;
    height: 100%;
    background-color: #FFF;
    z-index: 1000;
    box-shadow: -2px 0 5px 0 rgba(0,0,0,0.1);
    font-family: var(--main-font);
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    transition: 0.3s;
    &::-webkit-scrollbar{
        display: none;
    }
}
.CartSlide__inactive{
    right: -20%!important;
    transition: 0.3s;
}
.CartSlide__header{
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: var(--main-dark-blue);
}
.CartSlide__header h2{
    font-size: 1.2rem;
    font-weight: 600;
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    margin: 0;
}
.CartSlide__closeButton{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: #FFF;
    background-color: var(--main-red);
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
}
.CartSlide__content{
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 0px;
    height : 70%;
    transition: 0.3s;
}
.CartSlide__content_close{
    height: 35%;
    transition: 0.3s;
}
.CartSlide__content_title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    letter-spacing: 1px;
    font-weight: 400;
    background-color: var(--main-grey);
    color: #FFF;
    padding: 5px;
    text-transform: uppercase;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
}
.CartSlide__content_title svg{
    margin-left: 5px;
}
.CartSlide__content ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    overflow-y: auto;
    height: 70%;
    padding: 5px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar{
        display: none;
    }
}
.CartSlide__content_close ul{
    height: 45%;
    transition: 0.3s;
}
.CartSlide__categories_list{
    list-style: none;
    padding: 0;
    margin: 0!important;
    width: 100%;
    overflow-y: auto;
    padding: 5px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.CartSlide__content li{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    padding: 5px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
    margin-bottom: 20px;
    cursor: pointer;
    transition: 0.3s;

}
.CartSlide__content li:hover{
    background-color: #FFF;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
    transition: 0.3s;
}
.CartSlide__image{
    width: 28%;
    aspect-ratio: 1/1;
    background-color: #222;
    border-radius: 3px;
    border-radius: 4px;
    overflow: hidden;
}
.CartSlide_article_description{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
}
.CartSlide_article_description h3{
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0;
    color: var(--main-grey);
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.CartSlide_article_description p{
    font-size: 0.8rem;
    margin: 0;
    color: var(--main-middle-grey);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.CartSlide__Quantity{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 3px;
}
.CartSlide__Quantity_less{
    padding: 5px 10px;
    border-radius: 3px 0px 0px 3px;
    background-color: var(--main-light-grey);
    color: var(--main-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
}
.CartSlide__Quantity_less:hover{
    background-color: var(--main-middle-grey);
    transition: 0.3s;
}
.CartSlide__Quantity_more{
    padding: 5px 10px;
    border-radius: 0px 3px 3px 0px;
    background-color: var(--main-light-grey);
    color: var(--main-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
}
.CartSlide__Quantity_more:hover{
    background-color: var(--main-middle-grey);
    transition: 0.3s;
}
.CartSlide__Quantity_number{
    width: 25%!important;
    font-size: 0.8rem!important;
    color: var(--main-grey)!important;
    border: none;
    width: 40%;
    text-align: center;
    -moz-appearance: textfield;
    appearance: textfield;
}
.CartSlide__content_total{
    display: flex!important;
    justify-content: space-between!important;
    align-items: center!important;
    padding: 0px 10px!important;
    height: 5vh!important;
    color: var(--main-grey)!important;
}
.CartSlide__footer{
    width : 100%;
    border-top: var(--main-light-grey) 1px solid;
    padding: 10px 10px;
    padding-bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
.CartSlideArrow{
    margin-top: -20px!important;
    margin-bottom: 10px!important;
    position: sticky;
    margin: auto;
    width: 100%;
    color: var(--main-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
}
.CartSlideArrow:hover{
    color: var(--main-dark-grey);
    transform: scale(1.1);
    transition: 0.3s;
}
.CartSlideArrowDown{
    transform: rotateZ(180deg);
    transition: 0.3s;
}
.CartSlideArrowDown:hover{
    transform: scale(1.1) rotateZ(180deg);
    transition: 0.3s;
}
.CartSlide__footer_content{
    height: 9vh!important;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar{
        display: none;
    }
    transition: 0.3s;
}
.CartSlide__footer_content_open{
    height: 45vh!important;
}
.CartSlide__footer_content_empty{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: solid 1px var(--main-light-grey);
    border-radius: 3px;
    padding: 10px;
    width: 100%;
    height: 5vh;
    color: var(--main-grey);
    font-size: 0.8rem;
    font-weight: 400;
    cursor: pointer;
    transition: 0.3s;
}
.CartSlide__categories_list{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    list-style: none;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar{
        display: none;
    }
}
.CartSlide__categories_list li{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 0px;
    border-radius: 3px;
    margin-bottom: 5px;
    background-color: var(--main-light-grey);
    color: var(--main-grey);
    border-radius: 3px;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 400;
    cursor: pointer;
    transition: 0.3s;
}
.CartSlide__categories_title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 400;
    color: #FFF;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: var(--main-middle-grey);
    border-radius: 3px;
    width: 100%;
    margin: 0;
    padding-left: 5px;
}
.CartSlide__categories_title_active{
    border-radius: 3px;
    border: solid 2px var(--main-green);
}
.CartSlide_category_status{
    padding: 5px;
    background-color: var(--main-dark-grey);
    color: #FFF;
    border-radius: 0px 3px 3px 0px ;
    min-width: 30%;
    text-align: right;
}
.CartSlide__categories_articles_list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 80%;
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;
}
.CartSlide__categories_articles_list_active{
    max-height: none;
    transition: 0.3s;
}
.CartSlide__categories_articles_list li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 96%;
    padding: 3px 5px;
    font-size: 0.8rem;
    font-weight: 400;
    cursor: pointer;
    transition: 0.3s;
    background-color: #FFF;
    margin-top: 5px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
    user-select: none; /* empêche la sélection du texte */
    cursor: grab; /* change le curseur en mode "poignée" */
}
  
.CartSlide__categories_articles_list li:active {
    cursor: grabbing; /* change le curseur en mode "poignée fermée" lorsque le composant est cliqué */
}
.CartSlide__categories_article_image{
    width: 20%;
    aspect-ratio: 1/1;
    background-color: #222;
    border-radius: 3px;
    border-radius: 4px;
    overflow: hidden;
}
.CartSlide_categories_article_description{
    width: 76%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
}
.CartSlide_categories_article_description h3{
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 600;
    width: 100%;
    margin: 0;
    color: var(--main-grey);
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.CartSlide_categories_article_description p{
    font-size: 0.8rem;
    line-height: 1rem;
    margin: 0;
    color: var(--main-middle-grey);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.CartSlide__command_active{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px 10px;
    background-color: var(--main-green);
    color: #FFF;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s;
}
.CartSlide__content_date{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: var(--main-light-grey);
    color: var(--main-grey);
    font-size: 0.8rem;
    font-weight: 400;
    border-radius: 5px;
    margin-bottom: 10px;
}
.CartSlide__content_date input{
    border: none;
    width: 100%;
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--main-grey);
    text-align: center;
    background-color: var(--main-light-grey);
}
.CartSlide__content_validate{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px 10px;
    background-color: var(--main-green);
    color: #FFF;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s;
}
.CartSlide__content_delete{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px 10px;
    background-color: var(--main-red);
    color: #FFF;
    margin: 10px 0px;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s;
}
.CartSlide__content_alert{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 5px 10px;
    background-color: var(--main-red);
    color: #FFF;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s;
}
.commandesPannelMask{
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.85);
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 1;
    pointer-events: all;
    transition: 0.3s;
}
.commandesPannelMaskUnactive{
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.85);
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
}
.commandesPannel{
    width: 60vw;
    height: 80vh;
    background-color: #FFF;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.commandesPannel__header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: var(--main-light-grey);
    border-radius: 5px 5px 0px 0px;
}
.commandesPannel__header h2{
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--main-grey);
    width: 100%;
    margin: 0;
}
.commandesPannel__content{
    width: 100%;
    height: 80%;
    padding: 10px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar{
        display: none;
    }
}
.commandesPannel__content ul{
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    overflow-y: auto;
    padding: 5px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar{
        display: none;
    }
}
.commandesPannel__content li{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    padding: 5px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
    margin-bottom: 20px;
    cursor: pointer;
    transition: 0.3s;
}
.commandesPannel__content li:hover{
    background-color: #FFF;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
    transition: 0.3s;
}
.commandesPannel__content li:active{
    background-color: var(--main-light-grey);
    transition: 0.3s;
}
.commandesPannel__content_title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    letter-spacing: 1px;
    font-weight: 400;
    background-color: var(--main-grey);
    color: #FFF;
    padding: 5px;
    text-transform: uppercase;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
}
.commandesPannel__content_image{
    width: 28%;
    aspect-ratio: 1/1;
    background-color: #222;
    border-radius: 3px;
    border-radius: 4px;
    overflow: hidden;
}
.commandesPannel_article_description{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
}
.commandesPannel_article_description h3{
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0;
    color: var(--main-grey);
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.commandesPannel_article_description p{
    font-size: 0.8rem;
    margin: 0;
    color: var(--main-middle-grey);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.commandesPannel__footer{
    width : 100%;
    border-top: var(--main-light-grey) 1px solid;
    padding: 10px 10px;
    padding-bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
.commandesPannel__footer_close{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    padding: 5px 10px;
    background-color: var(--main-red);
    color: #FFF;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s;
}
.all_commandes_button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px 10px;
    background-color: var(--main-green);
    color: #FFF;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s;

}
    
@media screen and (max-width: 768px) {
    .CartIcone{
        right: calc(90% - 3px);
        top: 8vh;
        min-width: 5vw;
        height: 5vw;
    }
    .CartIcone_length{
        width: 4vw;
        height: 4vw;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .CartSlide{
        width: 80%;
    }
    .CartSlide__inactive{
        right: -80%!important;
    }
    .CartSlide__header h2{
        font-size: 1rem;
    }
    .CartSlide__content{
        height: 60%;
    }
    .CartSlide__content_close{
        height: 30%;
    }
    .CartSlide__content_title{
        font-size: 0.7rem;
    }
    .CartSlide__content ul{
        height: 60%;
    }
    .CartSlide__content_close ul{
        height: 30%;
    }
    .CartSlide__content li{
        margin-bottom: 10px;
    }
    .CartSlide__content li:hover{
        margin-bottom: 10px;
    }
    .CartSlide__image{
        width: 30%;
    }
    .CartSlide_article_description h3{
        font-size: 0.8rem;
    }
    .CartSlide_article_description p{
        font-size: 0.7rem;
    }
    .CartSlide__Quantity_more{
        padding: 5px 5px;
    }
    .CartSlide__Quantity_less{
        padding: 5px 5px;
    }
    .CartSlide__Quantity_number{
        font-size: 0.7rem;
    }
    .CartSlide__content_total{
        height: 5vh;
    }
    .CartSlide__footer{
        padding: 10px 5px;
    }
    .CartSlide__footer_content{
        height: 10vh;
    }
    .CartSlide__footer_content_open{
        height: 40vh;
    }
    .CartSlide__footer_content_empty{
        height: 5vh;
    }
    .CartSlide__categories_list li{
        font-size: 0.7rem;
    }
    .CartSlide__categories_title{
        font-size: 0.7rem;
    }
    .CartSlide_category_status{
        padding: 5px;
        font-size: 0.7rem;
    }
    .CartSlide__categories_articles_list li{
        font-size: 0.7rem;
    }
    .CartSlide_categories_article_description h3{
        font-size: 0.7rem;
    }
    .CartSlide_categories_article_description p{
        font-size: 0.7rem;
    }
    .CartSlide__command_active{
        font-size: 0.7rem;
    }
    .CartSlide__content_date{
        font-size: 0.7rem;
    }
    .CartSlide__content_date input{
        font-size: 0.7rem;
    }
    .CartSlide__content_validate{
        font-size: 0.7rem;
    }
    .CartSlide__content_delete{
        font-size: 0.7rem;
    }
    .CartSlide__content_alert{
        font-size: 0.7rem;
    }
}
@media screen and (max-width: 480px) {
    .CartIcone{
        right: calc(90% - 3px);
        top: 8vh;
        width: 10vw;
        height: 10vw;
    }
    .CartIcone_length{
        width: 4vw;
        height: 4vw;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .CartIcone__inactive{
        right: 0;
    }
    .CartSlide{
        width: 90%;
    }
    .CartSlide__inactive{
        right: -90%!important;
    }
    .CartSlide__header h2{
        font-size: 0.8rem;
    }
    .CartSlide__content{
        height: 50%;
    }
    .CartSlide__content_close{
        height: 25%;
    }
    .CartSlide__content_title{
        font-size: 0.6rem;
    }
    .CartSlide__content ul{
        height: 50%;
    }
    .CartSlide__content_close ul{
        height: 25%;
    }
    .CartSlide__content li{
        margin-bottom: 5px;
    }
    .CartSlide__content li:hover{
        margin-bottom: 5px;
    }
    .CartSlide__image{
        width: 35%;
    }
    .CartSlide_article_description h3{
        font-size: 0.6rem;
    }
    .CartSlide_article_description p{
        font-size: 0.5rem;
    }
    .CartSlide__Quantity_more{
        padding: 5px 5px;
    }
    .CartSlide__Quantity_less{
        padding: 5px 5px;
    }
    .CartSlide__Quantity_number{
        font-size: 0.5rem;
    }
    .CartSlide__content_total{
        height: 5vh;
    }
    .CartSlide__footer{
        padding: 10px 5px;
    }
    .CartSlide__footer_content{
        height: 10vh;
    }
    .CartSlide__footer_content_open{
        height: 30vh;
    }
    .CartSlide__footer_content_empty{
        height: 5vh;
    }
    .CartSlide__categories_list li{
        font-size: 0.5rem;
    }
    .CartSlide__categories_title{
        font-size: 0.5rem;
    }
    .CartSlide_category_status{
        padding: 5px;
        font-size: 0.5rem;
    }
    .CartSlide__categories_articles_list li{
        font-size: 0.5rem;
    }
    .CartSlide_categories_article_description h3{
        font-size: 0.5rem;
    }
    .CartSlide_categories_article_description p{
        font-size: 0.5rem;
    }
    .CartSlide__command_active{
        font-size: 0.5rem;
    }
    .CartSlide__content_date{
        font-size: 0.5rem;
    }
    .CartSlide__content_date input{
        font-size: 0.5rem;
    }
    .CartSlide__content_validate{
        font-size: 0.5rem;
    }
    .CartSlide__content_delete{
        font-size: 0.5rem;
    }
    .CartSlide__content_alert{
        font-size: 0.5rem;
    }
}