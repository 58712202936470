.Parametre{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-x: auto;
    font-family: var(--main-font);
    padding-bottom: 3vh;
    max-width: 1080px;
    margin: auto;
    /* On cache la barre de défilement */
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
}
.ParametreSection{
    width: 95%;
    border: solid 1px var(--main-dark-blue);
    border-radius: 5px;
    margin-top: 5vh;
    background-color: #FFF;
}
.ParametreSectionTitle{
    font-size: 1rem;
    padding: 5px 10px;
    width: 30%;
    margin-left: 10px;
    color: #FFF;
    margin-top: calc(-1rem - 5px);
    background-color: var(--main-dark-blue);
    border-radius: 4px;
}
.ParametreSectionContent{
    min-height: 15vh;
    margin: 10px 0px;
    display: flex;
    align-items: flex-start;
    justify-content:space-between;
    flex-wrap: wrap;
}
.ParametreSectionContent::after{
    content: "";
    flex: auto;
}
.ParametreSectionContentRoles{
    padding: 1% 0%;
}
.ParametreSectionContentRoles span{
    font-size: 1rem;
    margin-left: 1%;
    display: inline-block;
    width: 20%;
}
.ParametreSectionContentRolesAttributeButton{
    border-radius: 4px;
    background-color: var(--main-dark-blue);
    font-size: 0.7rem;
    color: #FFF;
    padding: 5px 10px;
    margin-left: 1%;
    font-size: 0.8rem;
    cursor: pointer;
    transition: 0.3s;
    width: unset!important;
}
.ParametreSectionContentRolesAttributeButton:hover{
    background-color: var(--main-dark-blue);
    transition: 0.3s;
}
.ParametreSectionContentRolesChecklist,
.ParametreSectionContentRolesChecklistUnactive {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    margin-top: 1%;
    padding: 10px 10px;
    overflow: hidden;
    border-top: solid 1px var(--main-dark-blue);
    background-color: var(--main-light-grey);
    transition: height 0.3s;
}
.ParametreSectionContentRolesChecklist{
    height:auto;
}

.ParametreSectionContentRolesChecklist fieldset{
    border: none;
    border: solid 1px var(--main-middle-grey);
    padding: 10px;
    margin: 0px;
    width: 100%;
    border-radius: 10px;
}
.ParametreSectionContentRolesChecklist fieldset div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.ParametreSectionContentRolesChecklist fieldset legend{
    font-size: 1rem;
    color: var(--main-dark-blue);
    text-transform: uppercase;
    font-style: italic;
    padding: 5px 10px;
    border-radius: 4px;
    margin: 0px 10px;
}
.ParametreSectionContentRolesChecklist fieldset div input{
    width: unset;
    margin: 0px 10px;
}
.ParametreSectionContentRolesChecklistUnactive{
    height: 0;
    padding: 0px;
}

.ParametreSectionContentRolesChecklist::after{
    content: "";
    flex: auto;
}
.ParametreSectionContentRolesChecklistSection{
    width: 24%;
    text-align: left;
}
.ParametreSectionInputBloc{
    border: solid 1px var(--main-dark-blue);
    border-radius: 5px;
    margin: 5px 1%;
    width: 48%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
}
.ParametreSectionInputBloc span{
    text-align: center;
    width: 48%;
    padding: 5px 10px;
    background-color: var(--main-dark-blue);
    color: #FFF;
    border-radius: 4px 0px 0px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ParametreSectionInput{
    width: 48%;
    padding: 5px 10px;
    border-radius: 0px 4px 4px 0px;
    border: none;
    color: var(--main-dark-blue);
    display: flex;
    align-items: center;
    flex-direction: column;
}
.ParametreSectionInputAddress{
    width: 98%;
    padding: 5px 10px;
    border-radius: 0px 4px 4px 0px;
    border: none;
    color: var(--main-dark-blue);
}
.ParametreSectionUsers{
    width : 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.userCard{
    border: solid 1px #FFF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    transition: 0.3s;
    width : 20%;
    min-height: 30vh;
    margin: 10px;
    background-color: #FFF;
    cursor: pointer;
    color: var(--main-dark-blue);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    border-radius: 5px;
    border: solid 1px var(--main-light-grey);
}
.userCard:hover{
    background-color: var();
    transition: 0.3s;
}
.UserCardPicture{
    width: 75%;
    aspect-ratio: 1 / 1;
    border-radius: 100px;
    border: solid 1px #FFF;
    margin: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color : var(--main-grey);
    background-color: var(--main-dark-blue);
    font-size: 4rem;
}
.UserCardPictureUnactive{
    display: none;
}
.UserCardRoleUnactive{
    display: none;
}
.UserCardName{
    font-size: 0.8rem;
    margin: 5px 10px;
}
.UserCardRole{
    background-color: var(--main-dark-blue);
    color : var(--main-light-grey);
    font-size: 0.6rem;
    text-align: center;
    width: 85%;
    padding: 5px;
    border-radius: 50px;
    margin: 5px 10px;
}
.UserCardModifiy{
    width : 100%;
    background-color: var(--main-light-grey);
    cursor: pointer;
    color : var(--main-grey);
    text-align: center;
    font-size: 0.8rem;
    text-transform: uppercase;
    padding: 5px 10px;
    margin-top: 5px;
}
.UserCardModifiy:hover{
    background-color: var(--main-middle-grey);
    transition: 0.3s;
}
.newUserCard{
    width: 100%;
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}
.newUserCardUnactive{
    display: none;
}
.newUserCard input,select{
    width: 90%!important;
    border: solid 1px var(--main-dark-blue)!important;
    color: var(--main-dark-blue)!important;
    background-color: #FFF!important;
    padding: 5px!important;
    border-radius: 4px;
    background-color: transparent;
}
.newUserValid{
    width : 90%;
    background-color: var(--blue);
    transition: 0.3s;
    color: #FFF;
    padding: 5px;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
}
.newUserValid:hover{
    background-color: var(--main-blue);
    transition: 0.3s;
}
.ParametreSectionInputLogo{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.ParametreSectionInputLogo input{
    width: 90%!important;
    border: solid 1px var(--main-dark-blue)!important;
    color: var(--main-dark-blue)!important;
    background-color: #FFF!important;
    padding: 5px!important;
    border-radius: 4px;
    background-color: transparent;
}
.ParametreSectionLogo{
    aspect-ratio: 1 / 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main-dark-blue);
    color: #FFF;
    font-size: 4rem;
    border-radius: 5px;
    border: solid 1px var(--main-dark-blue);
    margin: 10px;
    cursor: pointer;
    transition: 0.3s;
}

.cancelSubscriptionMask{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    transition: 0.3s;
}
.cancelSubscriptionMaskUnactived{
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
}
.cancelSubscriptionBloc{
    width: 70%;
    min-height: 50%;
    background-color: #FFF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    }
.cancelSubscriptionBlocTitle{
    font-weight: 500;
    font-size: 1.5rem;
    width: 100%;
    background-color: var(--main-dark-blue);
    color: #FFF;
    padding: 10px;
    border-radius: 5px 5px 0px 0px;
    text-align: center;
}
.cancelSubscriptionBlocContent{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.cancelSubscriptionBlocContentText{
    font-size: 1.2rem;
    color: var(--main-dark-blue);
    margin: 10px;
    width: 60%;
    text-align: center;
}
.cancelSubscriptionBlocActionZone{
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    background-color: var(--main-light-grey);
    padding: 5px;
    margin: 1%;
    border-radius: 5px;
}
.cancelSubscriptionBlocCancelButton{
    background-color: transparent;
    color: var(--main-grey);
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    transition: 0.3s;
}
.cancelSubscriptionBlocCancelButton:hover{
    background-color: var(--main-blue);
    color: #FFF;
    transition: 0.3s;
}
.cancelSubscriptionBlocBackButton{
    background-color: var(--main-red);
    color: #FFF;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}
.cancelSubscriptionBlocBackButton:hover{
    background-color: var(--main-dark-red);
    transition: 0.3s;
}
.cancelSubscriptionBlocContentSendButton{
    background-color: var(--main-blue);
    color: #FFF;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    transition: 0.3s;
}
.cancelSubscriptionBlocContentSendButton:hover{
    background-color: var(--main-dark-blue);
    transition: 0.3s;
}
.cancelSubscriptionBlocContentForm{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.cancelSubscriptionBlocContentForm p{
    font-size: 1rem;
    background-color: var(--main-light-grey);
    color: var(--main-dark-blue);
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    width: 60%;
}
.cancelSubscriptionBlocContentFormRadios{
    width: 60%;
    margin: 10px auto;
    border: solid 1px var(--main-light-grey);
    border-radius: 5px;
    padding: 10px;
}
.cancelSubscriptionBlocContentFormRadios label{
    font-size: 1rem;
    width: 85% !important;
    display: inline-block;
    color: var(--main-dark-blue);
    margin: 5px;
    cursor: pointer;
}
.cancelSubscriptionBlocContentFormRadios input{
    width: 10%!important;
    margin: 0px 5px!important;
    cursor: pointer;
}
.cancelSubscriptionBlocContentForm textarea{
    width: 60%!important;
    height: 100px;
    margin: 10px;
    padding: 5px;
    margin: 10px auto;
    border: solid 1px var(--main-middle-grey)!important;
    background-color: var(--main-light-grey);
    color: var(--main-dark-blue)!important;
    border-radius: 6px;
    font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 768px) {
    .Parametre{
        padding: 15vh 0px;
    }
    .ParametreSectionContentRoles span{
        width: 100%;
    }
    .ParametreSectionContentRolesAttributeButton{
        width: 100%;
    }
    .ParametreSectionContentRolesChecklistSection{
        width: 100%;
    }
    .ParametreSectionInputBloc{
        width: 98%;
    }
    .ParametreSectionInputBloc span{
        width: 48%;
    }
    .ParametreSectionInput{
        width: 50%;
    }
    .ParametreSectionInputAddress{
        width: 100%;
    }
    .userCard{
        width: 90%;
    }
    .UserCardPicture{
        width: 50%;
    }
    .UserCardName{
        font-size: 1rem;
    }
    .UserCardRole{
        font-size: 0.8rem;
    }
    .UserCardModifiy{
        font-size: 1rem;
    }
    .newUserCard input,select{
        width: 100%!important;
    }
    .newUserValid{
        width: 100%;
    }
    .ParametreSectionTitle{
        width: 95%;
    }
}

@media screen and (max-width: 480px) {
    .Parametre{
        padding: 15vh 0px;
    }
    .ParametreSectionContentRoles span{
        width: 100%;
    }
    .ParametreSectionContentRolesAttributeButton{
        width: 100%;
    }
    .ParametreSectionContentRolesChecklistSection{
        width: 100%;
    }
    .ParametreSectionInputBloc{
        width: 98%;
    }
    .ParametreSectionInputBloc span{
        width: 48%;
    }
    .ParametreSectionInput{
        width: 50%;
    }
    .ParametreSectionInputAddress{
        width: 100%;
    }
    .userCard{
        width: 90%;
    }
    .UserCardPicture{
        width: 50%;
    }
    .UserCardName{
        font-size: 1rem;
    }
    .UserCardRole{
        font-size: 0.8rem;
    }
    .UserCardModifiy{
        font-size: 1rem;
    }
    .newUserCard input,select{
        width: 100%!important;
    }
    .newUserValid{
        width: 100%;
    }
    .ParametreSectionTitle{
        width: 95%;
    }
}