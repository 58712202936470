.Chat{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.Chat h1{
    margin: 0;
    padding: 10px;
    background-color: var(--main-blue);
    color: white;
    width: 100%;
    text-align: center;
}
.discussionZone{
    height: 92%;
    width: calc(100% - 100px);
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.messages{
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: calc(100% - 50px);

    /* Cacher la barre de défilement */
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar{
        display: none;
    }
}   
.messages button{
    background-color: #498fd4;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    margin: 10px;
    align-self: center;
}
.message{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.message_user{
    /* Permet de mettre les messages à droite et en light blue */
    justify-content: flex-start;
}
.message_user p{
    margin: 0;
    padding: 10px;
    border-radius: 10px;
    margin-left: 10px;
    background-color: white;
}
.message_bot{
    /* Permet de mettre les messages à gauche et en white */
    justify-content: flex-end;
}
.message_bot p{
    margin: 0;
    padding: 10px;
    border-radius: 10px;
    margin-right: 10px;
    background-color: var(--main-light-blue);
}
.message_admin{
    /* Permet de mettre les messages a droite et en orange */
    justify-content: flex-end;
}
.message_admin p{
    margin: 0;
    padding: 10px;
    border-radius: 10px;
    margin-left: 10px;
    background-color: var(--main-orange);
}
.inputZone{
    display: flex;
    background-color: white;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #e0e0e0;
}
.inputZone input{
    flex: 1;
    padding: 5px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    margin-right: 10px;
}
.inputZone button{
    padding: 5px 10px;
    background-color: #498fd4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.ContactsZone{
    display: flex;
    flex-direction: column;
    width: 100px;
    height: 100%;
    background-color: #e0e0e0;
    border-right: 1px solid #e0e0e0;
    overflow-y: auto;
    /* Cacher la barre de défilement */
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar{
        display: none;
    }
}

.contact p{
    margin: 0;
    width: 80%;
}
.statusLight{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #63bf8f;
    margin-right: 5px;
}
.statusLightRed{
    width: 10px;
    height: 10px;
    border-radius: 50%;

    background-color: #d14242;
    margin-right: 5px;
}