.Caisse{
    width: 98%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 10vh;
    max-width: 1080px;
}
.CaisseEnregistreuse{
    height: 95%;
    width: 80%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
}
.CaisseEnregistreusePad{
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border: solid 1px var(--main-middle-grey);
    padding: 5px;
    border-radius: 5px;
}
.CaisseEnregistreuseNouvelleFacture{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    background-color: var(--blue);
    color: #FFF;
    font-family: var(--main-font);
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    margin-bottom: 1vh;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.3s;
}
.CaisseEnregistreuseNouvelleFacture:hover{
    background-color: var(--main-blue);
    color: var(--main-light-blue);
    transition: 0.3s;
}
.CaisseEnregistreusePadScreen{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    height: 12vh;
    background-color: #FFF;
    border-radius: 5px;
    padding: 2vh;
    font-size: 1.4rem;
    font-weight: initial;
    font-family: "Poppins", sans-serif;
    color: var(--main-dark-blue);
    background-color: var(--main-grey);

}
.CaisseEnregistreusePadTouch{
    width: 30%;
    height: 10vh;
    margin-top: 2%;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--main-middle-grey);
    border-radius: 5px;
    border: solid 1px var(--main-middle-grey);
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--main-font);
    cursor: pointer;
    transition: 0.3s;
}
.CaisseEnregistreusePadTouch:hover{
    background-color: var(--main-middle-grey);
    color: #FFF;
    transition: 0.3s;
}
.Pad0{
    width: 48%;
}
.pad00{
    width: 48%;
}
.PadValider{
    height: 5vh;
    font-size: 1.3rem;
}
.CaisseEnregistreuseCategories{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 48%;
}
.CaisseEnregistreuseArticleSearch{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width : 100%;
    background-color: var(--main-middle-grey);
    color: #FFF;
    font-family: var(--main-font);
    border-radius: 5px;
}
.CaisseEnregistreuseArticleSearchInput{
    width: 80%;
    font-size: 0.8rem;
    font-family: var(--main-font);
    color: #FFF;
    border: none;
    background-color: transparent;
}
.CaisseEnregistreuseArticleSearchResult{
    position: absolute;
    top: 100%; /* Commence juste en dessous du champ de recherche */
    width: 100%;
    background: white;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    z-index: 1000; /* S'assurer qu'il flotte au-dessus d'autres éléments */
    max-height: 200px; /* Limiter la hauteur et permettre le défilement */
    overflow-y: auto; /* Ajouter une barre de défilement si nécessaire */
    border: 1px solid #ddd; /* Ajouter une bordure pour le distinguer */
    border-top: none;
    color: var(--main-middle-grey);
}
.CaisseEnregistreuseCategoriesZone{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    margin-top: 2%;
    max-height: 72vh;
    overflow-y: auto;
    border: solid 1px var(--main-middle-grey);
    border-radius: 5px;
    background-color: #FFF;
    font-family: var(--main-font);
    color: var(--main-middle-grey);
    /* On cache la barre de défilement */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar {
        display: none;  /* Chrome, Safari, Opera*/
    }
}
.CaisseEnregistreuseCategorieOpen{
    width: 100%;
}
.CaisseEnregistreuseCategorieNameZone{
    position: sticky;
    top: 0;
    width: 100%;
    background-color: var(--main-middle-grey);
    color: #FFF;
    font-family: var(--main-font);
    padding: 5px 5px;
    text-transform: uppercase;
    font-size: 1rem;
    z-index: 1000;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 1rem;
    font-family: var(--main-font);
    padding: 5px 5px;
    color: var(--main-middle-grey);
    text-transform: uppercase;
    font-size: 0.8rem;
}
.CaisseEnregistreuseCategorieBack{
    cursor: pointer;
    background-color: var(--main-grey);
    color: #FFF;
    padding: 5px 10px;
    border-radius: 3px;
    transition: 0.3s;
}
.CaisseEnregistreuseCategorieName{
    color: var(--main-grey);
    font-size: 1rem;
    letter-spacing: 1px;
}
.CaisseEnregistreuseCategorieNameZone{
    padding: 5px 10px;
}
.CaisseEnregistreuseCategorieBack:hover{
    background-color: var(--main-dark-blue);
    transition: 0.3s;
}
.CaisseEnregistreuseArticle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
    padding: 10px;
    margin: 1%;
    font-size: 0.8rem;
    font-family: var(--main-font);
    color: var(--main-middle-grey);
    cursor: pointer;
    border: solid 1px var(--main-middle-grey);
    background-color: #FFF;
    border-radius: 3px;
    transition: 0.3s;
}
.CaisseRechercheClientSearchResult{
    position: absolute;
    top: 100%; /* Commence juste en dessous du champ de recherche */
    width: 100%;
    background: white;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    z-index: 1000; /* S'assurer qu'il flotte au-dessus d'autres éléments */
    max-height: 200px; /* Limiter la hauteur et permettre le défilement */
    overflow-y: auto; /* Ajouter une barre de défilement si nécessaire */
    border: 1px solid #ddd; /* Ajouter une bordure pour le distinguer */
    border-top: none;
    color: var(--main-middle-grey);
}
.CaisseEnregistreuseArticleSearchResultInactive{
    display: none;
}
.CaisseEnregistreuseArticleSearchResultArticle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    font-size: 0.8rem;
    font-family: var(--main-font);
    color: var(--main-middle-grey);
    cursor: pointer;
    transition: 0.3s;
}
.CaisseEnregistreuseArticleSearchResultArticle:hover{
    background-color: var(--main-middle-grey);
    color: #FFF;
    transition: 0.3s;
}
.CaisseEnregistreuseArticleSearchResultArticleName{
    width: 60%;
    text-align: left;
}
.CaisseEnregistreuseArticleSearchResultArticlePrice{
    width: 35%;
    text-align: right;
}
.CaisseEnregistreuseCategorie{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 98%;
    padding: 10px;
    border: solid 1px var(--main-middle-grey);
    color: var(--main-middle-grey);
    background-color: #FFF;
    border-radius: 5px;
    margin: 1%;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: var(--main-font);
    cursor: pointer;
    transition: 0.3s;
}
.CaisseEnregistreuseCategorie:hover{
    background-color: var(--main-middle-grey);
    color: #FFF;
    transition: 0.3s;
}
.CaisseList{
    width: 34%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.CaisseCreationClient{
    display: flex;
    justify-content: center;
    align-items: center;
    width : 100%;
    background-color: var(--main-dark-blue);
    color: #FFF;
    font-family: var(--main-font);
    border-radius: 5px;
    padding: 10px;
    font-size: 0.8rem;
    cursor: pointer;
    transition: 0.3s;
}
.CaisseCreationClient:hover{
    background-color: var(--main-blue);
    color: var(--main-light-blue);
    transition: 0.3s;
}
.CaisseCreationClientInactive{
    display: none;
}
.CaisseClient{
    background-color: var(--main-middle-grey);
    color: var(--main-grey);
    font-family: var(--main-font);
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    margin: 10px 0px;
    font-size: 0.8rem;
}
.CaisseClientInactive{
    display: none;
}
.CaisseClientTitle{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    background-color: var(--main-middle-grey);
    color: #FFF;
    padding: 5px 5px;
}
.CaisseClientClose{
    cursor: pointer;
    transition: 0.3s;
}
.CaisseClientClose:hover{
    color: var(--main-dark-blue);
    transition: 0.3s;
}
.CaisseClientOperation{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    width: 100%;
}
.CaisseClientOperationInactive{
    display: none;
}
.CaisseRechercheClient{
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width : 100%;
    background-color: var(--main-middle-grey);
    color: var(--main-dark-blue);
    font-family: var(--main-font);
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0px;
}
.CaisseRechercheClientInactive{
    display: none;
}
.CaisseRechercheClient input{
    width: 80%;
    font-size: 0.8rem;
    font-family: var(--main-font);
    color: #FFF;
    border: none;
    background-color: transparent;
}
.CaisseCreationClientZone{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width : 100%;
    background-color: var(--main-middle-grey);
    color: var(--main-dark-blue);
    font-family: var(--main-font);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 0.8rem;
}
.CaisseCreationClientZone input{
    width: 100%;
    font-size: 0.8rem;
    font-family: var(--main-font);
    color: var(--main-grey);
    background-color: #FFF;
    border: none;
    border-radius: 3px;
    margin-top: 5px;
    padding: 5px;
}
.CaisseCreationClientZoneInactive{
    display: none;
}
.CaisseCreationClientValidation{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
    background-color: var(--main-dark-blue);
    color: #FFF;
    padding: 5px 0px;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.2s;
}
.CaisseCreationClientValidation:hover{
    background-color: var(--main-grey);
    transition: 0.2s;
}
.CaisseCreationClientAnnulation{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
    color: var(--main-grey);
    cursor: pointer;
}
.CaisseCreationClientAnnulation:hover{
    color: var(--main-dark-blue);
    transition: 0.2s;
}
.CaisseRechercheClientSearchResultClient{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-size: 0.8rem;
    font-family: var(--main-font);
    color: var(--main-middle-grey);
    cursor: pointer;
}
.CaisseRechercheClientSearchResultClient:hover{
    background-color: var(--main-middle-grey);
    color: #FFF;
    transition: 0.3s;
}
.CaisseRechercheClientSearchResultClientName{
    width: 100%;
    text-align: left;
}
.CaisseArticleListZone{
    display: flex;
    width: 100%;
    height: 58vh;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    color: var(--main-middle-grey);
    border: solid 1px var(--main-middle-grey);
    border-radius: 5px;
    background-color: #FFF;
    font-family: var(--main-font);
}
.CaisseArticleList{
    width: 100%;
}
.CaisseArticleListHeader{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    background-color: var(--main-middle-grey);
    color: #FFF;
    padding: 5px 5px;
}
.CaisseArticleListArticle{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    border-bottom: solid 1px var(--main-middle-grey);
    padding: 5px 5px;
}
.CaisseArticleListArticleName{
    width: 45%;
    text-align: left;
    cursor: pointer;
}
.CaisseArticleListArticleQuantity{
    width: 15%;
    text-align: right;
    cursor: pointer;
}
.CaisseArticleListArticlePrice{
    width: 15%;
    text-align: right;
    cursor: pointer;
}
.CaisseArticleListArticleDelete{
    width: 15%;
    text-align: right;
    cursor: pointer;
}
.CaisseArticleListTotal{
    width: 80%;
    font-size: 1rem;
    text-align: right;
    color: var(--main-middle-grey);
    border-top: solid 1px var(--main-middle-grey);
    margin-bottom: 5px;
    line-height: 1.5rem;
    font-weight: 600;
}
.CaisseFactureValidation{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
    background-color: var(--blue);
    color: #FFF;
    padding: 5px 0px;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.2s;
}
.CaisseFactureCalcul{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
    background-color: var(--main-middle-grey);
    color: #FFF;
    padding: 5px 0px;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.2s;
}
.CaisseFactureCalculUnactive{
    display: none;
}
.quantityMask{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.85);
    pointer-events: all;
    opacity: 1;
    transition: 0.2s;
}
.quantityMaskUnactive{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.85);
    pointer-events: none;
    opacity: 0;
    transition: 0.2s;
}
.quantityBox{
    width: 55vw;
    height: 65vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-color: #FFF;
    color: var(--main-middle-grey);
    font-family: var(--main-font);
    border-radius: 5px;
    font-size: 1rem;
}
.quantityBoxTitle{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5rem;
    background-color: var(--main-middle-grey);
    color: #FFF;
    padding: 10px 15px;
    border-radius: 5px 5px 0px 0px;
}
.quantityBoxContent{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    font-size: 1.5rem;
    height: 90%;
    overflow-y: auto;
    /* On cache la barre de défilement */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar {
        display: none;  /* Chrome, Safari, Opera*/
    }
}
.quantityBoxBassinSize{
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 1rem;
    margin: 3% 0px;
}
.quantityBoxBassinSizeInput{
    width: 30%;
    font-size: 1.5rem;
    font-family: var(--main-font);
    color: var(--main-middle-grey);
    border: solid 1px var(--main-middle-grey)!important;
    background-color: #FFF;
    border: none;
    border-radius: 3px;
    margin-top: 5px;
    padding: 5px;
    text-align: center;
}
.quantityBoxBassinSize input::-webkit-inner-spin-button,
.quantityBoxBassinSize input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.quantityBoxContentArticle{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    font-size: 1rem;
}
.quantityBoxContentArticleName{
    width: 98%;
    border-radius: 4px 4px 0px 0px;
    padding: 5px;
    font-size: 1rem;
    background-color: var(--main-middle-grey);
    color: #FFF;
    font-family: var(--main-font);
    text-align: left;
    margin-top: 10px;
}
.quantityBoxContentArticleQuantite{
    width: 98%;
    border-left: solid 1px var(--main-middle-grey);
    border-right: solid 1px var(--main-middle-grey);
}
.quantityBoxContentArticleCalcul{
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    padding: 5px;
    border-bottom: solid 1px var(--main-middle-grey);
    cursor: pointer;
    transition: 0.3s;
}
.quantityBoxContentArticleCalcul:hover{
    background-color: var(--main-middle-grey);
    color: #FFF;
    transition: 0.3s;
}
.quantityActionBar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1rem;
    background-color: var(--main-middle-grey);
    color: #FFF;
    padding: 10px 0px;
    border-radius: 0px 0px 5px 5px;
}
.quantityBoxText{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 1rem;
    margin: 3% 0px;
}
.quantityBoxClose{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    font-size: 1rem;
    background-color: var(--main-grey);
    color: #FFF;
    border-radius: 3px;
    cursor: pointer;
    margin: 0px 10px;
    transition: 0.3s;
}
.quantityBoxClose svg{
    margin-left: 5px;
}
.quantityBoxClose:hover{
    background-color: var(--main-red);
    transition: 0.3s;
}

@media screen and (max-width: 768px) {
    .Caisse{
        margin: 0px;
        width: 100%;
        height: unset;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 10vh 0px;
    }
    .CaisseEnregistreuse{
        width: 100%;
        height: unset; 
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .CaisseEnregistreusePad{
        width: 95%;
    }
    .CaisseEnregistreuseCategories{
        width: 100%;
        align-items: flex-start;
        justify-content: center;
    }
    .CaisseList{
        width: 100%;
    }
    .CaisseArticleListZone{
        min-height: 40vh;
        width: 96%;
    }
    .CaisseArticleListHeader{
        font-size: 0.8rem;
    }
    .CaisseArticleListArticle{
        font-size: 0.8rem;
    }
    .CaisseArticleListArticleName{
        width: 40%;
        font-size: 0.7rem;
    }
    .CaisseArticleListArticleQuantity{
        width: 20%;
        font-size: 0.75rem;
    }
    .CaisseArticleListArticlePrice{
        width: 20%;
        font-size: 0.75rem;
    }
    .CaisseArticleListArticleDelete{
        width: 20%;
        font-size: 0.75rem;
    }
    .CaisseArticleListTotal{
        font-size: 0.8rem;
    }
    .CaisseFactureValidation{
        font-size: 1rem;
        width: 96%;
    }
    .CaisseEnregistreusePadScreen{
        height: 10vh;
        margin-top: 1vh;
    }
    .CaisseEnregistreusePadTouch{
        height: 7vh;
        font-size: 1rem;
    }
    .Pad0{
        width: 65%;
    }
    .CaisseEnregistreuseArticleSearch{
        padding: 5px;
        width: 95%;
    }
    .CaisseEnregistreuseArticleSearchInput{
        font-size: 0.6rem;
    }
    .CaisseEnregistreuseArticleSearchResultArticle{
        padding: 5px;
        font-size: 0.6rem;
    }
    .CaisseEnregistreuseArticleSearchResultArticleName{
        width: 60%;
    }
    .CaisseEnregistreuseArticleSearchResultArticlePrice{
        width: 35%;
    }
    .CaisseEnregistreuseCategorie{
        height: 7vh;
        font-size: 1rem;
    }
    .CaisseEnregistreuseCategorieNameZone{
        font-size: 0.6rem;
    }
    .CaisseEnregistreuseCategorieBack{
        padding: 5px;
        font-size: 0.8rem;
    }
    .CaisseEnregistreuseCategorieNameZone{
        padding: 5px;
    }
    .CaisseEnregistreuseArticle{
        padding: 5px;
        font-size: 0.8rem;
    }
    .CaisseRechercheClient{
        font-size: 0.6rem;
    }
    .CaisseRechercheClient input{
        padding: 10px 5px;
        font-size: 0.6rem;
        width: 100%;
    }
    .CaisseRechercheClientSearchResultClient{
        padding: 5px;
        font-size: 0.6rem;
    }
    .CaisseRechercheClientSearchResultClientName{
        font-size: 0.6rem;
    }
    .CaisseCreationClientZone{
        padding: 5px;
        font-size: 0.6rem;
    }
    .CaisseCreationClientZone input{
        font-size: 0.6rem;
    }
    .CaisseCreationClientValidation{
        padding: 5px;
        font-size: 0.8rem;
    }
    .CaisseCreationClientAnnulation{
        font-size: 0.8rem;
    }
    .CaisseClient{
        padding: 5px;
        font-size: 0.6rem;
    }
    .CaisseClientTitle{
        font-size: 0.6rem;
    }
    .CaisseClientOperation{
        font-size: 0.6rem;
        width: 96%;
    }
    .CaisseArticleList{
        font-size: 0.6rem;
    }
    .CaisseArticleListArticle{
        font-size: 0.6rem;
    }
    .CaisseArticleListArticleName{
        width: 40%;
    }
    .CaisseArticleListArticleQuantity{
        width: 20%;
    }
    .CaisseArticleListArticlePrice{
        width: 20%;
    }
    .CaisseArticleListArticleDelete{
        width: 20%;
    }
    .CaisseArticleListTotal{
        font-size: 0.8rem;
        width: 96%;
    }
    .CaisseFactureValidation{
        font-size: 1rem;
        width: 96%;
    }
    .CaisseClientOperationInactive{
        display: none;
    }
    .CaisseCreationClientInactive{
        display: none;
    }
    .CaisseRechercheClientInactive{
        display: none;
    }
    .CaisseArticleListArticleDelete{
        display: none;
    }
    .CaisseArticleListArticle{
        justify-content: space-between;
    }
    .CaisseEnregistreuseCategoriesZone{
        margin: 1vh 0px;
        width: 96%;
    }
}

@media screen and (max-width: 480px) {
    .Caisse{
        margin: 0px;
        width: 100%;
        height: unset;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 10vh 0px;
    }
    .CaisseEnregistreusePad{
        width: 95%;
    }
    .CaisseEnregistreusePadScreen{
        height: 10vh;
        margin-top: 1vh;
    }
    .CaisseEnregistreusePadTouch{
        height: 7vh;
        font-size: 0.8rem;
    }
    .Pad0{
        width: 65%;
    }
    .CaisseEnregistreuseArticleSearch{
        padding: 5px;
        width: 95%;
        margin-top: 1vh;
    }
    .CaisseEnregistreuseArticleSearchInput{
        font-size: 0.5rem;
        width: 100%;
    }
    .CaisseEnregistreuseArticleSearchResultArticle{
        padding: 3px;
        font-size: 0.5rem;
    }
    .CaisseEnregistreuseArticleSearchResultArticleName{
        width: 60%;
    }
    .CaisseEnregistreuseArticleSearchResultArticlePrice{
        width: 35%;
    }
    .CaisseEnregistreuseCategories{
        width: 100%;
        align-items: flex-start;
        justify-content: center;
    }
    .CaisseEnregistreuseCategorie{
        height: 7vh;
        font-size: 0.8rem;
    }
    .CaisseEnregistreuseCategorieNameZone{
        font-size: 0.5rem;
    }
    .CaisseEnregistreuseCategorieBack{
        padding: 3px;
        font-size: 0.8rem;
    }
    .CaisseEnregistreuseCategorieNameZone{
        padding: 3px;
    }
    .CaisseEnregistreuseArticle{
        padding: 3px;
        font-size: 0.8rem;
    }
    .CaisseRechercheClient{
        padding: 10px 5px;
        font-size: 0.5rem;
    }
    .CaisseRechercheClient input{
        font-size: 0.5rem;
    }
    .CaisseRechercheClientSearchResultClient{
        padding: 3px;
        font-size: 0.5rem;
    }
    .CaisseRechercheClientSearchResultClientName{
        font-size: 0.5rem;
    }
    .CaisseCreationClientZone{
        padding: 3px;
        font-size: 0.5rem;
    }
    .CaisseCreationClientZone input{
        font-size: 0.5rem;
    }
    .CaisseCreationClientValidation{
        padding: 3px;
        font-size: 0.8rem;
    }
    .CaisseCreationClientAnnulation{
        font-size: 0.8rem;
    }
    .CaisseClient{
        padding: 3px;
        font-size: 0.5rem;
    }
    .CaisseClientTitle{
        font-size: 0.5rem;
    }
    .CaisseClientOperation{
        font-size: 0.5rem;
        width: 96%;
    }
    .CaisseArticleList{
        font-size: 0.5rem;
    }
    .CaisseArticleListArticle{
        font-size: 0.5rem;
    }
    .CaisseArticleListArticleName{
        width: 40%;
        font-size: 0.7rem;
    }
    .CaisseArticleListArticleQuantity{
        width: 20%;
        font-size: 0.75rem;
    }
    .CaisseArticleListArticlePrice{
        width: 20%;
        font-size: 0.75rem;
    }
    .CaisseArticleListArticleDelete{
        width: 20%;
    }
    .CaisseArticleListTotal{
        font-size: 0.8rem;
        width: 96%;
    }
    .CaisseClientOperationInactive{
        display: none;
    }
    .CaisseCreationClientInactive{
        display: none;
    }
    .CaisseRechercheClientInactive{
        display: none;
    }
    .CaisseArticleListArticleDelete{
        display: none;
    }
    .CaisseArticleListArticle{
        justify-content: space-between;
    }
    .CaisseEnregistreuse{
        width: 100%;
        height: unset; 
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .CaisseEnregistreuseCategoriesZone{
        margin: 1vh 0px;
        width: 96%;
    }
    .CaisseArticleListZone{
        min-height: 40vh;
        width: 96%;
    }
}