.BlogPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--main-light-grey);
    overflow-y: auto;
    /* on masque la scrollbar */
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
}
.BlogPageBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 980px;
    border-radius: 5px;
    background-color: #FFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}
.BlogPageHeader{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}
.BlogPagelogo{
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--main-dark-blue);
}
.logo-block{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    padding: 20px;
    cursor: pointer;
}
.logo-text{
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--main-grey);
    margin-left: 10px;
    font-family: 'Poppins', sans-serif;
}
.BlogPageTitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: var(--main-grey);
    padding: 10px 20px;
    text-align: center;
}
.BlogPageTitle h1{
    font-size: 1.2rem;
    font-weight: 600;
    margin: 5px 0;
    text-align: left;
    width: 80%;
}
.BlogPageTitle p{
    font-size: 1rem;
    font-weight: 500;
    margin: 5px 0;
    padding: 10px 20px;
    background-color: var(--main-light-grey);
    border-radius: 5px;
}
.BlogPageShare{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 0px 20px;
    border-radius: 5px;
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    transition: 0.3s;
}
.BlogPageShareLink{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    background-color: var(--main-grey);
    color: #FFF;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    transition: 0.3s;
}
.BlogPageImage{
    width: 100%;
    height: 450px;
    background-color: #555;
}
.BlogPageContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    padding: 20px;
}
.BlogPageContent br{
    margin: 5px 0;
}
.BlogPageContent p{
    font-size: 1rem;
    font-weight: 400;
    margin: 10px 0;
    text-align: justify;
}
.BlogPageCommentCreateBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-top: 1px solid var(--main-light-grey);
}
.BlogPageCommentCreateBlock textarea{
    width: 100%;
    height: 100px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--main-light-grey);
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 10px;
}
.BlogPageCommentCreateButton{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    background-color: var(--main-grey);
    color: #FFF;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    transition: 0.3s;
}
.BlogPageComments{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-top: 1px solid var(--main-light-grey);
}
.BlogPageComments h2{
    font-size: 1.5rem;
    font-weight: 500;
    width: 100%;
    color: var(--main-grey);
    margin-bottom: 20px;
    text-align: left;
}
.BlogPageComment{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    background-color: #FFF;
    border: solid 1px var(--main-light-grey);
    margin-bottom: 20px;
}
.BlogPageCommentAuthor{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    border-bottom: solid 1px var(--main-light-grey);
}
.BlogPageCommentComment{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: ;
    width: 100%;
    margin-bottom: 10px;
}